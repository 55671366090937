import { Box } from '@mui/material';
import { useFormik } from 'formik';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState, useMemo, useEffect } from 'react';
import { customerAddressSchema, customerPersonalSchema } from '../../lib/customer.schema';
import { DashboardLayout } from '../DashboardLayout';
import CustomerPersonal from './CustomerPersonal';
import CustomerAddress from './CustomerAddress';
import CustomerReview from './CustomerReview';
import { addCustomer, updateCustomer } from '../../api';
import { useStore } from '../../store';
import { notifyError, notifySuccess } from '../../lib/notification';
import { queryKeys } from '../../lib/queryKeys';
import { useParams } from 'react-router-dom';
const steps = ['Customer Details', 'Customer Address', 'Finish'];

const getFormikStepConfig = (step: number) => {
  return [
    {
      initialValues: { fullname: '', currency: '', email: '', phone: '', note: '' },
      validationSchema: customerPersonalSchema
    },
    {
      initialValues: {
        shippingAddress: '',
        shippingCity: '',
        shippingLgaZip: '',
        shippingState: '',
        address: '',
        city: '',
        lgazip: '',
        state: ''
      },
      validationSchema: customerAddressSchema
    },
    { initialValues: {} }
  ][step];
};

const CustomerForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const [activeStep, setActiveStep] = useState(0);
  const { user, resetCustomerDetails, customerDetails }: any = useStore((state: any) => state);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  // Make the api for creating customer here
  const createCustomer = useMutation(addCustomer, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data) => {
      notifySuccess('Customer Created Successfully');
      resetCustomerDetails(null);
      queryClient.invalidateQueries([queryKeys.customers]);
      navigate('/customers');
    },
    onError: (error) => {
      notifyError('Error Creating Customer');
      console.log(error);
    }
  });
  const editCustomer = useMutation(updateCustomer, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data) => {
      notifySuccess('Update Customer was Successful');
      resetCustomerDetails(null);
      queryClient.invalidateQueries([queryKeys.customers]);
      navigate('/customers');
    },
    onError: (error) => {
      notifyError('Error Updating Customer');
      console.log(error);
    }
  });
  const stepFormikConfig = useMemo(() => getFormikStepConfig(activeStep), [activeStep]);
  const formik = useFormik({
    initialValues: stepFormikConfig.initialValues,
    validationSchema: stepFormikConfig.validationSchema,
    onSubmit: (values) => {
      if (id) {
        // update customer
        editCustomer.mutate({ ...values, id, ownerId: user.id });
        return;
      }
      createCustomer.mutate({ ...values, ownerId: user.id });
      return;
    }
  });

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (id) {
      formik.setValues({ ...customerDetails });
    }
  }, []);

  const contentProps = {
    formik,
    handleNext,
    handleComplete,
    handleBack,
    activeStep,
    steps,
    handleStep
  };

  const stepContent = [
    { content: <CustomerPersonal {...contentProps} /> },
    { content: <CustomerAddress {...contentProps} /> },
    { content: <CustomerReview {...contentProps} /> }
  ];

  return (
    <DashboardLayout>
      <Box sx={{ margin: 10 }}>
        {activeStep !== 2 && (
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}

        <Box sx={{ maxWidth: '80%', margin: 'auto' }}>{stepContent[activeStep].content}</Box>
      </Box>
    </DashboardLayout>
  );
};

export default CustomerForm;
