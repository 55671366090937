import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const userTestimonials = [
  {
    avatar: <Avatar alt="James Johnson" src="/img/test.jpg" />,
    name: 'James A.',
    occupation: 'Owner, Orijin Solutions Inc.',
    testimonial: `ReachAI has revolutionized how we approach digital marketing. Its AI-powered solutions have significantly boosted our online visibility and organic traffic. It's a game-changer for small businesses like ours.`
  },
  {
    avatar: <Avatar alt="Sarah Lee" src="/img/test.jpg" />,
    name: 'Sarah L.',
    occupation: 'Marketing Manager',
    testimonial: `As a marketing professional, I have tried numerous tools, but ReachAI stands out for its unparalleled performance. Its data-driven strategies have helped us precisely target our audience segments, leading to remarkable results.`
  },
  {
    avatar: <Avatar alt="Michael Wang" src="/img/test.jpg" />,
    name: 'Michael O.',
    occupation: 'Founder, Tech Solutions LLC',
    testimonial: `I have been using ReachAI for a while now, and I must say, it has been a game-changer for my business. The platform's ability to continuously learn from user data ensures that our marketing efforts remain relevant and effective.`
  },
  {
    avatar: <Avatar alt="Emily White" src="/img/test.jpg" />,
    name: 'Emily A.',
    occupation: 'SEO Specialist',
    testimonial: `ReachAI's AI-driven SEO capabilities have transformed how we optimize content. Its advanced algorithms help us stay ahead of the curve, ensuring that our content remains optimized and hyper-personalized for our audience.`
  },
  {
    avatar: <Avatar alt="David Martinez" src="/img/test.jpg" />,
    name: 'Adeola A.',
    occupation: 'Small Business Owner',
    testimonial: `I can't thank ReachAI enough for the impact it's had on my business. Its AI-powered solutions have made digital marketing accessible and effective for small businesses like mine. It's truly a game-changer in the industry.`
  },
  {
    avatar: <Avatar alt="Jennifer Adams" src="/img/test.jpg" />,
    name: 'Janet A.',
    occupation: 'Marketing Director',
    testimonial:
      'ReachAI has exceeded all our expectations. Its innovative features and exceptional support have made our marketing efforts more efficient and effective. I highly recommend it to any business looking to elevate their digital presence.'
  }
];

export default function Testimonials() {
  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 }
      }}>
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' }
        }}>
        <Typography component="h2" variant="h4" color="text.primary">
          Testimonials
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Discover why businesses trust ReachAI for exceptional efficiency, durability, and
          guaranteed ROI. Join us and elevate your business with our innovative solutions,
          unwavering quality, and dedicated support. Your success is our priority.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1
              }}>
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2
                }}>
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
