import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import React from 'react';

const tasks = [
  {
    description: 'Add H1 and H2 headings for SEO',
    severity: 'high'
  },
  {
    description:
      'The page has very few internal links, if its a welcome page, consider removing it',
    severity: 'medium'
  },
  {
    description: 'Add more images with alt text to improve SEO',
    severity: 'low'
  }
];

const Task = ({ description, severity }: any) => {
  return (
    <Box
      component="li"
      sx={{
        bgcolor: severity === 'high' ? '#F7D1CD' : severity === 'medium' ? '#FEF2D5' : '#F0F5FD',
        my: 1,
        listStyle: 'none',
        px: { xs: 2, sm: 3 },
        py: 0.5
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <Typography variant="subtitle2" fontWeight={700}>
          {description}
        </Typography>
        <Typography variant="body2" fontWeight={800}>
          {severity === 'high'
            ? 'Very Important'
            : severity === 'medium'
            ? 'Important'
            : 'Not Important'}
        </Typography>
      </Box>
    </Box>
  );
};

const TaskList = () => {
  return (
    <Card
      variant="outlined"
      sx={{
        my: 3
      }}>
      <CardHeader
        sx={{
          m: 0,
          p: 2
        }}
        title="Issues to Improve SEO"
        subheader="List of issues to fix to improve SEO"
      />
      <CardContent
        sx={{
          m: 0,
          p: 2
        }}>
        <Box component="ul">
          {tasks.map((task) => (
            <Task key={task.description} description={task.description} severity={task.severity} />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TaskList;
