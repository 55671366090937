const salesData: any = [
  // {
  //   date: '2023-06-19',
  //   income: 200.0,
  //   spending: 130.56,
  //   net: 69.44,
  //   customer: 'At&T'
  // },
  // {
  //   date: '2023-06-20',
  //   income: null,
  //   spending: 7.28,
  //   net: null,
  //   customer: 'ESSL'
  // }
];
const holiday = [
  {
    country: 'US',
    data: [
      {
        date: '2023-01-02',
        localName: 'New Year&quot;s Day',
        name: 'New Year&quot;s Day',
        countryCode: 'US',
        fixed: false,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-01-16',
        localName: 'Martin Luther King, Jr. Day',
        name: 'Martin Luther King, Jr. Day',
        countryCode: 'US',
        fixed: false,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-02-20',
        localName: 'Presidents Day',
        name: 'Washington&apo;s Birthday',
        countryCode: 'US',
        fixed: false,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-04-07',
        localName: 'Good Friday',
        name: 'Good Friday',
        countryCode: 'US',
        fixed: false,
        global: false,
        counties: [
          'US-CT',
          'US-DE',
          'US-HI',
          'US-IN',
          'US-KY',
          'US-LA',
          'US-NC',
          'US-ND',
          'US-NJ',
          'US-TN'
        ],
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-04-07',
        localName: 'Good Friday',
        name: 'Good Friday',
        countryCode: 'US',
        fixed: false,
        global: false,
        counties: ['US-TX'],
        launchYear: null,
        types: ['Optional']
      },
      {
        date: '2023-05-29',
        localName: 'Memorial Day',
        name: 'Memorial Day',
        countryCode: 'US',
        fixed: false,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-06-19',
        localName: 'Juneteenth',
        name: 'Juneteenth',
        countryCode: 'US',
        fixed: false,
        global: true,
        counties: null,
        launchYear: 2021,
        types: ['Public']
      },
      {
        date: '2023-07-04',
        localName: 'Independence Day',
        name: 'Independence Day',
        countryCode: 'US',
        fixed: false,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-09-04',
        localName: 'Labor Day',
        name: 'Labour Day',
        countryCode: 'US',
        fixed: false,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-10-09',
        localName: 'Columbus Day',
        name: 'Columbus Day',
        countryCode: 'US',
        fixed: false,
        global: false,
        counties: [
          'US-AL',
          'US-AZ',
          'US-CO',
          'US-CT',
          'US-GA',
          'US-ID',
          'US-IL',
          'US-IN',
          'US-IA',
          'US-KS',
          'US-KY',
          'US-LA',
          'US-ME',
          'US-MD',
          'US-MA',
          'US-MS',
          'US-MO',
          'US-MT',
          'US-NE',
          'US-NH',
          'US-NJ',
          'US-NM',
          'US-NY',
          'US-NC',
          'US-OH',
          'US-OK',
          'US-PA',
          'US-RI',
          'US-SC',
          'US-TN',
          'US-UT',
          'US-VA',
          'US-WV'
        ],
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-11-10',
        localName: 'Veterans Day',
        name: 'Veterans Day',
        countryCode: 'US',
        fixed: false,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-11-23',
        localName: 'Thanksgiving Day',
        name: 'Thanksgiving Day',
        countryCode: 'US',
        fixed: false,
        global: true,
        counties: null,
        launchYear: 1863,
        types: ['Public']
      },
      {
        date: '2023-12-25',
        localName: 'Christmas Day',
        name: 'Christmas Day',
        countryCode: 'US',
        fixed: false,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      }
    ]
  },
  {
    country: 'UK',
    data: []
  },
  {
    country: 'NG',
    data: [
      {
        date: '2023-01-01',
        localName: 'New Year&apo;s Day',
        name: 'New Year&apo;s Day',
        countryCode: 'NG',
        fixed: true,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-04-07',
        localName: 'Good Friday',
        name: 'Good Friday',
        countryCode: 'NG',
        fixed: false,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-04-10',
        localName: 'Easter Monday',
        name: 'Easter Monday',
        countryCode: 'NG',
        fixed: false,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-05-01',
        localName: 'Workers Day',
        name: 'Workers Day',
        countryCode: 'NG',
        fixed: true,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-05-27',
        localName: 'Childrens Day',
        name: 'Childrens Day',
        countryCode: 'NG',
        fixed: true,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-06-12',
        localName: 'Democracy Day',
        name: 'Democracy Day',
        countryCode: 'NG',
        fixed: true,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-10-01',
        localName: 'National Day',
        name: 'National Day',
        countryCode: 'NG',
        fixed: true,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-11-01',
        localName: 'National Youth Day',
        name: 'National Youth Day',
        countryCode: 'NG',
        fixed: true,
        global: true,
        counties: null,
        launchYear: 2020,
        types: ['Public']
      },
      {
        date: '2023-12-25',
        localName: 'Christmas Day',
        name: 'Christmas Day',
        countryCode: 'NG',
        fixed: true,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      },
      {
        date: '2023-12-26',
        localName: 'Boxing Day',
        name: 'Boxing Day',
        countryCode: 'NG',
        fixed: true,
        global: true,
        counties: null,
        launchYear: null,
        types: ['Public']
      }
    ]
  }
];

export { salesData, holiday };
