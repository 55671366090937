import { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './DashboardNavbar';
import { DashboardSidebar } from './DashboardSidebar';
import { useStore } from '../store';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}));

export const DashboardLayout = (props: any) => {
  const { isDashboard = false, children } = props;
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const { user }: any = useStore((state): any => state);

  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={
            isDashboard
              ? {
                  backgroundImage: 'url("/assets/images/dashboardbg.png")',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundAttachment: 'fixed',
                  display: 'flex',
                  flex: '1 1 auto',
                  flexDirection: 'column',
                  width: '100%',
                  backgroundColor: '#f3f3f3',
                  minHeight: '100vh',
                  padding: 1
                }
              : {
                  display: 'flex',
                  flex: '1 1 auto',
                  flexDirection: 'column',
                  width: '100%',
                  backgroundColor: '#f3f3f3',
                  minHeight: '100vh',
                  padding: 1
                }
          }>
          {children}
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar isDashboard={isDashboard} onSidebarOpen={() => setSidebarOpen(true)} />
      {user?.roles?.includes('ADMIN') && (
        <DashboardSidebar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />
      )}
    </>
  );
};
