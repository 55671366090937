import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material';
import { useStore } from '../../store';

const ImageProfile = () => {
  const { user }: any = useStore((state: any) => state);
  return (
    <Card>
      <CardContent>
        <Box className="flex flex-center flex-column">
          <Avatar
            src={user?.photo || ''}
            sx={{
              height: 64,
              mb: 2,
              width: 64
            }}
          />
          <Typography color="textPrimary" gutterBottom variant="h5">
            {user?.fullname || user?.firstname}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {`${user?.address || 'Address'} ${user?.city || ''}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" fullWidth variant="text">
          Upload picture
        </Button>
      </CardActions>
    </Card>
  );
};

export default ImageProfile;
