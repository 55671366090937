import { Link } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ErrorPage = () => (
  <>
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%'
      }}>
      <Container maxWidth="md">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Typography align="center" color="textPrimary" variant="h2">
            404: The page you are looking for isn’t here
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried some none existing route or you came here by mistake. Whichever it is,
            try using the navigation
          </Typography>
          <Box sx={{ textAlign: 'center' }}>
            <img
              alt="Under development"
              src="../../assets/svg/errorpage.svg"
              style={{
                marginTop: 50,
                display: 'inline-block',
                maxWidth: 'auto',
                width: 500
              }}
            />
          </Box>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Button
              color="primary"
              startIcon={<ArrowBackIcon fontSize="small" />}
              sx={{ mt: 3 }}
              variant="contained">
              Go back to dashboard
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  </>
);

export default ErrorPage;
