import { Box, Card, CardContent, CardHeader, LinearProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { useEffect, useState } from 'react';

export const size = {
  width: 400,
  height: 200
};

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 16,
  fontWeight: 700
}));

export function ProgressWithLabel({ label, value }: { label: string; value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', width: '100%' }}>
      <Box sx={{ flex: { xs: '1', sm: '0 0 150px' }, marginRight: '20px' }}>
        <Typography variant="subtitle1">{label}</Typography>
      </Box>
      <Box sx={{ flex: '1' }}>
        <LinearProgress
          color={label === 'Passed SEO' ? 'success' : label === 'Failed SEO' ? 'error' : 'warning'}
          variant="determinate"
          value={value}
          sx={{ height: '15px' }}
        />
      </Box>
      <Box sx={{ minWidth: '40px', marginLeft: '10px' }}>
        <Typography variant="body2" color="textSecondary">
          {value}%
        </Typography>
      </Box>
    </Box>
  );
}

export function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      SEO Score:
      {children}
    </StyledText>
  );
}

const Overview = ({ seoData }: any) => {
  const [seoScore, setSeoScore] = useState<number | string>(0);
  const [data, setData] = useState([
    { value: 30, label: 'Failed' },
    { value: 10, label: 'Warning' },
    { value: 60, label: 'Passed' }
  ]);
  const [totalIssues, setTotalIssues] = useState(0);

  useEffect(() => {
    const totalTests = Object.keys(seoData).length;
    const failedTests = Object.values(seoData).filter((test: any) => test.status === false).length;
    const passedTests = totalTests - failedTests;
    const calcPassed = ((passedTests / totalTests) * 100).toFixed(0);
    const calcFailed = ((failedTests / totalTests) * 100).toFixed(0);
    const calcWarning = 0;

    setSeoScore(calcPassed);
    setTotalIssues(failedTests);
    setData([
      { value: Number(calcFailed), label: 'Failed' },
      { value: calcWarning, label: 'Warning' },
      { value: Number(calcPassed), label: 'Passed' }
    ]);
  }, []);

  return (
    <Card variant="outlined">
      <CardHeader
        sx={{
          m: 0,
          p: 2
        }}
        title="Overview"
        subheader="Your generated SEO report summary"
      />
      <CardContent
        sx={{
          m: 0,
          p: 2
        }}>
        <Box mb={2} maxWidth={'700px'}>
          <Typography variant="subtitle1">
            This website received an SEO score of {seoScore} out of 100, which is{' '}
            {Number(seoScore) > 70 ? 'above' : 'below'} the average score of 70.
          </Typography>
          {Number(seoScore) < 70 && (
            <Typography variant="subtitle1">
              However, there are {totalIssues} important issues that need to be fixed to improve
              your website&apos;s ranking on search engines and enhance its overall performance.
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Box>
            <PieChart
              colors={['#FF0000', '#FFFF00', '#00FF00']}
              series={[{ data, innerRadius: 80 }]}
              {...size}>
              <PieCenterLabel>{seoScore} %</PieCenterLabel>
            </PieChart>
          </Box>
          <Box sx={{ flex: 1, width: '100%' }}>
            <ProgressWithLabel label="Failed SEO" value={Number(data[0].value)} />
            <ProgressWithLabel label="Warning SEO" value={Number(data[1].value)} />
            <ProgressWithLabel label="Passed SEO" value={Number(data[2].value)} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Overview;
