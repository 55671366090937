import { Delete, Keyboard, Mic, MicOutlined, RefreshOutlined, Stop } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CSSProperties, useEffect, useState } from 'react';
import { ReactMic } from 'react-mic';
import { Link, useNavigate } from 'react-router-dom';
import { generatePrompt } from '../../api';
import { useBuilderStore } from '../../hooks/useBuilderStore';
import { notifyError, notifyInfo, notifySuccess } from '../../lib/notification';
import { useStore } from '../../store';
import { templates } from '../../api/data';
import './chat.css';

type tError = {
  color: string;
  isError: boolean;
  msg: string;
};
const promptMock: string[] = [
  'I need a portfolio website to showcase my achievements and personal projects as an accountant',
  'Build an ecommerce website to showcase sales of furniture items'
];
const Detail = () => {
  const navigate = useNavigate();
  const { user } = useStore((state): any => state);
  const { chatResponse } = useBuilderStore((state): any => state);
  const [showMicrophone, setShowMicrophone] = useState<boolean>(false);

  const toggleMicrophone = () => {
    setShowMicrophone(!showMicrophone);
  };

  const [detail, setDetail] = useState<string>(chatResponse?.detail || '');
  const [record, setRecord] = useState<boolean>(false);
  const [error, setError] = useState<tError | null>(null);
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [layout, setLayout] = useState<string>('');
  const [template, setTemplate] = useState<any>(null);

  useEffect(() => {
    if (layout) {
      const selectedTemplate = templates.find((template) => template.name.toString() === layout);
      setTemplate(selectedTemplate);
    } else {
      setTemplate(null);
    }
  }, [layout]);

  const promptMutation = useMutation(generatePrompt, {
    onSuccess: (data) => {
      notifySuccess('Generating your website...');
      setLayout(data);
    },
    onError: (error) => {
      console.log('error', error);
      notifyError('Something went wrong, please try again');
    }
  });

  const _submit = async () => {
    if (!user) {
      notifyInfo('Membership is required! login or signup');
      navigate('/login');
      return;
    }
    if (!detail) {
      setError({
        color: 'error',
        isError: true,
        msg: 'Please enter a response'
      });
      return;
    }

    promptMutation.mutate({
      prompt: detail,
      useremail: user.email
    });
  };

  const onData = (recordedBlob: any) => {
    if (recordedBlob) {
      const data = recordedBlob.data;
      console.log(data);
    }
  };

  const onStop = (recordedBlob: any) => {
    if (recordedBlob) {
      const url = recordedBlob?.blobURL;
      setAudioUrl(url);
    }
  };
  const handleInitPropmpt = (selected: number) => {
    setDetail(promptMock[selected]);
  };

  return (
    <Box>
      {!user?.id && (
        <Typography variant="h6" gutterBottom>
          This is available to members only. Please{' '}
          <Link to={'/login'} className="customLink">
            Login
          </Link>{' '}
          or{' '}
          <Link to="/register" className="customLink">
            register!
          </Link>
        </Typography>
      )}

      {layout && (
        <Card sx={styles.root}>
          <Button
            sx={styles.backButton}
            variant="outlined"
            startIcon={<RefreshOutlined />}
            onClick={() => {
              setLayout('');
            }}>
            Reset
          </Button>
          <img
            style={styles.img as CSSProperties}
            src={template?.imageUrl || 'e-commerce'}
            title="Website Template"
          />
        </Card>
      )}
      <Box
        sx={{
          backgroundColor: 'white',
          height: 'auto',
          p: 2,
          borderRadius: '20px',
          mt: '240px'
        }}>
        {error?.isError && (
          <Typography variant="body2" color={error?.color}>
            {error?.msg}
          </Typography>
        )}
        <Box display={'flex'} alignItems={'center'} my={4}>
          {showMicrophone ? (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {audioUrl ? (
                  <>
                    <Paper
                      elevation={3}
                      sx={{
                        borderRadius: '10px',
                        background: '#f5f5f5',
                        width: '80%'
                      }}>
                      <div>
                        <audio controls>
                          <source src={audioUrl} type="audio/wav" />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    </Paper>
                    <Box sx={{ display: 'flex', marginTop: 2 }}>
                      <IconButton sx={{ margin: 1 }} size="small" color="primary">
                        <SendIcon
                          sx={{
                            mr: 1
                          }}
                        />
                        Submit
                      </IconButton>
                      <IconButton
                        sx={{ margin: 1 }}
                        size="small"
                        onClick={() => setAudioUrl('')}
                        color="error">
                        <Delete
                          sx={{
                            mr: 1
                          }}
                        />
                        Delete
                      </IconButton>
                    </Box>
                  </>
                ) : (
                  <>
                    <Paper
                      elevation={3}
                      sx={{
                        borderRadius: '10px',
                        background: '#f5f5f5',
                        width: '80%'
                      }}>
                      <ReactMic
                        record={record}
                        className="sound-wave"
                        onStop={onStop}
                        onData={onData}
                        strokeColor="#000000"
                        backgroundColor="#f5f5f5"
                      />
                    </Paper>
                    <Box sx={{ display: 'flex', marginTop: 2 }}>
                      {record ? (
                        <IconButton
                          sx={{ margin: 1 }}
                          size="small"
                          onClick={() => setRecord(false)}
                          color="secondary">
                          <Stop />
                          Stop
                        </IconButton>
                      ) : (
                        <IconButton
                          sx={{ margin: 1 }}
                          size="small"
                          onClick={() => setRecord(true)}
                          color="primary">
                          <MicOutlined />
                          Record
                        </IconButton>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </>
          ) : (
            <Box
              sx={{
                width: '100%'
              }}>
              <Stack direction={'row'} gap={1} flexWrap={'wrap'} mb={4}>
                {promptMock.map((prompt, idx) => (
                  <Chip
                    sx={{
                      height: 'auto',
                      p: 1,
                      '& .MuiChip-label': {
                        display: 'block',
                        whiteSpace: 'normal'
                      }
                    }}
                    key={prompt}
                    label={prompt}
                    onClick={() => handleInitPropmpt(idx)}
                  />
                ))}
              </Stack>

              <TextField
                id={'question-detail'}
                variant="outlined"
                rows={4}
                multiline
                fullWidth
                placeholder="detailed description of your website requirements including colors, business description, sales slogan, social media handle etc..."
                value={detail}
                onChange={(e) => setDetail(e.target.value)}
              />
            </Box>
          )}
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} mb={2}>
          <Button variant="contained" endIcon={<SendIcon />} onClick={_submit}>
            Submit
          </Button>
          <Button
            variant="outlined"
            onClick={toggleMicrophone}
            className="mt-2 flex items-center text-gray-600">
            {showMicrophone ? <Keyboard /> : <Mic />}
            {showMicrophone ? 'Switch to Keyboard' : 'Switch to Voice'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Detail;
const styles = {
  root: {
    maxWidth: '100%',
    margin: 'auto',
    marginTop: 3,
    padding: 2
  },
  backButton: {
    marginBottom: 2
  },
  img: { height: '100%', width: '100%', objectFit: 'cover' }
};
