import React from 'react';
import { InputField, PasswordField } from '../../components/InputFields';
import { useFormik } from 'formik';
import { DashboardLayout } from '../../components/DashboardLayout';
import { Box, Button, Container, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { addNewStaff, registerUser } from '../../api';
import { notifyError, notifySuccess } from '../../lib/notification';
import { staffSchema } from '../../lib/auth.schema';
import { useLocation } from 'react-router-dom';

const Addstaff = () => {
  const location = useLocation();
  const { id } = location.state;
  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      username: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      email: '',
      password: '',
      phone: ''
    },
    validationSchema: staffSchema,
    onSubmit: (values) => {
      const fullname = `${values.firstname} ${values.lastname}`;
      const username = `${values.firstname}${values.lastname}`;
      const toSave = { ...values, fullname, username };
      addStaffMutation.mutate(toSave);
    }
  });
  const addStaffMutation = useMutation(registerUser, {
    onSuccess: async (data) => {
      if (data?.id) {
        try {
          const reqData = { id, ownerId: data.id };
          await addNewStaff(reqData);
          notifySuccess('Account created!');
        } catch (error) {
          notifyError('Registration failed');
        }
      }
    }
  });
  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4
        }}>
        <Container maxWidth={false}>
          <Typography className="m" variant="h4">
            Add New Staff
          </Typography>
          <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column' }}
            onSubmit={formik.handleSubmit}>
            <InputField
              type="text"
              label="Firstname"
              name="firstname"
              id="firstname"
              error={Boolean(formik.touched.firstname && formik.errors.firstname)}
              helperText={formik.touched.firstname && formik.errors.firstname}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.firstname}
              required
              minlength="3"
            />
            <InputField
              type="text"
              label="Lastname"
              name="lastname"
              id="lastname"
              error={Boolean(formik.touched.lastname && formik.errors.lastname)}
              helperText={formik.touched.lastname && formik.errors.lastname}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.lastname}
              required
              minlength="3"
            />
            <InputField
              type="email"
              label="Email"
              name="email"
              id="email"
              error={Boolean(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <PasswordField
              type="password"
              label="Password"
              name="password"
              id="password"
              error={Boolean(formik.touched.password && formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password}
              required
            />
            <InputField
              placeholder="11 digits phone number"
              type="text"
              label="Phone"
              name="phone"
              id="phone"
              error={Boolean(formik.touched.phone && formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.phone}
              maxlength="11"
            />
            <InputField
              type="text"
              label="Address"
              name="address"
              id="address"
              error={Boolean(formik.touched.address && formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.address}
              required
            />
            <InputField
              type="text"
              label="City"
              name="city"
              id="city"
              error={Boolean(formik.touched.city && formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.city}
            />
            <InputField
              type="text"
              label="State"
              name="state"
              id="state"
              error={Boolean(formik.touched.state && formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.state}
            />
            <InputField
              type="text"
              label="Zip Code"
              name="zip"
              id="zip"
              error={Boolean(formik.touched.zip && formik.errors.zip)}
              helperText={formik.touched.zip && formik.errors.zip}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.zip}
            />
            <InputField
              type="text"
              label="Country"
              name="country"
              id="country"
              error={Boolean(formik.touched.country && formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.country}
              required
              minlength="3"
            />
            <Button
              variant="contained"
              fullWidth
              disabled={!formik.isValid || !formik.dirty}
              type="submit">
              Submit
            </Button>
          </Box>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Addstaff;
