import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function Policy() {
  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 }
      }}>
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' }
        }}>
        Our Privacy Policy
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Typography variant="body1" paragraph>
          Effective Start Date: 2023-01-01
        </Typography>
        <Typography variant="body1" paragraph>
          At ReachAI, we are committed to protecting the privacy and security of our users. This
          Privacy Policy outlines how we collect, use, and safeguard the information you provide
          when using our AI-powered digital marketing, website, and search engine optimization
          services. By accessing or using our services, you consent to the practices described in
          this policy.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          a. Personal Information: When you sign up for our services or contact us, we may collect
          personal information such as your name, email address, phone number, company name, and
          billing information.
        </Typography>
        <Typography variant="body1" paragraph>
          b. Usage Data: We automatically collect certain information about your interaction with
          our services, including IP address, browser type, device information, and referring/exit
          pages.
        </Typography>
        <Typography variant="body1" paragraph>
          c. Cookies and Similar Technologies: We use cookies and similar technologies to enhance
          your experience, analyze trends, and gather demographic information.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          a. To provide and improve our services: We use your information to deliver our AI-powered
          digital marketing, website, and search engine optimization services and to enhance their
          functionality and performance.
        </Typography>
        <Typography variant="body1" paragraph>
          b. To communicate with you: We may use your contact information to send you
          service-related notifications, updates, and promotional messages. You can opt out of
          receiving promotional communications at any time.
        </Typography>
        <Typography variant="body1" paragraph>
          c. To personalize your experience: We may use your information to customize and tailor our
          services to your specific needs and preferences.
        </Typography>
        <Typography variant="body1" paragraph>
          d. For research and analytics: We analyze aggregated and anonymized data to understand
          user behavior, improve our services, and develop new features.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          3. Information Sharing and Disclosure
        </Typography>
        <Typography variant="body1" paragraph>
          a. Service Providers: We may share your information with trusted third-party service
          providers who assist us in delivering our services, such as hosting providers and payment
          processors. These providers are obligated to maintain the confidentiality and security of
          your information.
        </Typography>
        <Typography variant="body1" paragraph>
          b. Legal Requirements: We may disclose your information if required by law, court order,
          or governmental request, or if we believe that such disclosure is necessary to protect our
          rights, property, or safety, or that of our users or the public.
        </Typography>
        <Typography variant="body1" paragraph>
          c. Business Transfers: In the event of a merger, acquisition, or sale of all or a portion
          of our assets, your information may be transferred as part of the transaction.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          4. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement reasonable security measures to protect your information from unauthorized
          access, alteration, disclosure, or destruction. However, no method of transmission over
          the internet or electronic storage is 100% secure, and we cannot guarantee absolute
          security.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          5. Your Choices and Rights
        </Typography>
        <Typography variant="body1" paragraph>
          a. Access and Update: You have the right to access, update, or delete your personal
          information. You can do so by logging into your account or contacting us directly.
        </Typography>
        <Typography variant="body1" paragraph>
          b. Opt-Out: You may opt out of receiving promotional communications from us by following
          the unsubscribe instructions provided in the messages or by contacting us.
        </Typography>
        <Typography variant="body1" paragraph>
          c. Cookies: You can modify your browser settings to disable cookies, but this may affect
          certain features of our services.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          6. Children's Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Our services are not directed to individuals under the age of 13. We do not knowingly
          collect personal information from children. If we become aware that we have collected
          personal information from a child without parental consent, we will take steps to remove
          that information from our servers.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          7. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. We will notify you of any material
          changes by posting the revised policy on our website or by other means. Your continued use
          of our services after any modifications indicates your acceptance of the updated policy.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions, concerns, or requests regarding this Privacy Policy or our
          privacy practices, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          ReachAI
          <br />
          [office@myeverlasting.net]
        </Typography>
        <Typography variant="body1" paragraph>
          By using ReachAI's services, you acknowledge that you have read and understand this
          Privacy Policy and agree to be bound by its terms and conditions.
        </Typography>
      </Box>
    </Container>
  );
}
