import { Box, Typography } from '@mui/material';
import ReusableOverview from './ReusableOverview';
import { useSeoInput } from '../../hooks/useSeoInput';
import ReusableSeo from './ReusableSeo';
import { useNavigate, useParams } from 'react-router-dom';
import { regexUrl } from '../../lib/util';

const PageObjects = () => {
  return (
    <Box>
      <ReusableOverview
        message={
          'To optimize website performance and enhance SEO, prioritize the efficient management of page objects such as images, JavaScript files, and network requests. Streamlining these elements improves page load speed, user experience, and search engine rankings.'
        }
        passed={true}
      />
      <Typography
        variant="h4"
        sx={{
          my: 2
        }}>
        How to pass
      </Typography>
      <Typography variant="subtitle1">
        To pass the SEO requirements, focus on optimizing page objects such as images, JavaScript
        files, and network requests. Streamline these elements by reducing network requests,
        compressing images, and combining JavaScript files to decrease load times. Optimize CSS,
        HTML, and other resource files to minimize size, enable browser caching for improved load
        speed, and implement lazy loading to prioritize critical content for faster rendering.
      </Typography>
    </Box>
  );
};

export default PageObjects;
export const PageObjectsSearch = () => {
  const { url, setUrl, error, setError } = useSeoInput();
  const navigate = useNavigate();
  const params = useParams();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);

    if (url && regexUrl(url)) return navigate(`/seo-result/${params.id}`);

    setError(true);
  };
  return (
    <ReusableSeo
      title="Page Objects SEO Test"
      keywords="Page Objects SEO Test"
      url={url}
      setUrl={setUrl}
      error={error}
      setError={setError}
      handleSubmit={handleSubmit}
    />
  );
};
