import * as Yup from 'yup';
import { userExist } from '../api';

export const loginSchema = Yup.object({
  email: Yup.string().label('Email').email('Invalid email').required('Required'),
  password: Yup.string()
    .label('Password')
    .min(5, 'Too Short!')
    .max(32, 'Too Long!')
    .required('Required')
});

export const passwordRegSchema = Yup.object({
  password: Yup.string()
    .label('Password')
    .min(5, 'Too Short!')
    .max(32, 'Too Long!')
    .required('Required'),
  confirmPassword: Yup.string()
    .label('Confirm password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required')
});

export const businessRegSchema = Yup.object({
  businessName: Yup.string().label('Business Name').required('Required'),
  businessEmail: Yup.string().label('Business Email').email('Invalid email').required('Required')
});

export const personalRegSchema = Yup.object().shape({
  contactName: Yup.string().label('Contact Name').required('Required'),
  contactEmail: Yup.string()
    .label('Contact Email')
    .email('Invalid email')
    .required('Required')
    .test('checkEmailExists', 'This email is already in use', (email) =>
      userExist(email).then((res) => {
        return res === false;
      })
    ),
  phone: Yup.string().label('Phone number')
});

export const addressRegSchema = Yup.object({
  address: Yup.string().label('Address').required('Required'),
  city: Yup.string().label('City').required('Required'),
  state: Yup.string().label('State').required('Required'),
  zip: Yup.string().label('Zip Code').max(8, 'Zip code is too long'),
  country: Yup.string().label('Country').required('Required')
});

export const profileSchema = Yup.object({
  fullname: Yup.string().label('Full Name').required(),
  bizname: Yup.string().label('Business Name'),
  phone: Yup.string().label('Phone Number'),
  email: Yup.string().label('Email Address').email('Invalid Email Address').required(),
  address: Yup.string().label('Address').required(),
  city: Yup.string().label('City'),
  zip: Yup.string().label('LGA/ZIP')
});
export const staffSchema = Yup.object({
  firstname: Yup.string().label('First Name').required().min(3, 'Minimum of 3 characters'),
  lastname: Yup.string().label('Last Name').required().min(3, 'Minimum of 3 characters'),
  email: Yup.string().label('Email Address').email('Invalid Email Address').required(),
  password: Yup.string().label('Password').required(),
  phone: Yup.string().label('Phone Number').max(11, '11 digits max'),
  address: Yup.string().label('Address').required(),
  city: Yup.string().label('City'),
  zip: Yup.string().label('LGA/ZIP'),
  country: Yup.string().label('Country').required().min(3)
});
