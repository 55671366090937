import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as Yup from 'yup';
import { addBudget, getApps } from '../../api';
import Select from 'react-select';
import { queryKeys } from '../../lib/queryKeys';
import { Option, tAddBudget } from '../../types/types';
import { DashboardLayout } from '../DashboardLayout';
import { InputField, SelectField } from '../InputFields';
import GenericToolbar from './GenericToolbar';
import { useNavigate } from 'react-router-dom';
import { notifySuccess, notifyError } from '../../lib/notification';
import { colourStyles } from '../../assets/styles/reactSelectStyles';
import { useStore } from '../../store';
import { createOption } from '../../lib/util';

const budgetSchema = Yup.object().shape({
  amount: Yup.number().min(1).required('Amount is required'),
  description: Yup.string().required('Description is required'),
  appId: Yup.number().required('App is required'),
  period: Yup.string().required('Period is required')
});

const AddBudget = () => {
  const navigate = useNavigate();
  const { user }: any = useStore((state): any => state);
  const [options, setOptions] = useState<any>({ label: '', value: {}, id: '' });
  const [value, setValue] = useState<Option | null>();

  const initialValues: tAddBudget = {
    amount: null,
    description: '',
    appId: null,
    period: 'ANNUAL'
  };
  const formik = useFormik<tAddBudget>({
    initialValues,
    validationSchema: budgetSchema,
    onSubmit: (values) => {
      mutation.mutate(values);
    }
  });

  const apps = useQuery([queryKeys.apps, { userId: user }], getApps, {
    refetchOnWindowFocus: false,
    retry: 2
  });

  const mutation = useMutation(addBudget, {
    onSuccess: () => {
      formik.resetForm();
      notifySuccess('Budget Added Successfully');
      navigate('/budget');
    },
    onError: (error) => {
      notifyError('Budget creation Failed');
      console.error(error);
    }
  });

  useEffect(() => {
    if (apps.data) {
      const options = apps.data.map((app: any) => createOption(app.appname, app));
      setOptions(options);
    }
  }, [apps.data]);

  const handleAppName = (newValue: any) => {
    formik.setValues({
      ...formik.values,
      appId: newValue.id
    });
    setValue(newValue as Option);
  };

  return (
    <DashboardLayout>
      <Box sx={{ flexGrow: 1, py: 4 }}>
        <Container>
          <GenericToolbar title="Add a Budget Form" />
          <Card
            sx={{
              my: 3
            }}>
            <CardHeader title="Add a Budget" subheader="All fields are required" />
            <Divider />

            <PerfectScrollbar>
              <CardContent sx={{ gap: 2 }}>
                <Select
                  isClearable
                  isDisabled={apps.isLoading}
                  isLoading={apps.isLoading}
                  onChange={(newValue) => {
                    handleAppName(newValue);
                  }}
                  options={options}
                  value={value}
                  styles={colourStyles}
                  className="customer-select"
                  classNamePrefix="select"
                  placeholder="Type and Select an App"
                />
                {formik.errors.appId && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'red',
                      fontSize: 14,
                      mx: 1
                    }}>
                    {formik.errors.appId}
                  </Typography>
                )}
                <InputField
                  type="text"
                  label="Budget Description"
                  name="description"
                  id="description"
                  error={Boolean(formik.touched.description && formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />
                <InputField
                  type="text"
                  label="Budget Amount(in number)"
                  name="amount"
                  id="amount"
                  error={Boolean(formik.touched.amount && formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.amount}
                />
                <Box my={1}>
                  <SelectField
                    fullWidth
                    id="period"
                    name="period"
                    type="text"
                    value={formik.values.period}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.period && Boolean(formik.errors.period)}
                    helperText={formik.touched.period && formik.errors.period}>
                    <option defaultValue="">Select Option</option>
                    <option value="DAILY">Daily</option>
                    <option value="MONTHLY">Monthly</option>
                    <option value="ANNUAL">Yearly</option>
                  </SelectField>
                </Box>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    my: 2
                  }}
                  fullWidth
                  size="large"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}>
                  Submit App
                </Button>
              </CardContent>
            </PerfectScrollbar>
          </Card>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default AddBudget;
