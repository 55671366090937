import React from 'react';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { tBudget } from '../../types/types';
interface Props {
  data: tBudget[];
}
const BudgetTable: React.FC<Props> = ({ data }: Props) => {
  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 920 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Period</TableCell>
                <TableCell>Expiry</TableCell>
                <TableCell>App</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((appData: tBudget) => {
                return (
                  <TableRow hover key={appData.id}>
                    <TableCell>{appData.id}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}>
                        <Typography color="textPrimary" variant="body1">
                          {appData.updatedOn}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{appData.amount}</TableCell>
                    <TableCell>{appData.period}</TableCell>
                    <TableCell>{appData?.expiry}</TableCell>
                    <TableCell>{appData?.appCode}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default BudgetTable;
