import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { templates } from '../../api/data';
import BuilderLayout from '../../components/website/BuilderLayout';
import { useNavigate } from 'react-router-dom';
const WebsitePreview = () => {
  const [template, setTemplate] = useState<any>(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const goBackToLayout = () => {
    navigate('/website-builder?step=2');
  };

  useEffect(() => {
    const selectedTemplate = templates.find((template) => template.name.toString() === id);
    setTemplate(selectedTemplate);
  }, []);
  return (
    <BuilderLayout title={template?.title}>
      <Card sx={styles.root}>
        <Button
          sx={styles.backButton}
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={goBackToLayout}>
          Back
        </Button>
        <img
          style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          src={template?.imageUrl}
          title="Website Template"
        />
      </Card>
    </BuilderLayout>
  );
};

export default WebsitePreview;
const styles = {
  root: {
    maxWidth: '100%',
    margin: 'auto',
    marginTop: 3,
    padding: 2
  },
  backButton: {
    marginBottom: 2
  }
};
