import { Delete, Send } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Modal,
  TextField,
  Typography
} from '@mui/material';
import React, { FC, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useStore } from '../../store';
import { addUsersToCustomer, removeUsersFromCustomer } from '../../api';
import { notifyError, notifySuccess } from '../../lib/notification';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};
interface Props {
  open: boolean;
  handleClose: () => void;
  staff: any;
}
const UserMgmtModal: FC<Props> = (props: Props) => {
  const { user }: any = useStore((state): any => state);

  const [emails, setEmails] = useState('');
  const [error, setError] = useState<{
    error: boolean;
    msg: string;
  } | null>(null);
  const [removeEmails, setRemoveEmails] = useState<any>({});
  const { handleClose, open, staff } = props;

  // api calls
  const { mutate, isLoading } = useMutation(addUsersToCustomer, {
    onSuccess: () => {
      notifySuccess('User was successfully added.');
      removeUser.mutate(removeEmails);
      setEmails('');
    },
    onError: () => {
      notifyError('User could not be added. Please try again');
      return;
    }
  });
  const removeUser = useMutation(removeUsersFromCustomer, {
    onSuccess: () => {
      notifySuccess('User was successfully moved to Users');
    },
    onError: () => {
      notifyError('User could not be removed. Please try again');
    }
  });

  const handleSubmitEmails = (emails: string[]) => {
    if (emails.length === 0) return;

    mutate({
      emails: emails,
      customerId: user.id
    });
    setRemoveEmails({
      emails: emails,
      customerId: user.id
    });
  };

  const handleAddUser = (event: any) => {
    event.preventDefault();
    setError(null);

    const sanitizedEmails: string[] = [];
    if (!emails) {
      setError({ error: true, msg: 'Please enter your email address' });
      return;
    }

    emails.split(',').forEach((email) => {
      if (!email || email.trim() === '') {
        return;
      } else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        setError({ error: true, msg: `${email} is Invalid, Please correct and try again` });
        return;
      } else {
        sanitizedEmails.push(email);
      }
    });

    handleSubmitEmails(sanitizedEmails);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Manage Users
          </Typography>
          <List>
            {staff?.map((item: any) => (
              <ListItem
                key={item.id}
                secondaryAction={
                  <IconButton edge="end" aria-label="delete">
                    <Delete />
                  </IconButton>
                }>
                <ListItemText primary={item.email} />
              </ListItem>
            ))}
          </List>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Add new users"
            value={emails}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setError(null);
              setEmails(event.target.value);
            }}
            variant="outlined"
            error={!!error && error.error}
            helperText={error && error.msg}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" disabled={isLoading} onClick={handleAddUser}>
                    <Send />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </>
    </Modal>
  );
};

export default UserMgmtModal;
