import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Download as DownloadIcon } from '../../icons/download';
import { Upload as UploadIcon } from '../../icons/upload';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../store';

interface Props {
  settingsRef?: any;
  setOpenImportPopover?: any;
  path: string;
  title: string;
  adminOnly?: boolean;
}
const ToolHeader = (props: Props) => {
  const { user }: any = useStore((state: any) => state);
  const { settingsRef, setOpenImportPopover, path, title, adminOnly } = props;
  const navigate = useNavigate();

  const adminUser = user?.roles?.includes('ADMIN');
  return (
    <Box className="resources__header">
      <Typography className="m" variant="h4">
        {title}
      </Typography>
      <Box className="m">
        <Button
          ref={settingsRef}
          onClick={() => setOpenImportPopover(true)}
          startIcon={<UploadIcon fontSize="small" />}
          sx={{ mr: 1 }}>
          Import
        </Button>
        <Button startIcon={<DownloadIcon fontSize="small" />} sx={{ mr: 1 }}>
          Export
        </Button>
        {adminOnly ? (
          adminUser && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                navigate(path, { replace: true });
              }}>
              Add {title}
            </Button>
          )
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate(path, { replace: true });
            }}>
            Add {title}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ToolHeader;
