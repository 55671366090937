import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Modal } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const tiers = [
  {
    title: 'Basic',
    price: '$49',
    description: ['Ideal for small businesses', 'Limited SEO report'],
    buttonText: 'Get Started',
    buttonVariant: 'outlined',
    soldOut: true
  },
  {
    title: 'Professional',
    subheader: 'Recommended',
    price: '$99',
    description: [
      'Best value for growing businesses',
      'Access to all basic features',
      'SEO fixes',
      'Targeted ADs'
    ],
    buttonText: 'Get Started',
    buttonVariant: 'contained',
    soldOut: true
  },
  {
    title: 'Enterprise',
    price: 'Custom',
    description: [
      'For large enterprises and agencies',
      'Access to all Pro features',
      'Priority customer support',
      'Dedicated account manager',
      'AD Hyper-personalization',
      'AI powered Reporting & Analytics',
      'GenAI for Web Assets'
    ],
    buttonText: 'Get Started',
    buttonVariant: 'outlined',
    soldOut: false
  }
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: {
    xs: '90%',
    sm: '90%',
    md: 600
  },
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  borderRadius: '12px',
  boxShadow: 24,
  p: 4
};

export default function Pricing() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 }
      }}>
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' }
        }}>
        <Typography component="h2" variant="h4" color="text.primary">
          Pricing
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Choose the plan that fits your business needs. <br />
          All plans include 24/7 support and regular updates.
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: tier.title === 'Professional' ? '1px solid' : undefined,
                borderColor: tier.title === 'Professional' ? 'primary.main' : undefined,
                background:
                  tier.title === 'Professional' ? 'linear-gradient(#033363, #021F3B)' : undefined
              }}>
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: tier.title === 'Professional' ? 'grey.100' : ''
                  }}>
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.soldOut && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={'SOLD OUT'}
                      size="small"
                      sx={{
                        background: (theme) => (theme.palette.mode === 'light' ? '' : 'none'),
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark'
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark'
                        }
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color: tier.title === 'Professional' ? 'grey.50' : undefined
                  }}>
                  <Typography component="h3" variant="h2">
                    {tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; per month
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500'
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center'
                    }}>
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color: tier.title === 'Professional' ? 'primary.light' : 'primary.main'
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color: tier.title === 'Professional' ? 'grey.200' : undefined
                      }}>
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant as 'outlined' | 'contained'}
                  sx={{
                    bgcolor: tier.buttonVariant === 'contained' ? 'white' : '',
                    color: tier.buttonVariant === 'contained' ? 'primary.main' : ''
                  }}
                  disabled={tier.soldOut}
                  onClick={handleOpen}
                  // component="a"
                  // rel="noopener noreferrer"
                  // href="#"
                  // target="_blank"
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Onboarding Guide
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              No onboarding slots at the moment, please <Link to="/contact">click here</Link> to
              submit a contact request our admin will get back to you ASAP
            </Typography>

            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                sx={{
                  mt: 2,
                  bgcolor: '#28a745',
                  color: 'whitesmoke',
                  ml: 'auto',
                  alignSelf: 'flex-end',
                  '&:hover': { bgcolor: '#218838' }
                }}
                onClick={() => navigate('/contact')}>
                Contact Us
              </Button>
            </Box>
          </Box>
        </>
      </Modal>
    </Container>
  );
}
