import React from 'react';
import { Card, Typography, Divider, Box, IconButton, styled } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CashFlowChart from './CashflowChart';
import { salesData } from '../../store/mockData';
import { tChart } from '../../types/types';

const FlexDiv = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1)
  };
});
let totalIncome = 0;
let totalSpending = 0;
let totalNet = 0;
const sData: tChart[] = salesData;

for (const transaction of sData) {
  const income = transaction.income;
  const spending = transaction.spending;
  const net = transaction.net;

  if (typeof income === 'number') {
    totalIncome += income;
  }
  if (typeof spending === 'number') {
    totalSpending += spending;
  }
  if (typeof net === 'number') {
    totalNet += net;
  }
}

const BalanceCard = () => {
  const [showBalance, setShowBalance] = React.useState(true);

  return (
    <Card sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
      <FlexDiv>
        <Typography variant="h6" component="h6">
          Balances
        </Typography>
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            space: '10px'
          }}>
          <IconButton onClick={() => setShowBalance(!showBalance)}>
            {showBalance ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
          </IconButton>
          <IconButton>
            <KeyboardArrowDownOutlinedIcon />
          </IconButton>
        </Box>
      </FlexDiv>
      <Divider />
      <Box component="section">
        <FlexDiv>
          <Typography
            variant="subtitle1"
            component="h6"
            sx={{
              fontWeight: 600
            }}>
            Available Balance
          </Typography>
          <Typography
            variant="subtitle1"
            component="h6"
            sx={{
              color: '#0CC809',
              fontWeight: 700
            }}>
            {showBalance ? 'N 200.00' : '********'}
          </Typography>
        </FlexDiv>

        <FlexDiv>
          <Typography
            variant="subtitle2"
            component="p"
            sx={{
              fontStyle: 'italic',
              fontWeight: 600
            }}>
            Reserves
          </Typography>
          <Typography variant="body1" component="p">
            {showBalance ? 'N 0' : '********'}
          </Typography>
        </FlexDiv>
      </Box>
    </Card>
  );
};

export default BalanceCard;

export const TotalBalance = () => {
  return (
    <Card
      sx={{
        my: 1,
        padding: 5,
        wrap: 'wrap',
        borderRadius: 2
      }}>
      <Typography variant="h6" component="h6" mb={4}>
        Total Income:{' '}
        <span
          style={{
            color: '#0bff0b'
          }}>
          $ {totalIncome}
        </span>{' '}
        Spending:{' '}
        <span
          style={{
            color: 'red'
          }}>
          $ {totalSpending}
        </span>{' '}
        Net: ${totalNet}
      </Typography>
      <CashFlowChart data={salesData} />
    </Card>
  );
};
