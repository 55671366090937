import React from 'react';
import { DashboardLayout } from '../../components/DashboardLayout';
import {
  Box,
  Card,
  CardContent,
  InputAdornment,
  TextField,
  Typography,
  SvgIcon
} from '@mui/material';
import { Search as SearchIcon } from '../../icons/search';
import '../../assets/styles/resources.css';

const Sales = () => {
  return (
    <DashboardLayout>
      <Box sx={{ py: 4 }}>
        <Box className="resources__header">
          <Typography className="m" variant="h4">
            Sales
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Card>
            <CardContent>
              <Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search Sales"
                  variant="outlined"
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Sales;
