import Layout from '../../components/auth/Layout';
import { InputField, PasswordField } from '../../components/InputFields';
import { Box, Button, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { loginSchema } from '../../lib/auth.schema';
import { useMutation } from '@tanstack/react-query';
import { loginUser } from '../../api';
import { notifyError, notifySuccess } from '../../lib/notification';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useStore } from '../../store';
import { Buffer } from 'buffer';
const fields = {
  header: 'Login to your account',
  subHeader: 'Enter your email and password to login',
  link: 'register',
  linkText: 'Register'
};
const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setUser }: any = useStore();
  // const [allCustomers, setAllCustomers] = useState([]);

  const loginMutation = useMutation(loginUser, {
    onSuccess: (data) => {
      notifySuccess('Login Successful');
      data.token = Buffer.from(`${formik.values.email}:${formik.values.password}`).toString(
        'base64'
      );
      formik.resetForm();

      // const customer: any = allCustomers?.find((customer: any) => customer.email === data.email);
      // localStorage.setItem(
      //   'user',
      //   JSON.stringify({
      //     ...data,
      //     customerId: customer?.id || data?.id
      //   })
      // );
      setUser({
        ...data
        // customerId: customer?.id || data?.id
      });
      sessionStorage.setItem('token', data.token);
      data?.custId === 367 ? navigate('/product/website/edit/4') : navigate('/dashboard');
    },
    onError: (error) => {
      notifyError('Login Failed! Please check your credentials');
      console.log(error);
    }
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      loginMutation.mutate(values);
      // const response = useQuery([queryKeys.customers], getCustomers);
      // setAllCustomers(response?.data);
    }
  });

  return (
    <Layout fields={fields} client={searchParams.get('client')}>
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={formik.handleSubmit}>
        <InputField
          type="email"
          label="Email"
          name="email"
          id="email"
          error={Boolean(formik.touched.email && formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        <PasswordField
          type="password"
          label="Password"
          name="password"
          id="password"
          error={Boolean(formik.touched.password && formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        <Stack direction={'row'} gap={4}>
          <Box component={Link} to={'/forgot-password'} sx={styles.forgot}>
            Forgot Password
          </Box>
          <Box component={Link} to={'/register'} sx={styles.forgot}>
            Register New Account
          </Box>
        </Stack>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loginMutation.isLoading || !formik.isValid || !formik.dirty}
          sx={{
            marginTop: 2
          }}>
          Login
        </Button>
      </Box>
    </Layout>
  );
};

export default Login;
const styles = {
  forgot: {
    textDecoration: 'underline',
    color: '#000',
    justifySelf: 'flex-end',
    alignSelf: 'flex-end',
    fontSize: 18
  }
};
