import { Box, Typography } from '@mui/material';
import ReusableOverview from './ReusableOverview';
import SEOBadge from './SEOBadge';
import { useSeoInput } from '../../hooks/useSeoInput';
import ReusableSeo from './ReusableSeo';
import { useNavigate, useParams } from 'react-router-dom';
import { regexUrl } from '../../lib/util';

const DOMTest = () => {
  return (
    <Box>
      <ReusableOverview
        message={`To optimize your website's performance and enhance SEO, aim to maintain a well-structured Document Object Model (DOM). The DOM represents the hierarchical structure of your webpage's elements and impacts factors such as page rendering speed and user experience.`}
        passed={true}
      />
      <Typography
        variant="h4"
        sx={{
          my: 2
        }}>
        How to pass
      </Typography>
      <Typography variant="subtitle1">
        To address this test result, analyze the composition of your webpage&apos;s DOM and identify
        areas for optimization. Aim to streamline the DOM by minimizing unnecessary nodes and
        consolidating elements where possible. Utilize techniques such as code refactoring, lazy
        loading of assets, and efficient use of HTML, CSS, and JavaScript to reduce the overall node
        count while preserving functionality and content integrity.
      </Typography>
      <SEOBadge type="success">
        <Box component={'pre'}>
          {`<-- Example of DOM optimization -->


<div class="container">
  <header>
    <nav>
      <ul>
        <li><a href="/">Home</a></li>
        <li><a href="/about">About</a></li>
        <li><a href="/services">Services</a></li>
        <li><a href="/contact">Contact</a></li>
      </ul>
    </nav>
  </header>
  <main>
    <section>
      <h1>Welcome to our Website</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </section>
    <section>
      <h2>Our Services</h2>
      <ul>
        <li>Service 1</li>
        <li>Service 2</li>
        <li>Service 3</li>
      </ul>
    </section>
  </main>
  <footer>
    <p>&copy; 2024 Example Website</p>
  </footer>
</div>

`}
        </Box>
      </SEOBadge>
      <SEOBadge type="error">
        <Box component={'pre'}>
          {`<-- Example of Unnecessary Nodes -->

<!-- Unnecessary use of div -->
<div class="wrapper">
  <div class="content">
    <div class="inner">
      <!-- Content here -->
    </div>
  </div>
</div>

<!-- Unnecessary nested elements -->
<section>
  <div>
    <div>
      <div>
        <!-- Content here -->
      </div>
    </div>
  </div>
</section>

<!-- Excessive use of div for styling purposes -->
<div class="box">
  <div class="box-header">
    <div class="box-content">
      <!-- Content here -->
    </div>
  </div>
</div>

`}
        </Box>
      </SEOBadge>
    </Box>
  );
};

export default DOMTest;
export const DOMTestSearch = () => {
  const { url, setUrl, error, setError } = useSeoInput();
  const navigate = useNavigate();
  const params = useParams();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);

    if (url && regexUrl(url)) return navigate(`/seo-result/${params.id}`);

    setError(true);
  };
  return (
    <ReusableSeo
      title="DOM Elements SEO Test"
      keywords="DOM Elements SEO Test"
      url={url}
      setUrl={setUrl}
      error={error}
      setError={setError}
      handleSubmit={handleSubmit}
    />
  );
};
