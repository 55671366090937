/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, styled } from '@mui/material';

export const InputContainer = styled(Box)(({ theme }) => ({
  flexGrow: 5,
  mx: 2,

  '@media (max-width: 600px)': {
    width: '100%',
    mx: 0
  }
}));
export const InputLabel = styled(Box)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 600,
  color: '#333',
  flexGrow: 1,
  minWidth: '100px',

  '@media (max-width: 600px)': {
    minWidth: '50px'
  }
}));

export const InputBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.1rem 0',

  '@media (max-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));
