import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'AI-Powered SEO',
    description: 'An AI-powered platform that goes beyond conventional SEO tactics.'
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Tangible Results',
    description: `Our solution is not just another GPT; it's about delivering tangible results and unmatched ROI using complex maths and statistics.`
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Targeted Audience',
    description:
      'Harness AI to learn from bad campaigns, maximise search engine visibility and drive organic traffic.'
  },
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Data-Driven Strategies',
    description: 'Precisely target ideal audience segments with data-driven strategies.'
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Continuous Learning',
    description: 'Continuous learning from user data for unmatched contextual relevance.'
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Content Optimization',
    description: 'Leveraging advanced AI/ML for content optimization & hyper-personalization.'
  }
];

export default function Features() {
  return (
    <Box
      id="features"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a'
      }}>
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 }
        }}>
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' }
          }}>
          <Typography component="h2" variant="h3">
            Features
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Explore why our product stands out: adaptability, durability, user-friendly design, and
            innovation. Enjoy reliable customer support and precision in every detail.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900'
                }}>
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
