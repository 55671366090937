import { Box, Grid, Typography, styled, useMediaQuery } from '@mui/material';
import logo from '../../assets/img/logo.png';
import { Link } from 'react-router-dom';
import '../../assets/styles/auth.css';
const MainSection = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  minWidth: '70%'
}));

const TopSection = styled(Box)<any>(() => ({
  minWidth: '100%',
  margin: '10px auto',
  display: 'flex'
}));

const Layout = ({ fields, currentStep, totalStep, landing = false, client, children }: any) => {
  const currentPath = window.location.href.split('/')[3];
  const lgUp = useMediaQuery('(min-width:600px)');
  const { header, subHeader } = fields;

  return (
    <Grid
      container
      spacing={3}
      sx={{
        minHeight: '100vh',
        overflow: 'hidden'
      }}>
      <Grid item xs={12} sm={3} sx={landing && lgUp ? styles.landing : styles.default}>
        {landing ? (
          <>
            {lgUp && (
              <Box sx={styles.landingText}>
                <TopSection component={Link} to={'/'} className="auth-layout-logo">
                  <img src={logo} alt="reach logo" className="h-16 w-auto" />
                </TopSection>
                <Box component="div">
                  <Typography align="center" variant="h1" component="h1" gutterBottom>
                    Increase your Reach
                  </Typography>
                  <Typography align="center" variant="h5" component="h2" gutterBottom>
                    Share your product with the world...
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <>
            <TopSection component={Link} to={'/'} className="auth-layout-logo">
              <img src={logo} alt="reach logo" className="h-16 w-auto" />
            </TopSection>
            {lgUp && (
              <MainSection>
                <Box component="div">
                  <img
                    src={
                      currentPath === 'login'
                        ? 'assets/images/business.png'
                        : 'assets/images/new-entry.png'
                    }
                    alt="An illustration of woman registering on her ipad"
                    style={{
                      width: '80%',
                      margin: '10px auto'
                    }}
                  />

                  <Typography align="center" variant="h4" component="h1" gutterBottom>
                    Increase your Reach
                  </Typography>
                  <Typography align="center" variant="h5" component="h2" gutterBottom>
                    Share your product with the world...
                  </Typography>
                </Box>
              </MainSection>
            )}
          </>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={9}
        sx={{
          backgroundColor: '#faf7f0',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        {/* <TopSection sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link to={`/${link}`} style={{ textDecoration: 'none' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                marginRight: 5
              }}>
              {linkText}
            </Button>
          </Link>
        </TopSection> */}
        <MainSection>
          <Box component="div" sx={{ minWidth: '70%' }}>
            {currentPath === 'register' && (
              <Typography variant="h5" gutterBottom>
                {' '}
                {`Step ${currentStep + 1} / ${totalStep}`}{' '}
              </Typography>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {client && (
                <img
                  src={`assets/images/${client}.png`}
                  alt={`${client} logo`}
                  style={styles.clientLogo}
                />
              )}
            </Box>
            <Typography
              variant="h2"
              gutterBottom
              style={{ fontWeight: 'bolder', textAlign: 'center' }}>
              {header}
            </Typography>
            <Typography
              variant="h4"
              component="p"
              gutterBottom
              style={{ fontWeight: 'bolder', textAlign: 'center' }}>
              {subHeader}
            </Typography>
            <Box component="div">{children}</Box>
          </Box>
        </MainSection>
      </Grid>
    </Grid>
  );
};

export default Layout;
const styles = {
  landing: {
    backgroundColor: '#000',
    color: '#fff',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  default: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  landingText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 3,
    flexGrow: 1,
    minWidth: '70%'
  },
  clientLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 3,
    marginBottom: 3,
    maxWidth: 250
  }
};
