import { Option } from '../types/types';

export const createOption = (label: string, value: any): Option => ({
  id: value.id || '',
  label,
  value: value
});

export function convertToCustomFormat(isoDate: string | number | Date) {
  const dateObject = new Date(isoDate);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function regexUrl(url: string) {
  const expression =
    /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
  const urlRegex = new RegExp(expression);

  if (url.match(urlRegex)) {
    return true;
  }

  return false;
}

export function generateRandomHexColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
