import { Box, Typography } from '@mui/material';
import React from 'react';

const NoValue = ({ text }: { text: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '40vh'
      }}>
      <Typography variant="h4">{text}</Typography>
    </Box>
  );
};

export default NoValue;
