import Cal from '@calcom/embed-react';
import { Container, Box } from '@mui/material';
import React from 'react';
import { DashboardLayout } from '../components/DashboardLayout';

const Appointment = () => {
  return (
    <DashboardLayout>
      <Container maxWidth={false}>
        <Box sx={{ py: 4 }}>
          <h1>Create Appointment</h1>
          <Cal
            calOrigin="http://localhost:3000/essl/15min?duration=15"
            config={{
              name: 'John Doe',
              email: 'you@youremail.net',
              notes: 'meeting agenda',
              guests: 's@me.com',
              theme: 'dark'
            }}
            calLink="essl"
          />
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default Appointment;
