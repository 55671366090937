import { tPricing, tQuestionaire } from '../types/types';

const qData: tQuestionaire[] = [
  {
    id: 1,
    title: 'What is the name of your company or service?',
    type: 'text'
  },
  {
    id: 2,
    title: 'What is the purpose of your Business/Product/Service?',
    type: 'text'
  },
  {
    id: 3,
    title: 'Do you have an existing website?',
    type: 'radio',
    parent: true,
    jumpNextQt: true
  },
  {
    id: 4,
    title: 'Website url? e.g www.femiadigun.com',
    type: 'text'
  },
  {
    id: 5,
    title:
      'What do you desire to achieve/accomplish with this website for each/every user group? For example, provide easily searchable information; drive more sales; drive more sign-ups; etc.',
    type: 'text'
  },
  {
    id: 6,
    title:
      'What are the specific functions required? For example, submission forms; e-commerce; blogging; etc. Provide a range of possibilities to your client based on the answer to the previous question.',
    type: 'text'
  },
  {
    id: 7,
    title: 'Who are the potential users of this site?',
    type: 'text',
    isSkippable: true
  },
  {
    id: 8,
    title:
      'What type of look do you want your website to have? e.g. clean, high-tech, conservative, warm, flashy, etc.',
    type: 'text'
  },
  {
    id: 9,
    title: 'Do you have a logo for this Business/Product/Service? ',
    type: 'radio',
    parent: true
  },
  // {
  //   id: 10,
  //   title: 'upload a digital copy of the Logo?i.e. Soft copy/ vector format of the Logo',
  //   type: 'upload'
  // },
  {
    id: 10,
    title:
      'What kind of display do you prefer on the masthead? e.g Slideshow, Animations, Web Banner/ Ad Banner',
    type: 'select',
    options: ['Slideshow', 'Animations', 'Web Banner/ Ad Banner']
  },
  {
    id: 11,
    title: 'What are your corporate colors?',
    type: 'text'
  },
  {
    id: 12,
    title:
      'Are there any other comments/specific ideas we should keep in mind while designing your website?',
    type: 'text',
    isSkippable: true
  },
  {
    id: 13,
    title: 'What is your X (formerly twitter) handle? e.g @esslng',
    type: 'text'
  },
  {
    id: 14,
    title: 'What is your instagram account or link? e.g @essloffice',
    type: 'text'
  },
  {
    id: 15,
    title: 'What is your youtube account or link? e.g @femiadigun',
    type: 'text'
  },
  {
    id: 16,
    title: 'What is your facebook account name or link? e.g @femiadigun',
    type: 'text'
  },
  {
    id: 17,
    title: 'What is your full contact address?',
    type: 'text'
  },
  {
    id: 18,
    title: 'What email address(es) should I display on your website?',
    type: 'text'
  },
  {
    id: 19,
    title: 'Kindly state the phone number including your country code you want displayed',
    type: 'text'
  }
];
export { qData };

export const templates = [
  {
    title: 'E-Commerce',
    content: 'Elegant e-commerce design for online stores.',
    cardImageUrl: '/assets/images/cardLayout-card.png',
    imageUrl: '/assets/images/cardLayout.png',
    name: 'e-commerce'
  },
  {
    title: 'Modern Techno',
    content: 'Sleek and tech-inspired design for modern websites.',
    cardImageUrl: '/assets/images/techy-card.png',
    imageUrl: '/assets/images/techy.png',
    name: 'techy'
  },
  {
    title: 'Fashion & Art',
    content: 'Stylish and trendy design for fashion-related websites.',
    cardImageUrl: '/assets/images/fashion-card.png',
    imageUrl: '/assets/images/fashion.png',
    name: 'fashion'
  },
  {
    title: 'Magazine',
    content: 'Dynamic layout for engaging online magazine sites.',
    cardImageUrl: '/assets/images/magazine-card.png',
    imageUrl: '/assets/images/magazine.png',
    name: 'magazine'
  },
  {
    title: 'Minimalistic',
    content: 'Clean and minimal design for a clutter-free look.',
    cardImageUrl: '/assets/images/minimalistic-card.png',
    imageUrl: '/assets/images/minimalistic.png',
    name: 'minimalistic'
  },
  {
    title: 'Mobile First ',
    content: 'Optimized for mobile devices with a responsive layout.',
    cardImageUrl: '/assets/images/mobileFirst-card.png',
    imageUrl: '/assets/images/mobileFirst.png',
    name: 'mobileFirst'
  },
  {
    title: 'News',
    content: 'Informative design perfect for news and articles.',
    cardImageUrl: '/assets/images/news-card.png',
    imageUrl: '/assets/images/news.png',
    name: 'news'
  },
  {
    title: 'Parallax',
    content: 'Engaging parallax scrolling for a dynamic experience.',
    cardImageUrl: '/assets/images/parallax-card.png',
    imageUrl: '/assets/images/parallax.png',
    name: 'parallax'
  },
  {
    title: 'Portfolio',
    content: 'Showcase your work with a professional portfolio.',
    cardImageUrl: '/assets/images/portfolio-card.png',
    imageUrl: '/assets/images/portfolio.png',
    name: 'portfolio'
  }
];
export const pricingData: tPricing[] = [
  {
    title: 'Qui-Gon',
    price: 12,
    features: ['1 corporate email', '1 website']
  },
  {
    title: 'Obi-Wan',
    price: 15,
    features: ['3 corporate email', '1 website']
  },
  {
    title: 'Anakin',
    price: 20,
    features: ['5 corporate email', '1 website']
  }
];
export const seoMock = {
  canonical_tag: null,
  charset: 'text/html',
  deprecated_tags: ['u'],
  disallow_directives: [],
  dns_spf_record: false,
  domsize: '682 of 1500',
  favicon: 'https://horacelearning.com/favicon.ico',
  h1s: ['Grow Your Skills\nUnlock Your Potential'],
  h2s: [],
  has_ads: false,
  has_analytics: true,
  has_canonized:
    'The URLs https://horacelearning.com and https://www.horacelearning.com resolve to the same IP address: 143.244.220.150',
  has_doctype_declaration: true,
  has_flash_multimedia: null,
  has_nested_tables: null,
  has_redirect: null,
  has_render_blocking: [
    'https://www.googletagmanager.com/gtag/js?id=G-2MBS3Z1BDF',
    'https://www.google-analytics.com/analytics.js',
    'https://www.googletagmanager.com/gtm.js?id=GTM-KSB8BZWS&gtm_auth=&gtm_preview=&gtm_cookies_win=x',
    'https://www.google-analytics.com/g/collect?v=2&tid=G-2MBS3Z1BDF&gtm=45je4130v9115154635z89171642681&_p=1704663028974&gcd=11l1l1l1l1&dma=0&cid=1382884717.1704663029&ul=en-us&sr=1280x720&uaa=arm&uab=64&uafvl=Not_A%2520Brand%3B8.0.0.0%7CChromium%3B120.0.6099.28%7CHeadlessChrome%3B120.0.6099.28&uamb=0&uam=&uap=macOS&uapv=14.1.1&uaw=0&_s=1&sid=1704663029&sct=1&seg=0&dl=https%3A%2F%2Fhoracelearning.com%2F&dt=Horace%20Learning&en=init_all_pages&_fv=1&_nsi=1&_ss=1&tfd=842',
    'https://material-ui.com/static/images/avatar/1.jpg'
  ],
  has_robots: false,
  has_sitemap: false,
  images: {
    correct_aspect_ratio: true,
    correct_dimensions: false,
    image_details: [
      {
        alt: '',
        height: null,
        src: 'data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271920%27%20height=%271080%27/%3e',
        width: null
      }
    ],
    properly_sized: true,
    required_alt_attribute: false
  },
  inline_styles: true,
  load_time: 'site loads in 1.28 from Oak Cliff Place, Texas, US. Recommended is under 5 seconds',
  media_query_responsive: true,
  meta_description: 'In Horace learning, We are building the best global education network',
  meta_refresh: null,
  nofollow_tag: null,
  noindex_tag: null,
  non_seofriendly_links: {
    friend: false,
    link_details: [
      {
        href: '/',
        rel: null,
        target: null,
        text: 'Home',
        title: null
      },
      {
        href: '/about/',
        rel: null,
        target: null,
        text: 'About',
        title: null
      },
      {
        href: '/courses/',
        rel: null,
        target: null,
        text: 'Courses',
        title: null
      },
      {
        href: '/contact/',
        rel: null,
        target: null,
        text: 'Contact',
        title: null
      },
      {
        href: '/login/',
        rel: null,
        target: null,
        text: 'Login',
        title: null
      },
      {
        href: '/sign-up/',
        rel: null,
        target: null,
        text: 'Sign Up',
        title: null
      },
      {
        href: 'https://www.facebook.com/horacelms',
        rel: 'noreferer noopener',
        target: '_blank',
        text: '',
        title: null
      },
      {
        href: 'https://www.twitter.com/essloffice',
        rel: 'noreferer noopener',
        target: '_blank',
        text: '',
        title: null
      },
      {
        href: 'https://www.instagram.com/',
        rel: 'noreferer noopener',
        target: '_blank',
        text: '',
        title: null
      },
      {
        href: 'https://www.linkedin.com/company/10654256/',
        rel: 'noreferer noopener',
        target: '_blank',
        text: '',
        title: null
      },
      {
        href: '/',
        rel: null,
        target: null,
        text: 'Home',
        title: null
      },
      {
        href: '/about/',
        rel: null,
        target: null,
        text: 'About',
        title: null
      },
      {
        href: '/courses/',
        rel: null,
        target: null,
        text: 'Courses',
        title: null
      },
      {
        href: '/contact/',
        rel: null,
        target: null,
        text: 'Contact',
        title: null
      },
      {
        href: '/contact/',
        rel: null,
        target: null,
        text: 'Send Feedback',
        title: null
      },
      {
        href: '/terms/',
        rel: null,
        target: null,
        text: 'Terms of Use',
        title: null
      },
      {
        href: '/privacy/',
        rel: null,
        target: null,
        text: 'Privacy Policy',
        title: null
      }
    ]
  },
  og_description: null,
  og_title: null,
  plaintext_emails: null,
  socials: [
    'https://www.facebook.com/horacelms',
    'https://www.twitter.com/essloffice',
    'https://www.instagram.com/',
    'https://www.linkedin.com/company/10654256/'
  ],
  title: 'Horace Learning',
  twitter_description: null,
  twitter_title: null,
  viewport_content:
    'The webpage at https://horacelearning.com is using a viewport meta tag with content: width=device-width'
};

export const seoTest = {
  canonical_tag: {
    title: 'Canonical Tag',
    status: null,
    notes:
      'The canonical tag helps search engines understand the preferred version of a page when multiple versions exist. It is advisable to set a canonical tag to avoid duplicate content issues.'
  },
  charset: {
    title: 'Charset',
    status: true,
    notes:
      'The charset is set to text/html, ensuring proper rendering of text. This is a good practice for SEO.'
  },
  deprecated_tags: {
    title: 'Deprecated Tags',
    status: false,
    notes:
      'Deprecated tags, like the "u" tag, may impact the pages semantic structure. It is recommended to replace deprecated tags with modern HTML equivalents for better compatibility and SEO.'
  },
  disallow_directives: {
    title: 'Disallow Directives',
    status: true,
    notes: `Disallow directives in the robots.txt file can control search engine crawlers' access to specific parts of the site. Ensure proper configuration to guide crawlers efficiently.`
  },
  dns_spf_record: {
    title: 'DNS SPF Record',
    status: false,
    notes:
      'Setting up a DNS SPF record enhances email deliverability and helps prevent email spoofing. Consider adding an SPF record to improve email security.'
  },
  domsize: {
    title: 'DOM Size',
    status: false,
    notes:
      'Reducing DOM size can improve page loading speed. Consider optimizing the DOM structure and minimizing unnecessary elements for better performance.'
  },
  favicon: {
    title: 'Favicon',
    status: true,
    notes:
      'A favicon enhances brand visibility and user experience. Ensure the favicon is relevant and distinctive for better recognition.'
  },
  h1s: {
    title: 'H1 Tags',
    status: true,
    notes:
      'H1 tags should provide a concise and descriptive summary of the page content. Ensure each H1 tag is unique and relevant to its section for optimal SEO.'
  },
  h2s: {
    title: 'H2 Tags',
    status: true,
    notes:
      'Use H2 tags to structure content and create a hierarchy. Ensure H2 tags are utilized appropriately to improve content organization and user experience.'
  },
  has_ads: {
    title: 'Ads Presence',
    status: false,
    notes:
      'The absence of ads can enhance page load speed and improve user experience. Consider optimizing ad placement and ensuring relevance to the content.'
  },
  has_analytics: {
    title: 'Analytics Presence',
    status: true,
    notes:
      'Analytics tools provide valuable insights into user behavior. Regularly analyze analytics data to make informed decisions and optimize the website for better performance.'
  },
  has_canonized: {
    title: 'Canonization',
    status: true,
    notes:
      'Canonicalization helps prevent duplicate content issues. Ensure consistent canonical tags across different versions of URLs to guide search engines to the preferred version.'
  },
  has_doctype_declaration: {
    title: 'Doctype Declaration',
    status: true,
    notes:
      'A proper doctype declaration ensures the browser renders the page in standards mode. Always include a valid doctype declaration for consistent rendering.'
  },
  has_flash_multimedia: {
    title: 'Flash Multimedia',
    status: null,
    notes:
      'Flash multimedia is deprecated and not supported by many devices. Consider using modern alternatives like HTML5 for multimedia elements to improve compatibility and performance.'
  },
  has_nested_tables: {
    title: 'Nested Tables',
    status: null,
    notes:
      'Avoiding nested tables can enhance page loading speed and improve accessibility. Use CSS for layout and structure to achieve a more responsive design.'
  },
  has_redirect: {
    title: 'Redirect',
    status: null,
    notes:
      'Properly configured redirects ensure users and search engines are directed to the correct pages. Regularly check and update redirects to maintain a healthy website structure.'
  },
  has_render_blocking: {
    title: 'Render Blocking',
    status: null,
    notes:
      'Reducing render-blocking resources can improve page load speed. Optimize CSS and JavaScript files to minimize their impact on rendering time for better user experience and SEO.'
  },
  has_robots: {
    title: 'Robots.txt',
    status: false,
    notes:
      'The absence of a robots.txt file may lead to unintended crawling of sensitive pages. Create and maintain a robots.txt file to guide search engine crawlers and protect specific areas of the site.'
  },
  has_sitemap: {
    title: 'Sitemap',
    status: false,
    notes:
      'A sitemap helps search engines understand the structure of the site and discover content efficiently. Create and submit a sitemap to major search engines for better indexation.'
  },
  images: {
    title: 'Images',
    status: null,
    notes:
      'Optimizing images improves page load speed and user experience. Compress and use appropriate formats for images to enhance performance.'
  },
  inline_styles: {
    title: 'Inline Styles',
    status: true,
    notes:
      'Minimizing the use of inline styles and utilizing external stylesheets can improve page loading speed and make styling more manageable. Consider externalizing styles for better maintainability.'
  },
  load_time: {
    title: 'Load Time',
    status: false,
    notes:
      'Reducing page load time enhances user experience and positively impacts SEO. Optimize assets, implement caching strategies, and use a content delivery network (CDN) for improved performance.'
  },
  media_query_responsive: {
    title: 'Media Query Responsive',
    status: true,
    notes:
      'Using responsive design with media queries ensures a consistent user experience across different devices. Prioritize mobile responsiveness for better search engine rankings.'
  },
  meta_description: {
    title: 'Meta Description',
    status: true,
    notes:
      'Craft compelling meta descriptions that accurately summarize page content. Aim for a length between 150 and 220 characters for optimal display in search engine results.'
  },
  meta_refresh: {
    title: 'Meta Refresh',
    status: null,
    notes:
      'Avoid using meta refresh tags for redirection as they may not be SEO-friendly. Use proper HTTP redirects for page redirection.'
  },
  nofollow_tag: {
    title: 'Nofollow Tag',
    status: null,
    notes:
      'Strategically use the nofollow tag to control the flow of PageRank. Apply nofollow to external links when needed, but avoid excessive use for a natural link profile.'
  },
  noindex_tag: {
    title: 'Noindex Tag',
    status: null,
    notes:
      'Use the noindex tag carefully to prevent specific pages from being indexed by search engines. Apply noindex to non-essential pages while ensuring important content remains indexable.'
  },
  non_seofriendly_links: {
    title: 'Non-SEOFriendly Links',
    status: null,
    notes:
      'Optimize anchor text and link structure for better SEO. Ensure descriptive anchor text and use proper link tags to enhance the relevance of links.'
  },
  og_description: {
    title: 'OG Description',
    status: null,
    notes:
      'Craft engaging Open Graph (OG) descriptions that encourage click-throughs when shared on social media platforms. Optimize OG descriptions for better visibility and user engagement.'
  },
  og_title: {
    title: 'OG Title',
    status: null,
    notes:
      'Create compelling Open Graph (OG) titles that accurately represent the content. Optimize OG titles for social media sharing to increase click-through rates.'
  },
  plaintext_emails: {
    title: 'Plaintext Emails',
    status: null,
    notes:
      'While plaintext emails are accessible, consider incorporating HTML elements for a visually appealing and user-friendly email design. Ensure a balance between accessibility and design aesthetics.'
  },
  socials: {
    title: 'Social Links',
    status: true,
    notes:
      'Integrate social links to improve online presence and user engagement. Regularly update and monitor social profiles for consistent branding and better visibility.'
  },
  title: {
    title: 'Title',
    status: true,
    notes:
      'Craft concise and descriptive titles that reflect the page content. Aim for a title length between 20 and 60 characters for optimal display in search engine results.'
  },
  twitter_description: {
    title: 'Twitter Description',
    status: null,
    notes:
      'Craft concise and engaging Twitter descriptions to encourage user engagement. Optimize descriptions for social media sharing to enhance visibility and click-through rates.'
  },
  twitter_title: {
    title: 'Twitter Title',
    status: null,
    notes:
      'Create compelling Twitter titles that accurately represent the content. Optimize Twitter titles for social media sharing to increase click-through rates.'
  },
  viewport_content: {
    title: 'Viewport Content',
    status: true,
    notes:
      'Set viewport meta tags with appropriate content to ensure proper rendering on various devices. Prioritize mobile responsiveness for better user experience and search engine rankings.'
  }
};
