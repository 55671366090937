import { Box, Container } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getApps } from '../../api';
import { DashboardLayout } from '../../components/DashboardLayout';
import ErrorTable from '../../components/ErrorTable';
import LoadingTable from '../../components/LoadingTable';
import GenericToolbar from '../../components/admin/GenericToolbar';
import AppsTable from '../../components/product/AppsTable';
import { queryKeys } from '../../lib/queryKeys';
import { useStore } from '../../store';
import { tApp } from '../../types/types';

const Apps = () => {
  const { user } = useStore((state: any) => state);
  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery([queryKeys.apps, { userId: user }], getApps, {
    enabled: !!user,
    retry: 2
  });
  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4
        }}>
        <Container maxWidth={false}>
          <GenericToolbar
            title="App"
            addBtn={user?.roles?.includes('ADMIN') && true}
            addFn={() => {
              navigate('/apps/add', {
                replace: true,
                state: { custId: user?.custId, usr: user?.id }
              });
            }}
            search={true}
          />
          <Box sx={{ mt: 3 }}>
            {isLoading ? (
              <LoadingTable />
            ) : error ? (
              <ErrorTable />
            ) : (
              <AppsTable data={data as tApp[]} />
            )}
          </Box>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Apps;
