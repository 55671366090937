import { Box, Button, InputAdornment, SvgIcon, TextField, Typography } from '@mui/material';
import Select from 'react-select';
import '../../assets/styles/resources.css';
import { Search as SearchIcon } from '../../icons/search';

import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getMyCustomers } from '../../api';
import { colourStyles } from '../../assets/styles/reactSelectStyles';
import { queryKeys } from '../../lib/queryKeys';
import { useStore } from '../../store';
import { createOption } from '../../lib/util';
import { Option } from '../../types/types';
const ReceiptToolbar = (props: any) => {
  const { setOpen, value, setValue } = props;
  const { user }: any = useStore((state: any) => state);

  const [options, setOptions] = useState<any>([]);

  const myCustomer: any = useQuery([queryKeys.mycustomers], () => getMyCustomers(user.id));

  useEffect(() => {
    if (myCustomer.data) {
      const options = myCustomer.data.map((customer: any) =>
        createOption(customer.fullname, customer)
      );
      setOptions(options);
      if (options) {
        setValue(options[0]);
      }
    }
  }, [myCustomer.data]);

  const handleSelectCustomer = (newValue: any) => {
    setValue(newValue as Option);
  };

  return (
    <Box>
      <Box className="resources__header">
        <Typography className="m" variant="h4">
          Receipts
        </Typography>
        <Box className="m">
          <Button color="primary" variant="contained" onClick={() => setOpen(true)}>
            Generate Receipt
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '8px',
            py: 5,
            px: 2
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              px: 2
            }}>
            <Box sx={{ flex: 1, mr: { xs: 0, sm: 2 }, width: '100%' }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action" fontSize="small">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search receipts"
                variant="outlined"
              />
            </Box>
            <Box sx={{ flex: 1, width: '100%' }}>
              <Select
                isClearable
                isDisabled={myCustomer.isLoading}
                isLoading={myCustomer.isLoading}
                onChange={(newValue) => handleSelectCustomer(newValue)}
                options={options}
                value={value}
                styles={colourStyles}
                className="customer-select"
                classNamePrefix="select"
                placeholder="Type a Customer Name to Select"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReceiptToolbar;
