import React from 'react';
import { Box, Container, Typography, Button, styled } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import { useParams } from 'react-router-dom';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
  })
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <AccountBoxIcon />,
    2: <ContactMailIcon />,
    3: <VideoLabelIcon />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const CustomerReview = (props: any) => {
  const { formik, handleBack, activeStep, steps, handleStep } = props;
  const { id } = useParams<{ id: string }>();
  return (
    <Box component="div" className="flex flex-center">
      <Container maxWidth="sm">
        <Typography variant="h4" component="h1" className="text-center py" gutterBottom>
          {!id ? 'Create New Customer' : 'Update Customer'}
        </Typography>
        <Typography variant="h6" component="h2" className="text-center my" gutterBottom>
          Review your information
        </Typography>

        <Typography variant="subtitle1" component="p" className="text-center p" gutterBottom>
          Click on the step to edit the information or click on the back button to go back to the
          previous step. Once you are done, click on the create new customer/update customer button
          to create/update the new customer.
        </Typography>

        <Box className="py2">
          <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
            {steps.map((label: string, index: number) => (
              <Step key={label}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box className="flex ">
          <Button
            variant="outlined"
            color="primary"
            disabled={activeStep < 1}
            fullWidth
            onClick={(e) => {
              e.preventDefault();
              handleBack();
            }}>
            Back
          </Button>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
            sx={{
              ml: 5
            }}>
            {!id ? 'Create New Customer' : 'Update Customer'}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default CustomerReview;
