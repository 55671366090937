import React from 'react';
import { DashboardLayout } from '../../components/DashboardLayout';
import EmptyState from '../../components/EmptyState';

const AdPage = () => {
  return (
    <DashboardLayout>
      <EmptyState />
    </DashboardLayout>
  );
};

export default AdPage;
