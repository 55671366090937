import React from 'react';

export default function Map() {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3464.8858986082146!2d-95.880999488953!3d29.723064233622377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8641233de3a3d5d7%3A0xc05c7ba9b9d765db!2sHaven%20Crest%20Ln%2C%20Fulshear%2C%20TX%2077441!5e0!3m2!1sen!2sus!4v1714902367817!5m2!1sen!2sus"
      width={'100%'}
      height="450"
      style={{ border: 0 }}
      // allowfullscreen="false"
      loading="lazy"
      title="map showing location"
      // referrerpolicy={'no-referrer-when-downgrade'}
    ></iframe>
  );
}
