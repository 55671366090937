import { Save } from '@mui/icons-material';
import { Box, IconButton, TextField } from '@mui/material';
import { FC, useState } from 'react';

interface Props {
  keyPath: string;
  label: string;
  value: string;
  onChange: (keyPath: string, newValue: string) => void;
}
const EditableText: FC<Props> = ({ keyPath, label, value, onChange }) => {
  const [v, setV] = useState<string>(value);
  const _change = (e: string) => {
    setV(e);
  };
  return (
    <Box sx={{ borderBottom: '1px solid gray', paddingY: 3 }}>
      <label htmlFor={keyPath}>{keyPath}</label>
      <TextField
        id={keyPath}
        label={label}
        value={v}
        onChange={(e) => _change(e.target.value)}
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <IconButton aria-label="save" onClick={() => onChange(keyPath, v)}>
        <Save />
      </IconButton>
    </Box>
  );
};
export default EditableText;
