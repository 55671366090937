import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

const UsersTable = ({ data }: any) => {
  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 920 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Address</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((customer: any) => {
                return (
                  <TableRow hover key={customer.id}>
                    <TableCell>{customer.id}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}>
                        <Typography color="textPrimary" variant="body1">
                          {`${customer.firstname} ${customer?.lastname ?? ''} (${
                            customer?.username
                          })`}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{customer.email}</TableCell>
                    <TableCell>{customer?.phone}</TableCell>
                    <TableCell>
                      {customer?.address}, {customer?.zip}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default UsersTable;
