import { Box, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { notifySuccess } from '../lib/notification';
import { useStore } from '../store';

export const AccountPopover = (props: any) => {
  const { user, setUser }: any = useStore((state): any => state);
  const navigate = useNavigate();
  const { anchorEl, onClose, open, ...other } = props;

  const handleSignOut = async () => {
    onClose?.();
    localStorage.removeItem('user');
    setUser(null);
    notifySuccess('You have been logged out successfully');
    navigate('/login', { replace: true });
    return;
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: { width: '300px' }
      }}
      {...other}>
      <Box
        sx={{
          py: 1.5,
          px: 2
        }}>
        <Typography variant="overline">Account</Typography>
        <Typography color="text.secondary" variant="body2">
          {user
            ? `${user.firstname} ${user.lastname || ''}`.trim() || user.fullname || 'John Doe'
            : 'John Doe'}
        </Typography>
      </Box>
      <MenuList
        disablePadding
        sx={{
          '& > *': {
            '&:first-of-type': {
              borderTopColor: 'divider',
              borderTopStyle: 'solid',
              borderTopWidth: '1px'
            },
            padding: '12px 16px'
          }
        }}>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </MenuList>
    </Popover>
  );
};
