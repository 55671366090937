const SocialTags = () => {
  const jsonLdData = {
    '@context': 'https://schema.org/',
    '@type': 'Reach AI',
    name: 'Website',
    url: 'https://www.reachai.online',
    logo: 'https://i.ibb.co/k58Kmy3/logo-large.png'
  };
  return (
    <>
      <meta
        name="description"
        content="Welcome to ReachAI. Build Leads, Get new Website, Optimize your website, Get Corporate email"
      />
      <meta
        name="keywords"
        content="website development in Cypress, website development in Houston, SEO,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency"
      />

      {/* Open Graph meta tags (Facebook) */}
      <meta
        property="og:title"
        content="ReachAI - Business Automation. Build Leads, Get new Website, Optimize your website"
      />
      <meta
        property="og:description"
        content="Welcome to ReachAI. Build Leads, Get new Website, Optimize your website, Get Corporate email"
      />
      <meta property="og:image" content="https://i.ibb.co/k58Kmy3/logo-large.png" />
      <meta property="og:url" content="reachai.online" />
      <meta property="og:type" content="website" />

      {/* Twitter Card meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="ReachAI - Business Automation. Build Leads, Get new Website, Optimize your website"
      />
      <meta
        name="twitter:description"
        content="Welcome to ReachAI. Build Leads, Get new Website, Optimize your website, Get Corporate email"
      />
      <meta name="twitter:image" content="https://i.ibb.co/k58Kmy3/logo-large.png" />
      <meta name="google-site-verification" content="gYZL1lzNNOW5ZMMEQzeVaoik8pboWMYEQjwlnhz0TA4" />
      <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
    </>
  );
};

export default SocialTags;
