import React from 'react';
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  TextField,
  Button,
  FormGroup,
  FormControlLabel
} from '@mui/material';
import '../assets/styles/emptystate.css';
import { useStore } from '../store';
const EmptyState = () => {
  const { user }: any = useStore((state): any => state);
  const [email, setEmail] = React.useState<string>('');
  const [checked, setChecked] = React.useState<string>('');
  const [submit, setSubmit] = React.useState<boolean>(false);
  const [submitMessage, setSubmitMessage] = React.useState<string>(
    ' Thanks for your interest. We will notify you when this feature launches.'
  );
  const [error, setError] = React.useState({
    error: false,
    errorMessage: ''
  });

  const onSubmit = () => {
    if (!user) {
      // match email regex
      if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        setError({ error: true, errorMessage: 'Please enter a valid email address' });
        return;
      } else if (!email) {
        setError({ error: true, errorMessage: 'Please enter your email address' });
        return;
      }
    } else if (user && !checked) {
      setError({
        error: true,
        errorMessage: 'Please check the box to confirm you want to be notified'
      });
      return;
    }
    if (checked === 'no') {
      setSubmitMessage('Thanks for your response. We appreciate');
    }
    setSubmit(true);
  };

  return (
    <Box className="center mt-50">
      {submit ? (
        <Typography
          variant="h5"
          sx={{
            my: 2
          }}>
          {submitMessage}
        </Typography>
      ) : (
        <Box className="center">
          <Typography variant="h5" className="my2">
            This feature will be launching soon. Will you like to be notified when it launches?
          </Typography>
          {user ? (
            <Box>
              <FormGroup>
                <RadioGroup
                  row
                  value={checked}
                  onChange={(e) => setChecked(e.target.value)}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group">
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormGroup>
            </Box>
          ) : (
            <Box className="half-screen">
              <TextField
                label="Email"
                fullWidth
                sx={{ backgroundColor: '#fff' }}
                margin="normal"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={error.error}
                helperText={error.errorMessage}
              />
            </Box>
          )}
          <Button className="half-screen" variant="contained" onClick={onSubmit}>
            Submit
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EmptyState;
