import { ArrowDropDownCircle } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip
} from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const Invoice = ({ invoice, setOpen, setSelectedInvoice }: any) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleNavigate = () => {
    navigate(`/invoices/${invoice.id}`);
  };

  const handleGenerateReceipt = (event: any) => {
    event.stopPropagation();
    setOpen(true);
    setSelectedInvoice(invoice);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow
      component={Box}
      onClick={handleNavigate}
      hover
      sx={{
        cursor: 'pointer',
        textDecoration: 'none'
      }}>
      <TableCell>{invoice.id}</TableCell>
      <TableCell>{invoice.number || 'Nil'}</TableCell>
      <TableCell>{invoice.frequency || 'Monthly'}</TableCell>
      <TableCell>{format(new Date(invoice.dueDate), 'MMM dd, yyyy')}</TableCell>

      <TableCell className="flex flex-space-between">
        <Tooltip title="view more">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <ArrowDropDownCircle color="primary" />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <MenuItem onClick={handleGenerateReceipt}>Generate Receipt</MenuItem>
          <MenuItem onClick={handleGenerateReceipt}>Capture Payment</MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              navigate('/invoice/add');
            }}>
            Edit Invoice
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={(e: any) => {
              e.stopPropagation();
              handleClose();
            }}>
            Close
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default Invoice;
