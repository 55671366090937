import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import dashboard from '../../assets/img/dashreachai.png';
import { useStore } from '../../store';

export default function Hero() {
  const { user }: any = useStore((state) => state);

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat'
      }}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 }
        }}>
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)'
            }}>
            Your Digital Marketing Partner
          </Typography>
          <Typography
            textAlign="center"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}>
            Easy-to-use AI-Powered Digital Marketing, Website, and SEO Solutions. ReachAI is poised
            to disrupt the digital marketing landscape, offering unparalleled AI-powered solutions
            to SMEs.
          </Typography>
          <Stack
            direction="column"
            alignSelf="center"
            textAlign="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}>
            {user ? (
              <>
                <Typography
                  variant="h1"
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignSelf: 'center',
                    textAlign: 'center',
                    fontSize: 'clamp(2.5rem, 10vw, 3rem)'
                  }}>
                  Continue your journey
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 0.5,
                    alignItems: 'center'
                  }}>
                  <Button
                    color="primary"
                    variant="contained"
                    component="a"
                    rel="noopener noreferrer"
                    href="/dashboard"
                    target="_blank">
                    Go To Dashboard
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 0.5,
                    alignItems: 'center'
                  }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    component="a"
                    rel="noopener noreferrer"
                    href="/login"
                    target="_blank">
                    Sign in
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    component="a"
                    rel="noopener noreferrer"
                    href="/register"
                    target="_blank">
                    Sign up
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </Stack>
        <Box
          id="image"
          sx={{
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 500 },
            width: '100%',
            backgroundImage: `url(${dashboard})`,
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor: alpha('#BFCCD9', 0.5),
            boxShadow:
              '0 0 1px rgba(0, 0, 0, 0.1), 1px 1.5px 2px -1px rgba(0, 0, 0, 0.15), 4px 4px 12px -2.5px rgba(0, 0, 0, 0.15)'
          }}
        />
      </Container>
    </Box>
  );
}
