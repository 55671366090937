import { create } from 'zustand';

interface PricingProps {
  title: string;
  price: number;
  features: string[];
}

export interface BuilderStore {
  id: number | null;
  layout: string;
  domain: string;
  domainForm: any;
  pricing: PricingProps | any;
  chatResponse: any;
  setChatResponse: (response: any) => void;
  setDomainForm: (formFields: any) => void;
  setLayout: (layout: string) => void;
  setId: (id: number) => void;
  setDomain: (domain: string) => void;
  setPricing: (item: PricingProps) => void;
}

export const useBuilderStore = create<BuilderStore>()((set) => ({
  id: null,
  domainForm: {},
  chatResponse: {},
  layout: '',
  domain: '',
  pricing: {
    title: '',
    price: 0,
    features: []
  },
  setLayout: (layout: string) => set({ layout }),
  setChatResponse: (response: any) => set({ chatResponse: response }),
  setDomainForm: (formFields: any) => set((state) => ({ ...state, domainForm: formFields })),
  setDomain: (domain: string) => set({ domain }),
  setId: (id: number) => set({ id }),
  setPricing: (pricing: any) =>
    set({
      pricing: {
        ...pricing
      }
    })
}));
