import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.png';

const TopSection = styled(Box)<any>(() => ({
  minWidth: '80%',
  margin: '10px 20px',
  display: 'flex'
}));

const MainSection = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  minWidth: '70%'
}));

const BuilderLayout = (props: { title?: string; children: React.ReactNode }) => {
  const { title, children } = props;
  return (
    <Box>
      <Grid
        container
        spacing={3}
        sx={{
          minHeight: '100vh'
        }}>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            backgroundColor: '#000',
            color: '#fff',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: { xs: 'auto', md: '100vh' },
            py: 5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}>
          <MainSection>
            <TopSection component={Link} to={'/'} className="auth-layout-logo">
              <img src={logo} alt="reach logo" className="h-16 w-auto" />
            </TopSection>
            <Box component="div">
              <Typography align="center" variant="h4" component="h1" gutterBottom>
                Increase your Reach
              </Typography>
              <Typography align="center" variant="h5" component="h2" gutterBottom>
                Build your product website with us
              </Typography>
            </Box>
          </MainSection>
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          sx={{
            backgroundColor: '#faf7f0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Typography
            variant="h4"
            sx={{
              my: 5
            }}>
            {title}
          </Typography>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default BuilderLayout;
