import { Box, Container } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getBudgets } from '../../api';
import { DashboardLayout } from '../../components/DashboardLayout';
import ErrorTable from '../../components/ErrorTable';
import LoadingTable from '../../components/LoadingTable';
import BudgetTable from '../../components/admin/BudgetTable';
import GenericToolbar from '../../components/admin/GenericToolbar';
import { queryKeys } from '../../lib/queryKeys';
import { useStore } from '../../store';
import { tBudget } from '../../types/types';

const Budget = () => {
  const { user } = useStore((state: any) => state);
  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery([queryKeys.budgets], getBudgets, {
    enabled: !!user
  });
  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4
        }}>
        <Container maxWidth={false}>
          <GenericToolbar
            title="Budget"
            addBtn={true}
            addFn={() => {
              navigate('/budget/add', { replace: true });
            }}
            search={true}
          />
          <Box sx={{ mt: 3 }}>
            {isLoading ? (
              <LoadingTable />
            ) : error ? (
              <ErrorTable />
            ) : (
              <BudgetTable data={data as tBudget[]} />
            )}
          </Box>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Budget;
