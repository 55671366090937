import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  CardContent,
  InputAdornment,
  SvgIcon,
  TextField,
  Modal
} from '@mui/material';
import { useState } from 'react';
import '../../assets/styles/resources.css';

import { DashboardLayout } from '../../components/DashboardLayout';
import Invoice from '../../components/invoice/Invoice';
import { Search as SearchIcon } from '../../icons/search';
import { useQuery } from '@tanstack/react-query';
import ToolHeader from '../../components/dashboard/ToolHeader';
import { queryKeys } from '../../lib/queryKeys';
import { getUserInvoices } from '../../api';
import { useStore } from '../../store';
import ReceiptModal from '../../components/receipt';
const Invoices = () => {
  const { user }: any = useStore((state): any => state);
  const [open, setOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const invoices = useQuery([queryKeys.userInvoices, { userId: user?.id }], getUserInvoices, {
    enabled: !!user
  });

  return (
    <DashboardLayout>
      <Container maxWidth={false}>
        <Box sx={{ py: 4 }}>
          <ToolHeader title="Invoices" path="/invoice/add" />
          <Box sx={{ mt: 3 }}>
            <Card>
              <CardContent>
                <Box sx={{ maxWidth: 500 }}>
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon color="action" fontSize="small">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search Invoices"
                    variant="outlined"
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 2
          }}>
          <Box sx={{ mt: 3 }}>
            <Card>
              <CardHeader title="All Invoices" />
              <PerfectScrollbar>
                <Box sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Invoice Id</TableCell>
                        <TableCell>Invoice Number</TableCell>
                        <TableCell>Frequency</TableCell>
                        <TableCell sortDirection="desc">Due Date</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoices.data &&
                        invoices.data.map((invoice: any) => (
                          <Invoice
                            key={invoice.id}
                            invoice={invoice}
                            setOpen={setOpen}
                            setSelectedInvoice={setSelectedInvoice}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Box>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            overflow: 'scroll'
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className="modal-style">
            <ReceiptModal setOpen={setOpen} selectedInvoice={selectedInvoice} />
          </Box>
        </Modal>
      </Container>
    </DashboardLayout>
  );
};

export default Invoices;
