import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { InputField } from '../InputFields';
import { useStore } from '../../store';

const CustomerPersonal = (props: any) => {
  const { user, setCustomerDetails }: any = useStore((state: any) => state);
  const { formik, handleComplete, handleBack, activeStep } = props;

  useEffect(() => {
    formik.setFieldValue('ownerId', user?.id);
  }, []);

  return (
    <Box component="div">
      <Box component="form">
        <InputField
          type="text"
          label="Business Name"
          name="fullname"
          id="fullname"
          error={Boolean(formik.touched.fullname && formik.errors.fullname)}
          helperText={formik.touched.fullname && formik.errors.fullname}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.fullname}
        />
        <InputField
          type="email"
          label="Email Address"
          name="email"
          id="email"
          error={Boolean(formik.touched.email && formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        <InputField
          type="text"
          label="Phone Number"
          name="phone"
          id="phone"
          error={Boolean(formik.touched.phone && formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.phone}
        />
        <InputField
          type="text"
          label="Currency"
          name="currency"
          id="currency"
          error={Boolean(formik.touched.currency && formik.errors.currency)}
          helperText={formik.touched.currency && formik.errors.currency}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.currency}
        />
        <InputField
          type="text"
          label="Note"
          name="note"
          id="note"
          multiline
          rows={4}
          error={Boolean(formik.touched.note && formik.errors.note)}
          helperText={formik.touched.note && formik.errors.note}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.note}
        />
        <Box sx={{ display: 'flex' }}>
          <Button
            variant="outlined"
            color="primary"
            disabled={activeStep < 1}
            fullWidth
            onClick={(e) => {
              e.preventDefault();
              handleBack();
            }}>
            Back
          </Button>
          <Button
            variant="contained"
            fullWidth
            disabled={!formik.isValid}
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              setCustomerDetails(formik.values);
              handleComplete();
            }}
            sx={{
              ml: 5
            }}>
            Next Step
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerPersonal;
