import { Box, Typography } from '@mui/material';
import ReusableOverview from './ReusableOverview';
import SEOBadge from './SEOBadge';
import { useSeoInput } from '../../hooks/useSeoInput';
import ReusableSeo from './ReusableSeo';
import { useNavigate, useParams } from 'react-router-dom';
import { regexUrl } from '../../lib/util';

const SecurityTest = () => {
  return (
    <Box>
      <ReusableOverview
        message={
          'To prioritize website security and enhance SEO, ensure the successful implementation of HTTPS, a secure communication protocol over the Internet. This not only protects user data but also positively impacts search engine rankings and user trust. '
        }
        passed={true}
      />
      <Typography
        variant="h4"
        sx={{
          my: 2
        }}>
        How to pass
      </Typography>
      <ul>
        <li>
          Activate the SSL certificate before its intended activation date to ensure its validity.
        </li>
        <li>Ensure the SSL certificate has not expired, maintaining continuous security.</li>
        <li>
          Confirm the correct listing of the website&apos;s hostname in the SSL certificate for
          accurate identification.
        </li>
        <li>
          Ensure the SSL certificate is trusted by all major web browsers, fostering user trust and
          confidence.
        </li>
        <li>
          Verify that the SSL certificate has not been revoked, maintaining its integrity and
          reliability.
        </li>
        <li>
          Ensure the SSL certificate was signed with a secure hash algorithm, guaranteeing robust
          encryption and protection against security threats.
        </li>
      </ul>

      <SEOBadge type="success">
        <Box component={'pre'}>
          {`<-- example of a .toml file for including security -->

[ssl]
activation_date = "YYYY-MM-DD"
expiry_date = "YYYY-MM-DD"
hostname = "www.example.com"
trusted_by_browsers = true
revoked = false
secure_hash_algorithm = "SHA-256"

`}
        </Box>
      </SEOBadge>
    </Box>
  );
};

export default SecurityTest;
export const SecurityTestSearch = () => {
  const { url, setUrl, error, setError } = useSeoInput();
  const navigate = useNavigate();
  const params = useParams();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);

    if (url && regexUrl(url)) return navigate(`/seo-result/${params.id}`);

    setError(true);
  };
  return (
    <ReusableSeo
      title="SEO Security Test"
      keywords="SEO Security Test"
      url={url}
      setUrl={setUrl}
      error={error}
      setError={setError}
      handleSubmit={handleSubmit}
    />
  );
};
