import { AddOutlined, DeleteOutline, SendOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Modal,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
  createInvoice,
  getAllProducts,
  getCustomer,
  getLastInvoice,
  getMyCustomers
} from '../../api';
import { colourStyles } from '../../assets/styles/reactSelectStyles';
import { DashboardLayout } from '../../components/DashboardLayout';
import { InputField, SelectField } from '../../components/InputFields';
import { createInvoiceSchema } from '../../lib/invoice.schema';
import { queryKeys } from '../../lib/queryKeys';
import { useStore } from '../../store';
import { notifyError, notifySuccess } from '../../lib/notification';
import InvoicePreview from '../../components/invoice/InvoicePreview';
import ProductModal from '../../components/product';
import { Option } from '../../types/types';
import { convertToCustomFormat, createOption } from '../../lib/util';

export interface Product {
  name: string;
  price: string;
  quantity: string;
  amount: string;
  description: string;
}

interface FormValues {
  customer?: string;
  customerId?: string | number;
  invoiceNumber: string | number;
  frequency: string | number;
  invoiceDate?: any;
  paymentDate?: any;
  dueDate?: any;
  paymentType: string | number;
  comment: string | number;
  products: Product[];
}

const InvoiceAdd = () => {
  const { user }: any = useStore((state: any) => state);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { cid } = useParams();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any>({ label: '', value: {}, id: '' });
  const [products, setProducts] = useState<any>({ label: '', value: {}, id: '' });
  const [value, setValue] = useState<Option | null>();
  const [productValue, setProductValue] = useState<Option | null>();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [preview, setPreview] = useState(false);

  const myCustomer: any = useQuery([queryKeys.mycustomers], () => getMyCustomers(user.id));
  const productsData: any = useQuery([queryKeys.products], () => getAllProducts());
  const addInvoice = useMutation(createInvoice, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data) => {
      notifySuccess('Invoice Created Successfully');
      queryClient.invalidateQueries([queryKeys.invoices]);
      navigate('/invoices');
    },
    onError: (error) => {
      notifyError('Error Creating Invoice, Please try again.');
      console.log(error);
    }
  });
  const oneCustomer: any = useQuery([queryKeys.customer], () => getCustomer(cid), {
    enabled: !!cid
  });
  const { data: lastInvoice } = useQuery(
    [queryKeys.lastInvoice, { customerId: value?.id || '' }],
    getLastInvoice,
    {
      enabled: !!value
    }
  );

  useEffect(() => {
    if (!lastInvoice) return;
    formik.setFieldValue('invoiceNumber', lastInvoice || '');
  }, [lastInvoice]);

  const initialValues: FormValues = {
    customer: '',
    customerId: '',
    invoiceNumber: '',
    frequency: '',
    dueDate: dayjs(new Date()),
    paymentType: '',
    comment: '',
    products: [
      {
        name: '',
        description: '',
        quantity: '',
        price: '',
        amount: ''
      }
    ]
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createInvoiceSchema,
    onSubmit: (values) => {
      const payload = {
        ownerId: value?.value?.id,
        number: values.invoiceNumber,
        dueDate: convertToCustomFormat(values.dueDate),
        frequency: values.frequency,
        note: values.comment,
        items: values.products.map((p) => ({
          name: p.name,
          description: p.description,
          quantity: p.quantity,
          price: p.price
        }))
      };

      addInvoice.mutate(payload);
    }
  });

  const { values } = formik;

  useEffect(() => {
    if (myCustomer.data) {
      const options = myCustomer.data.map((customer: any) =>
        createOption(customer.fullname, customer)
      );
      setOptions(options);
    }

    if (productsData.data) {
      const products = productsData.data.map((product: any) => createOption(product.name, product));
      setProducts(products);
    }
  }, [myCustomer.data, productsData.data]);
  useEffect(() => {
    if (oneCustomer.data) {
      handleAddCustomer({
        id: oneCustomer.data.id,
        label: oneCustomer.data.fullname,
        value: oneCustomer.data
      });
    }
  }, [oneCustomer.data]);
  useEffect(() => {
    async function scrollToError() {
      const el = document.querySelector('.Mui-error, [data-error]');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }

    scrollToError();
  }, [formik.errors, formik.isSubmitting]);

  const handleAddProduct = () => {
    formik.setValues({
      ...values,
      products: [
        ...values.products,
        { name: '', description: '', price: '', quantity: '', amount: '' }
      ]
    });
  };

  const handleRemoveProduct = (index: any) => {
    formik.setValues({
      ...values,
      products: values.products.filter((_, i) => i !== index)
    });
  };

  const handleCalculateAmount = (index: any) => {
    const product = values.products[index];
    const amount = parseFloat(product.price) * parseInt(product.quantity, 10) || 0;

    formik.setValues({
      ...values,
      products: values.products.map((p, i) =>
        i === index ? { ...p, amount: amount.toFixed(2) } : p
      )
    });
  };

  const handlePaymentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setValues({
      ...values,
      paymentType: event.target.value
    });
  };

  const handleChangeDate = async (type: string, date: Dayjs | null) => {
    if (type === 'dueDate') {
      formik.setValues({
        ...values,
        dueDate: dayjs(date).toISOString()
      });
      return;
    }
    formik.setValues({
      ...values,
      dueDate: dayjs(date).toISOString()
    });
  };

  const handleAddCustomer = (newValue: any) => {
    formik.setValues({
      ...values,
      customer: newValue.label,
      customerId: newValue.id
    });
    setValue(newValue as Option);
  };

  const handleAddProductValue = (value: any, index: number) => {
    const updatedProducts = [...values.products]; // Create a copy of the products array
    updatedProducts[index] = {
      name: value.name,
      description: value.description,
      price: value.cost || '',
      quantity: String(1),
      amount: value.cost || ''
    }; // Update the specific index with the new value

    formik.setValues({
      ...values,
      products: updatedProducts // Set the updated products array
    });
  };

  const handleCreate = () => {
    setOpen(true);
    return;
  };

  return (
    <DashboardLayout>
      <Box sx={styles.header}>
        <Typography variant="h4">Create Invoice</Typography>
        <Box>
          {/* <Button variant="contained" sx={styles.buttonOne} onClick={() => formik.handleSubmit()}>
            Create
          </Button> */}
          {preview ? (
            <Button variant="outlined" sx={styles.buttonTwo} onClick={() => setPreview(false)}>
              Back to Form
            </Button>
          ) : (
            <Button variant="outlined" sx={styles.buttonTwo} component={Link} to="/invoices">
              Cancel
            </Button>
          )}
        </Box>
      </Box>
      {preview ? (
        <Paper sx={styles.paper}>
          <InvoicePreview data={formik.values} customerData={selectedCustomer} />
          <Divider sx={styles.divider} />
          <Button
            variant="contained"
            onClick={() => setPreview(false)}
            sx={{
              py: 1.5
            }}>
            <Typography sx={{ fontWeight: 'bold', ml: 1 }}>Cancel Preview</Typography>
          </Button>
          <Divider sx={styles.divider} />
        </Paper>
      ) : (
        <Paper sx={styles.paper}>
          <Stack spacing={2}>
            <Typography variant="h5">Customer Details</Typography>
            <Box sx={styles.detailsForm}>
              <Box sx={styles.selectable}>
                {value ? (
                  <Box>
                    <Typography variant="h6" sx={{ color: 'gray' }}>
                      Selected Customer
                    </Typography>
                    <Box ml={3}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 18
                        }}>
                        {value.label}
                      </Typography>
                      <Typography variant="subtitle1">
                        {value?.value?.address || ''}, {value?.value?.city || ''}
                      </Typography>
                      <Typography variant="subtitle1" mt={5}>
                        {value?.value?.email}
                      </Typography>
                      <Stack direction={'row'} spacing={1}>
                        <Typography
                          variant="subtitle1"
                          sx={styles.links}
                          component={Link}
                          to={`/customers/edit/${value.value.id}`}>
                          Edit {value?.label}
                        </Typography>
                        <Typography variant="h6"> . </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={styles.links}
                          onClick={() => setValue(null)}>
                          Select a different customer
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Select
                      isClearable
                      isDisabled={myCustomer.isLoading}
                      isLoading={myCustomer.isLoading}
                      onChange={(newValue) => {
                        handleAddCustomer(newValue);
                        setSelectedCustomer(newValue);
                      }}
                      options={options}
                      value={value}
                      styles={colourStyles}
                      className="customer-select"
                      classNamePrefix="select"
                      placeholder="Type a Customer Name to Select"
                    />
                    {formik.errors.customer && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'red',
                          fontSize: 14,
                          mx: 1
                        }}>
                        {formik.errors.customer}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
              <Box sx={styles.shortInput}>
                <Stack spacing={2}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2 }}>
                    <InputLabel sx={styles.labelInput}>Invoice Number</InputLabel>
                    <InputField
                      fullWidth
                      id="invoiceNumber"
                      name="invoiceNumber"
                      type="text"
                      value={formik.values.invoiceNumber || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.invoiceNumber && Boolean(formik.errors.invoiceNumber)}
                      helperText={formik.touched.invoiceNumber && formik.errors.invoiceNumber}
                      margin={true}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2 }}>
                    <InputLabel sx={styles.labelInput}>Frequency</InputLabel>
                    <SelectField
                      fullWidth
                      id="frequency"
                      name="frequency"
                      type="text"
                      value={formik.values.frequency}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.frequency && Boolean(formik.errors.frequency)}
                      helperText={formik.touched.frequency && formik.errors.frequency}>
                      <option defaultValue="">Select Option</option>
                      <option value="one-time">One Time Payment</option>
                      <option value="monthly">Monthly</option>
                      <option value="yearly">Yearly</option>
                    </SelectField>
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2 }}>
                    <InputLabel sx={styles.labelInput}>Due Date</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker', 'DatePicker']}>
                        <DatePicker
                          label="Select a date"
                          value={values.dueDate}
                          onChange={(newValue: any) => handleChangeDate('dueDate', newValue)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                  {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2 }}>
                  <InputLabel sx={styles.labelInput}>Payment Date</InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        label="Select a date"
                        value={values.paymentDate}
                        onChange={(newValue: any) => handleChangeDate('payment', newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Stack> */}
                </Stack>
              </Box>
            </Box>
          </Stack>
          <Divider sx={styles.divider} />
          <Box>
            <Typography variant="h5">Billable Items</Typography>
            <Box sx={styles.billHeader}>
              <Typography variant="subtitle1" sx={{ flex: 3, ...styles.billHeaderItem }}>
                Item Name
              </Typography>
              <Typography variant="subtitle1" sx={{ flex: 1, ...styles.billHeaderItem }}>
                Quantity
              </Typography>
              <Typography variant="subtitle1" sx={{ flex: 1, ...styles.billHeaderItem }}>
                Price
              </Typography>
              <Typography variant="subtitle1" sx={{ flex: 1, ...styles.billHeaderItem }}>
                Amount
              </Typography>
              <Box sx={{ flex: 0.2 }} />
            </Box>

            {values.products.map((product, index, array) => (
              <Box key={index}>
                <Box sx={styles.billForm}>
                  <Box sx={styles.billName}>
                    <Box sx={styles.billNameInput}>
                      <CreatableSelect
                        isClearable
                        isDisabled={productsData.isLoading}
                        isLoading={productsData.isLoading}
                        onChange={(newValue) => {
                          handleAddProductValue(newValue.value, index);
                          setProductValue(newValue);
                        }}
                        options={products}
                        onCreateOption={handleCreate}
                        value={productValue}
                        styles={colourStyles}
                        className="product-select"
                        classNamePrefix="select"
                        placeholder="Item"
                      />
                    </Box>
                    <Box sx={styles.billItem}>
                      <InputField
                        name={`products[${index}].description`}
                        value={product.description}
                        onChange={formik.handleChange}
                        placeholder="Description"
                        multiline
                        rows={3}
                      />
                    </Box>
                  </Box>
                  <Box sx={styles.billItem}>
                    <InputField
                      name={`products[${index}].quantity`}
                      value={product.quantity}
                      placeholder="Quantity"
                      onChange={formik.handleChange}
                      onBlur={() => handleCalculateAmount(index)}
                      helperText="Number"
                    />
                  </Box>
                  <Box sx={styles.billItem}>
                    <InputField
                      name={`products[${index}].price`}
                      value={product.price}
                      placeholder="Price"
                      helperText="Number"
                      onChange={formik.handleChange}
                      onBlur={() => handleCalculateAmount(index)}
                    />
                  </Box>
                  <Box sx={styles.billAmount}>
                    <Typography variant="subtitle1">
                      $ {product.amount ? parseInt(product.amount).toFixed(2) : 0}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 0.2, py: 3 }}>
                    <IconButton onClick={() => handleRemoveProduct(index)}>
                      <DeleteOutline sx={styles.icon} />
                    </IconButton>
                  </Box>
                </Box>
                {index !== array.length - 1 && <Divider sx={styles.divider} />}
              </Box>
            ))}

            <Divider sx={styles.divider} />
            <Button
              variant="outlined"
              onClick={handleAddProduct}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <AddOutlined /> <Typography sx={{ fontWeight: 'bold', ml: 1 }}>Add Item</Typography>
            </Button>
            <Divider sx={styles.divider} />

            <Box sx={styles.total}>
              <Box
                flex={{
                  xs: 0,
                  sm: 2
                }}
              />
              <Box flex={1}>
                <Typography variant="h6">Subtotal</Typography>
                <Typography variant="subtitle1">
                  ${' '}
                  {values.products[0].amount
                    ? values.products
                        .reduce((acc, product) => acc + parseFloat(product.amount), 0)
                        .toFixed(2)
                    : 0}
                </Typography>
              </Box>
              <Box flex={1}>
                <Typography variant="h5">Invoice Total</Typography>
                <Typography variant="h6">
                  ${' '}
                  {values.products[0].amount
                    ? values.products
                        .reduce((acc, product) => acc + parseFloat(product.amount), 0)
                        .toFixed(2)
                    : 0}
                </Typography>
              </Box>
              <Divider sx={styles.divider} />
            </Box>
          </Box>
          <Divider sx={styles.divider} />
          <Box>
            <Typography variant="h5">Payment Options</Typography>
            {formik.errors.paymentType && (
              <Typography
                variant="body2"
                sx={{
                  color: 'red',
                  fontSize: 16,
                  my: 2
                }}>
                {formik.errors.paymentType}
              </Typography>
            )}
            <FormControl
              component="fieldset"
              fullWidth
              sx={{
                mt: 3
              }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.paymentType === 'card'}
                    onChange={handlePaymentTypeChange}
                    value="card"
                  />
                }
                label={
                  <Box display={'flex'} alignItems={'center'}>
                    <img
                      src={'/assets/images/credit-card.png'}
                      alt="credit card"
                      style={styles.paymentIcon}
                    />
                    <Box ml={2}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Debit or Credit Cards
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'gray' }}>
                        Pay with your debit or credit card
                      </Typography>
                    </Box>
                  </Box>
                }
                sx={styles.paymentOptions}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.paymentType === 'stripe'}
                    onChange={handlePaymentTypeChange}
                    value="stripe"
                  />
                }
                label={
                  <Box display={'flex'} alignItems={'center'}>
                    <img
                      src={'/assets/images/stripe.png'}
                      alt="Stripe logo"
                      style={styles.paymentIcon}
                    />
                    <Box ml={2}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        Stripe
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'gray' }}>
                        Pay with your Stripe account
                      </Typography>
                    </Box>
                  </Box>
                }
                sx={styles.paymentOptions}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.paymentType === 'paypal'}
                    onChange={handlePaymentTypeChange}
                    value="paypal"
                  />
                }
                label={
                  <Box display={'flex'} alignItems={'center'}>
                    <img
                      src={'/assets/images/paypal.png'}
                      alt="Paypal logo"
                      style={styles.paymentIcon}
                    />
                    <Box ml={2}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        PayPal
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'gray' }}>
                        Pay with your Paypal account
                      </Typography>
                    </Box>
                  </Box>
                }
                sx={styles.paymentOptions}
              />
            </FormControl>
          </Box>
          <Divider sx={styles.divider} />
          <Box>
            <Typography variant="h5">Additional Information</Typography>
            <Box my={3}>
              <InputField
                fullWidth
                multiline
                rows={4}
                id="comment"
                name="comment"
                label="Comment"
                type="text"
                value={formik.values.comment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.comment && Boolean(formik.errors.comment)}
                helperText={formik.touched.comment && formik.errors.comment}
                margin={true}
              />
            </Box>
          </Box>

          <Divider sx={styles.divider} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              py: 1.5
            }}>
            <Button
              variant="outlined"
              sx={{ ...styles.buttonTwo, py: 1.5, mr: 2 }}
              onClick={() => setPreview(true)}>
              Preview Invoice
            </Button>
            <Button
              variant="contained"
              onClick={() => formik.handleSubmit()}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                py: 1.5,
                alignSelf: 'flex-end'
              }}>
              <SendOutlined />{' '}
              <Typography sx={{ fontWeight: 'bold', ml: 1 }}>Create Invoice</Typography>
            </Button>
          </Box>
          <Divider sx={styles.divider} />
        </Paper>
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          overflow: 'scroll'
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="modal-style">
          <ProductModal setOpen={setOpen} />
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default InvoiceAdd;
const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    m: 3
  },
  buttonOne: {
    px: 3,
    mr: 3
  },
  buttonTwo: {
    px: 3
  },
  paper: {
    m: 2,
    p: 3
  },
  detailsForm: {
    display: {
      xs: 'block',
      sm: 'flex'
    },
    justifyContent: 'space-between'
  },
  selectable: {
    minWidth: {
      xs: '90%',
      sm: 'auto',
      md: 400
    }
  },
  shortInput: {
    minWidth: {
      xs: '90%',
      sm: 'auto',
      md: 300
    },
    mx: {
      xs: '0',
      sm: 5
    }
  },
  labelInput: {
    fontWeight: 'bold',
    flex: 1,
    justifySelf: 'flex-end',
    alignSelf: {
      xs: 'flex-start',
      sm: 'center'
    }
  },
  links: {
    textDecoration: 'none',
    color: '#2212da',
    cursor: 'pointer'
  },
  billHeader: {
    display: {
      xs: 'none',
      sm: 'flex'
    },
    my: 2
  },
  billHeaderItem: {
    fontSize: 18,
    fontWeight: 'semibold'
  },
  billForm: {
    display: {
      xs: 'block',
      sm: 'flex'
    },
    my: 2
  },
  billName: {
    flex: {
      sm: 4,
      md: 3
    },
    display: {
      xs: 'block',
      sm: 'flex'
    },
    justifyContent: 'space-between'
  },
  billNameInput: {
    flex: {
      xs: 1,
      md: 0.6
    }
  },
  billItem: {
    flex: 1,
    ml: {
      xs: 0,
      sm: 2
    }
  },
  billAmount: {
    flex: 1,
    mx: 2,
    py: {
      xs: 1,
      sm: 4
    }
  },
  icon: { fontSize: 30, color: 'red' },
  divider: { my: 3 },
  total: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    my: 3
  },
  paymentOptions: {
    border: '1px solid',
    borderColor: '#E6E8F0',
    borderRadius: 1,
    p: 1,
    my: 1,
    mx: {
      xs: 2,
      sm: 5
    }
  },
  paymentIcon: {
    height: 50,
    width: 50
  }
};
