import { Box, Button, Card, CardContent, CardHeader, Divider } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useState } from 'react';
import { addReceipt } from '../../api';
import { notifyError, notifySuccess } from '../../lib/notification';
import { queryKeys } from '../../lib/queryKeys';
import { receiptSchema } from '../../lib/receipt.schema';
import ReceiptDetail from './ReceiptDetail';
import ReceiptForm from './ReceiptForm';
interface Option {
  readonly label: string;
  readonly value: string;
}
const ReceiptModal = ({
  setOpen,
  selectedInvoice
}: {
  setOpen: (open: boolean) => void;
  selectedInvoice: any;
}) => {
  const queryClient = useQueryClient();
  const [preview, setPreview] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([{ label: '', value: '' }]);
  const [value, setValue] = useState<Option[] | []>([]);

  const formik = useFormik({
    initialValues: {
      from: '',
      to: '',
      title: '',
      currency: 'USD',
      message: '',
      inv: selectedInvoice?.id || ''
    },
    validationSchema: receiptSchema,
    onSubmit: (values) => {
      const submitValues = {
        ...values,
        status: 'DRAFT',
        recipients: value.map((val) => val.value)
      };
      receiptMutation.mutate(submitValues);
    }
  });

  const receiptMutation = useMutation(addReceipt, {
    onSuccess: () => {
      notifySuccess('Receipt sent successfully');
      setOpen(false);
      queryClient.invalidateQueries([queryKeys.receipts]);
    },
    onError: (error) => {
      notifyError('Something went wrong');
      console.log(error);
    }
  });

  const formProps = {
    formik,
    options,
    value,
    setValue,
    setOptions
  };
  return (
    <Card>
      {preview ? (
        <Box>
          <CardHeader title="Receipt Preview" />
          <Divider />
          <CardContent>
            <ReceiptDetail
              values={{ ...formik.values, to: options.map((option: any) => option.value) }}
            />
          </CardContent>
        </Box>
      ) : (
        <Box>
          <CardHeader title="Send a Receipt" />
          <Divider />
          <CardContent>
            <ReceiptForm {...formProps} />
          </CardContent>
        </Box>
      )}
      <Divider />

      <Box className="receipts-modal__bottom">
        <Box>
          <Button variant="outlined" fullWidth onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </Box>
        <Box className="receipts-modal__bottom">
          <Button
            variant="outlined"
            onClick={() => setPreview(!preview)}
            sx={{
              mx: 2,
              my: 2
            }}>
            {preview ? 'View Form' : 'View Preview'}
          </Button>
          <Button
            variant="contained"
            disabled={!formik.isValid || !formik.dirty}
            onClick={() => formik.handleSubmit()}>
            Send
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default ReceiptModal;
