import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  InputLabel,
  Typography
} from '@mui/material';
import { DashboardLayout } from '../DashboardLayout';
import GenericToolbar from './GenericToolbar';
import { Option, tAddTimeSheet } from '../../types/types';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { addTimeSheet, getBudgets } from '../../api';
import { useStore } from '../../store';
import * as Yup from 'yup';
import { InputField } from '../InputFields';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { queryKeys } from '../../lib/queryKeys';
import { convertToCustomFormat, createOption } from '../../lib/util';
import Select from 'react-select';
import { colourStyles } from '../../assets/styles/reactSelectStyles';
import { useNavigate } from 'react-router-dom';
import { notifySuccess, notifyError } from '../../lib/notification';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';

const timeSheetSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  budgetId: Yup.number().required('Budget is required'),
  from: Yup.string().required('From date is required'),
  to: Yup.string().required('To date is required'),
  week: Yup.number().required('Week is required')
});

const AddTimeSheet = () => {
  const { user }: any = useStore((state): any => state);
  const navigate = useNavigate();

  const [options, setOptions] = useState<any>({ label: '', value: {}, id: '' });
  const [value, setValue] = useState<Option | null>();

  const initialValues: tAddTimeSheet = {
    title: '',
    budgetId: null,
    description: '',
    from: dayjs(new Date()),
    to: dayjs(new Date()),
    usr: user.id,
    week: null
  };
  const formik = useFormik<tAddTimeSheet>({
    initialValues,
    validationSchema: timeSheetSchema,
    onSubmit: (values) => {
      mutation.mutate({
        ...values,
        from: convertToCustomFormat(values.from),
        to: convertToCustomFormat(values.to)
      });
    }
  });

  const budgets = useQuery([queryKeys.budgets, { userId: user }], getBudgets, {
    refetchOnWindowFocus: false,
    retry: 2
  });

  const mutation = useMutation(addTimeSheet, {
    onSuccess: () => {
      formik.resetForm();
      notifySuccess('TimeSheet Added Successfully');
      navigate('/timesheet');
    },
    onError: (error) => {
      notifyError('TimeSheet creation Failed');
      console.error(error);
    }
  });

  useEffect(() => {
    if (budgets.data) {
      const options = budgets.data.map((app: any) => createOption(app.appname, app));
      setOptions(options);
    }
  }, [budgets.data]);

  const handleAppName = (newValue: any) => {
    formik.setValues({
      ...formik.values,
      budgetId: newValue.id
    });
    setValue(newValue as Option);
  };

  const handleChangeDate = async (type: string, date: Dayjs | null) => {
    if (type === 'from') {
      formik.setValues({
        ...formik.values,
        from: dayjs(date).toISOString()
      });
      return;
    }
    formik.setValues({
      ...formik.values,
      to: dayjs(date).toISOString()
    });
  };

  return (
    <DashboardLayout>
      <Box sx={{ flexGrow: 1, py: 4 }}>
        <Container>
          <GenericToolbar title="Add New Time Sheet Form" />
          <Card
            sx={{
              my: 3
            }}>
            <CardHeader title="Add a new timesheet" subheader="All fields are required" />
            <Divider />

            <PerfectScrollbar>
              <CardContent sx={{ gap: 2 }}>
                <InputField
                  type="text"
                  label="Timesheet Title"
                  name="title"
                  id="title"
                  error={Boolean(formik.touched.title && formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                <InputField
                  type="text"
                  label="Timesheet Description"
                  name="description"
                  id="description"
                  error={Boolean(formik.touched.description && formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />
                <InputField
                  type="text"
                  label="Timesheet Week"
                  name="week"
                  id="week"
                  error={Boolean(formik.touched.week && formik.errors.week)}
                  helperText={formik.touched.week && formik.errors.week}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.week}
                />
                <Select
                  isClearable
                  isDisabled={budgets.isLoading}
                  isLoading={budgets.isLoading}
                  onChange={(newValue) => {
                    handleAppName(newValue);
                  }}
                  options={options}
                  value={value}
                  styles={colourStyles}
                  className="customer-select"
                  classNamePrefix="select"
                  placeholder="Type and Select a Budget"
                />
                {formik.errors.budgetId && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'red',
                      fontSize: 14,
                      mx: 1
                    }}>
                    {formik.errors.budgetId}
                  </Typography>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={styles.labelInput}>From</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker', 'DatePicker']}>
                        <DatePicker
                          label="Select Date"
                          value={formik.values.from}
                          onChange={(newValue: any) => handleChangeDate('from', newValue)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {formik.errors.from && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'red',
                          fontSize: 14,
                          mx: 1
                        }}>
                        {formik.errors.from?.toString()}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={styles.labelInput}>To</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker', 'DatePicker']}>
                        <DatePicker
                          label="Select Date"
                          value={formik.values.to}
                          onChange={(newValue: any) => handleChangeDate('to', newValue)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {formik.errors.to && (
                      <Typography
                        variant="body2"
                        sx={{
                          color: 'red',
                          fontSize: 14,
                          mx: 1
                        }}>
                        {formik.errors.to?.toString()}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    my: 2
                  }}
                  fullWidth
                  size="large"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}>
                  Submit App
                </Button>
              </CardContent>
            </PerfectScrollbar>
          </Card>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default AddTimeSheet;

const styles = {
  labelInput: {
    fontWeight: 'bold',
    flex: 1,
    justifySelf: 'flex-end',
    alignSelf: {
      xs: 'flex-start',
      sm: 'center'
    }
  }
};
