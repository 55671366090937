import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Stack
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { addProduct, getMyCustomers, getProductDetail, updateProduct } from '../../api';
import { InputField } from '../../components/InputFields';
import { productSchema } from '../../lib/customer.schema';
import { notifyError, notifySuccess } from '../../lib/notification';
import { queryKeys } from '../../lib/queryKeys';
import { useStore } from '../../store';
import { colourStyles } from '../../assets/styles/reactSelectStyles';
import Select from 'react-select';
import { createOption } from '../../lib/util';
import { Option } from '../../types/types';

const ProductModal = (props: any) => {
  const { user, productId, setProductId }: any = useStore((state: any) => state);
  const { setOpen } = props;
  const queryClient = useQueryClient();

  const [options, setOptions] = useState<any>([]);
  const [value, setValue] = useState<Option | null>();

  const product = useQuery([queryKeys.product], () => getProductDetail(productId), {
    enabled: !!productId
  });

  const editProduct = useMutation(updateProduct, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data) => {
      notifySuccess('Product Edit Successfully');
      queryClient.invalidateQueries([queryKeys.customerProducts, queryKeys.products]);
      setOpen(false);
      setProductId(null);
    },
    onError: (error: any) => {
      notifyError('Error Editing Product');
      console.log(error.message);
    }
  });

  const newProduct = useMutation(addProduct, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data) => {
      notifySuccess('Product Added Successfully');
      queryClient.invalidateQueries([queryKeys.customerProducts, queryKeys.products]);
      setOpen(false);
      setProductId(null);
    },
    onError: (error: any) => {
      notifyError('Error Creating New Product');
      console.log(error.message);
    }
  });

  const myCustomer: any = useQuery([queryKeys.mycustomers], () => getMyCustomers(user.id));

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      start: new Date(),
      expiry: new Date(),
      cost: 0,
      frequency: '',
      statistics: ''
    },
    validationSchema: productSchema,
    onSubmit: (values) => {
      const start = new Date(values.start).toISOString();
      const expiry = new Date(values.expiry).toISOString();
      if (productId) {
        editProduct.mutate({
          ...values,
          start,
          expiry
        });
      } else {
        newProduct.mutate({
          ...values,
          customerId: value?.value.id,
          active: true,
          start,
          expiry
        });
      }

      return;
    }
  });

  useEffect(() => {
    if (productId) {
      formik.setValues({
        ...product.data,
        start: new Date(product.data.start) || new Date(),
        expiry: new Date(product.data.expiry) || new Date()
      });
    }
  }, [product.data]);

  useEffect(() => {
    if (myCustomer.data) {
      const options = myCustomer.data.map((customer: any) =>
        createOption(customer.fullname, customer)
      );
      setOptions(options);
      if (options) {
        setValue(options[0]);
      }
    }
  }, [myCustomer.data]);

  const handleSelectCustomer = (newValue: any) => {
    setValue(newValue as Option);
  };

  return (
    <Card>
      <Box>
        <CardHeader title="Add a New Product (Note: All fields are mandatory)" />
        <Divider />
        <CardContent>
          <Box component="form">
            <InputField
              type="text"
              label="Name"
              name="name"
              id="name"
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            <InputField
              type="text"
              label="Description"
              name="description"
              id="description"
              error={Boolean(formik.touched.description && formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
            />
            <Box mb={2.5}>
              <Select
                isClearable
                isDisabled={myCustomer.isLoading}
                isLoading={myCustomer.isLoading}
                onChange={(newValue) => handleSelectCustomer(newValue)}
                options={options}
                value={value}
                styles={colourStyles}
                className="customer-select"
                classNamePrefix="select"
                placeholder="Type a Customer Name to Select"
              />
            </Box>

            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={'Start Date'}
                  value={formik.values.start}
                  onChange={(e: any) => formik.setFieldValue('start', e)}
                  format="yyyy-MM-dd"
                  closeOnSelect
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={'Expiry'}
                  value={formik.values.expiry}
                  onChange={(e: any) => formik.setFieldValue('expiry', e)}
                  closeOnSelect
                  format="yyyy-MM-dd"
                />
              </LocalizationProvider>
            </Stack>

            <InputField
              type="text"
              label="Cost"
              name="cost"
              id="cost"
              error={Boolean(formik.touched.cost && formik.errors.cost)}
              helperText={formik.touched.cost && formik.errors.cost}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.cost}
            />
            <FormControl fullWidth>
              <InputLabel id="billing-frequency">Billing Frequency</InputLabel>
              <MuiSelect
                labelId="billing-frequency"
                id="frequency"
                name="frequency"
                error={Boolean(formik.touched.frequency && formik.errors.frequency)}
                onBlur={formik.handleBlur}
                value={formik.values.frequency}
                label="Billing Frequency"
                onChange={formik.handleChange}>
                <MenuItem value={'MONTHLY'}>Monthly</MenuItem>
                <MenuItem value={'ANNUAL'}>Annual</MenuItem>
                <MenuItem value={'ONETIME'}>One Time</MenuItem>
              </MuiSelect>
              <FormHelperText>{formik.touched.frequency && formik.errors.frequency}</FormHelperText>
            </FormControl>
            <InputField
              type="text"
              label="Statistics"
              name="statistics"
              id="statistics"
              multiline
              rows={4}
              error={Boolean(formik.touched.statistics && formik.errors.statistics)}
              helperText={formik.touched.statistics && formik.errors.statistics}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.statistics}
            />
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                fullWidth
                disabled={!formik.isValid && !user.id}
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}>
                Submit
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

export default ProductModal;
