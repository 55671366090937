import React from 'react';
import { Table, TableRow, TableCell, Typography, Box, TableHead, TableBody } from '@mui/material';
import { useParams } from 'react-router-dom';
import { DashboardLayout } from '../DashboardLayout';
import { SeverityPill } from '../SeverityPills';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../lib/queryKeys';
import { getCustomer, getInvoice } from '../../api';

const InvoiceDetails = () => {
  const { id } = useParams();
  const { data } = useQuery(
    [
      queryKeys.invoice,
      {
        id: id
      }
    ],
    getInvoice,
    {
      enabled: !!id
    }
  );

  const customerData: any = useQuery([queryKeys.customer], () => getCustomer(data?.ownerId), {
    enabled: !!data?.ownerId
  });

  const itemsTotal = data?.items?.map((item: any) => item.price * item.quantity);
  const invoiceTotal = itemsTotal?.reduce((acc: number, item: number) => acc + item, 0);

  return (
    <DashboardLayout>
      <Box component="div" sx={{ bgcolor: 'background.paper', m: 5 }}>
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: 'primary.main',
            color: 'white',
            p: 5
          }}>
          <Box component="div">
            <Typography variant="h4">Invoice</Typography>
          </Box>
          <Typography variant="h4">{data?.number}</Typography>
        </Box>
        <Box
          component="div"
          sx={{ p: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box component="div" sx={{ pb: 3 }}>
            <Box
              component="div"
              sx={{
                pb: 3
              }}>
              <Typography variant="h6">Invoice Date</Typography>
              <Typography variant="body1">{data?.dueDate || new Date().toDateString()}</Typography>
            </Box>
            <Box component="div">
              <Typography variant="h6">Customer</Typography>
              <Typography variant="body1">
                {customerData?.data?.fullname || 'Customer Name'}
              </Typography>
              <Typography variant="body1">
                {customerData?.data?.address || 'Customer Address'}
              </Typography>
              <Typography variant="body1">
                {customerData?.data?.email || 'Customer Email'}
              </Typography>
              <Typography variant="body1">
                {customerData?.data?.phone || 'Customer Phone'}
              </Typography>
            </Box>
          </Box>
          <Box component="div" sx={{ px: 5 }}>
            <Typography variant="h4">Invoice Total</Typography>
            <Typography variant="h4">${invoiceTotal || 'Total'}</Typography>
          </Box>
        </Box>
        <Box component="div" sx={{ p: 5 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Quatity</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.items?.map((item: any, index: number) => (
                <TableRow key={index} hover>
                  <TableCell>{item?.name || 'item name'}</TableCell>
                  <TableCell>{item?.quantity || 'quantity'}</TableCell>
                  <TableCell>${item?.price || 'price'}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="h5">Invoice Total</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h5">${invoiceTotal || 'Total'}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="h5">Invoice Status</Typography>
                </TableCell>
                <TableCell>
                  <SeverityPill
                    color={
                      (data?.status === 'paid' && 'success') ||
                      (data?.status === 'failed' && 'error') ||
                      'warning'
                    }>
                    {data?.status}
                  </SeverityPill>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default InvoiceDetails;
