import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet';
import AppAppBar from '../../components/landing/AppAppBar';
import FAQ from '../../components/landing/FAQ';
import Features from '../../components/landing/Features';
import Footer from '../../components/landing/Footer';
import Hero from '../../components/landing/Hero';
import Pricing from '../../components/landing/Pricing';
import Testimonials from '../../components/landing/Testimonials';
import WhatWeOffer from '../../components/landing/WhatWeOffer';
import SocialTags from '../../components/SocialTags';

const Landing = () => {
  return (
    <div>
      <Helmet>
        <title>
          ReachAI - Business Automation. Build Leads, Get digital powered websites and marketing
          your businesses - Home
        </title>
        <meta
          name="description"
          content="Welcome to ReachAI. Build Leads, Get digital powered websites and marketing your businesses "></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston, SEO,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, Digital powered websites, marketing my businesses, reachai, Digital powered websites, marketing my businesses, reachai, ESSL"
        />
        <SocialTags />
      </Helmet>
      <main>
        <CssBaseline />
        <AppAppBar />
        <Hero />
        <Box>
          <Features />
          <WhatWeOffer />
          <Testimonials />
          <Divider />
          <Pricing />
          <Divider />
          <FAQ />
          <Footer />
        </Box>
      </main>
    </div>
  );
};

export default Landing;
