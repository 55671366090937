import { Box, Typography } from '@mui/material';
import ReusableOverview from './ReusableOverview';
import SEOBadge from './SEOBadge';
import { useSeoInput } from '../../hooks/useSeoInput';
import ReusableSeo from './ReusableSeo';
import { useNavigate, useParams } from 'react-router-dom';
import { regexUrl } from '../../lib/util';

const Deprecated = () => {
  return (
    <Box>
      <ReusableOverview
        message={`To ensure your website's compliance with modern HTML standards, prioritize the removal or replacement of deprecated HTML tags. Instead, utilize current HTML elements and attributes to maintain a clean and optimized codebase. `}
        passed={true}
      />
      <Typography
        variant="h4"
        sx={{
          my: 2
        }}>
        How to pass
      </Typography>
      <Typography variant="subtitle1">
        To pass this test, review your webpage&apos;s HTML code and identify any deprecated tags.
        Replace them with appropriate, non-deprecated alternatives or remove them altogether. Ensure
        all HTML elements used are consistent with current standards to avoid compatibility issues
        and maintain a streamlined website structure.
      </Typography>
      <SEOBadge type="success">
        <Box component={'pre'}>
          {`<-- some valid HTML tags -->
          
<p>This is a paragraph</p>
<h1>This is a heading</h1>
<ul>
  <li>Item 1</li>
  <li>Item 2</li>
</ul>
<a href="https://example.com">Link</a>

`}
        </Box>
      </SEOBadge>
      <SEOBadge type="error">
        <Box component={'pre'}>
          {`<-- invalid or deprecated HTML tags -->

<font color="red">Text here</font>
<center>Centered text</center>
<s>Strikethrough text</s>
<u>Underlined text</u>

`}
        </Box>
      </SEOBadge>
    </Box>
  );
};

export default Deprecated;
export const DeprecatedSearch = () => {
  const { url, setUrl, error, setError } = useSeoInput();
  const navigate = useNavigate();
  const params = useParams();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);

    if (url && regexUrl(url)) return navigate(`/seo-result/${params.id}`);

    setError(true);
  };
  return (
    <ReusableSeo
      title="Deprecated HTML tags Test"
      keywords="Deprecated HTML tags Test"
      url={url}
      setUrl={setUrl}
      error={error}
      setError={setError}
      handleSubmit={handleSubmit}
    />
  );
};
