import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useStore } from '../../store';
import { useMutation } from '@tanstack/react-query';
import { Box, Button } from '@mui/material';
import { profileSchema } from '../../lib/auth.schema';
import PersonalProfile from './PersonalProfile';
import AddressProfile from './Address';
// import ShippingProfile from './Shipping';
import { updateUser } from '../../api';
import { useNavigate } from 'react-router-dom';
import { notifySuccess, notifyError } from '../../lib/notification';
const AccountInfo = () => {
  const { user, setUser }: any = useStore((state: any) => state);
  const navigate = useNavigate();
  const userEditMutation = useMutation(updateUser, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data) => {
      notifySuccess('Account Updated was Successfully');
      localStorage.setItem('user', JSON.stringify(data));
      setUser(data);
      formik.resetForm();
      navigate('/dashboard');
    },
    onError: (error) => {
      notifyError('Account Update Failed');
      console.log(error);
    }
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      zip: '',
      bizname: '',
      photo: ''
    },
    validationSchema: profileSchema,
    onSubmit: (values) => {
      const firstName = values.fullname.split(' ')[0];
      const lengthName = values.fullname.split(' ').length - 1;
      const lastName = values.fullname.split(' ')[lengthName];
      const submitValues = {
        firstName,
        lastName,
        fullname: values.fullname,
        email: values.email,
        address: values.address,
        city: values.city,
        photo: values.photo,
        phone: values.phone,
        zip: values.zip,
        bizname: values.bizname,
        id: user?.id,
        app: 'Reach AI'
      };
      userEditMutation.mutate(submitValues);
      return;
    }
  });

  useEffect(() => {
    if (user) {
      formik.setValues(user);
    }
  }, [user]);

  return (
    <Box>
      <Box>
        <PersonalProfile formik={formik} />
      </Box>
      <Box className="my">
        <AddressProfile formik={formik} />
      </Box>
      {/* <Box className="my">
        <ShippingProfile formik={formik} />
      </Box> */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}>
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}>
          Save details
        </Button>
      </Box>
    </Box>
  );
};

export default AccountInfo;
