import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { AppBar, Avatar, Badge, Box, Button, IconButton, Toolbar, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Bell as BellIcon } from '../icons/bell';
import { UserCircle as UserCircleIcon } from '../icons/user-circle';
import { Users as UsersIcon } from '../icons/users';
import { AccountPopover } from './AccountPopover';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from '../store';
import SettingsIcon from '@mui/icons-material/Settings';

const DashboardNavbarRoot = styled(AppBar)(({ theme }: any) => ({
  // backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = (props: any) => {
  const { user }: any = useStore((state): any => state);
  const { onSidebarOpen, isDashboard, ...other } = props;
  const navigate = useNavigate();
  const settingsRef = useRef(null);
  const [openAccountPopover, setOpenAccountPopover] = useState(false);
  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280
          },
          width: {
            lg: 'calc(100% - 280px)'
          },
          backgroundColor: isDashboard ? '#464646' : '#fff'
        }}
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}>
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none'
              }
            }}>
            <MenuIcon fontSize="small" />
          </IconButton>
          <Tooltip title="Search">
            <IconButton sx={{ ml: 1 }}>
              <SearchIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Settings">
            <IconButton
              sx={{ ml: 1 }}
              // onClick={() => {
              //   navigate('/');
              // }}
            >
              <SettingsIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Customers">
            <IconButton
              sx={{ ml: 1 }}
              onClick={() => {
                navigate('/customers');
              }}>
              <UsersIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notifications">
            <IconButton sx={{ ml: 1 }}>
              <Badge badgeContent={4} color="secondary" variant="dot">
                <BellIcon fontSize="medium" />
              </Badge>
            </IconButton>
          </Tooltip>
          {!user ? (
            <>
              <Link to={'/login'} style={{ textDecoration: 'none' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    px: 3,
                    mx: 3
                  }}>
                  Login
                </Button>
              </Link>
            </>
          ) : (
            <>
              <Avatar
                onClick={() => setOpenAccountPopover(true)}
                ref={settingsRef}
                sx={{
                  cursor: 'pointer',
                  height: 40,
                  width: 40,
                  ml: 1
                }}
                src="/static/images/avatars/avatar_1.png">
                <UserCircleIcon fontSize="small" />
              </Avatar>
            </>
          )}
        </Toolbar>
      </DashboardNavbarRoot>
      <AccountPopover
        anchorEl={settingsRef.current}
        open={openAccountPopover}
        onClose={() => setOpenAccountPopover(false)}
      />
    </>
  );
};
