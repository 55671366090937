import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { DashboardLayout } from '../components/DashboardLayout';

const integrationData = [
  {
    name: 'Paystack',
    subtitle: ' Paystack Integration',
    description:
      'Use Paystack Integration to manage all payment transactions in your Reach AI account.',
    tag: 'Free',
    image: '/assets/images/paystack.png'
  },
  {
    name: 'Interswitch',
    subtitle: ' Interswitch Integration ',
    description: 'Manage your payment workflow with Interswitch on your Reach AI account',
    tag: 'Free',
    image: '/assets/images/interswitch.jpg'
  },
  {
    name: 'Flutterwave',
    subtitle: ' Flutterwave Integration',
    description:
      'Use Flutterwave Integration to manage all payment transactions in your Reach AI account.',
    tag: 'Free',
    image: '/assets/images/flutterwave.png'
  },
  {
    name: 'Stripe',
    subtitle: ' Stripe Integration',
    description:
      'Use Stripe Integration to manage all payment transactions in your Reach AI account.',
    tag: 'Free',
    image: '/assets/images/stripe.png'
  },
  {
    name: 'Zoho',
    subtitle: ' Zoho Integration',
    description:
      'Use Zoho Integration to manage all your customer relationship management  in your Reach AI account.',
    tag: 'Free',
    image: '/assets/svg/zoho.svg'
  },
  {
    name: 'CashApp',
    subtitle: ' CashApp Integration',
    description:
      'Use CashApp Integration to manage all payment transactions in your Reach AI account.',
    tag: 'Free',
    image: '/assets/images/cashapp.png'
  },
  {
    name: 'Slack',
    subtitle: ' Slack Integration',
    description: 'Use Slack Integration to manage all communications  in your Reach AI account.',
    tag: 'Free',
    image: '/assets/images/slack.png'
  }
];

const Integrations = () => {
  return (
    <DashboardLayout>
      <Container maxWidth={false}>
        <Box className="resources__header" sx={{ pt: 4, pb: 2 }}>
          <Typography className="m" variant="h4">
            Integrations
          </Typography>
          <Button
            sx={{ color: 'black !important', border: '1px solid black !important' }}
            variant="outlined"
            disabled>
            Featured Apps
          </Button>
        </Box>
        <Typography variant="h5" sx={{ pb: 2 }}>
          Manage all your business with Reach AI and it&apos;s powerful integrations
        </Typography>
        <Divider />
        <Box
          sx={{
            my: 3
          }}>
          <Grid container spacing={3}>
            {integrationData.map((integration) => (
              <Grid item xs={12} sm={6} md={4} key={integration.name}>
                <Card
                  sx={{
                    height: '100%'
                  }}>
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                      <img
                        src={integration.image}
                        alt="Company Logo"
                        style={{
                          width: '50px',
                          height: '50px',
                          aspectRatio: 'auto',
                          marginRight: '7px'
                        }}
                      />
                      <Box>
                        <Typography variant="h6" fontWeight={'bold'}>
                          {integration.name}
                        </Typography>
                        <Typography variant="body1" fontWeight={'normal'}>
                          {integration.subtitle}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider />
                    <Typography
                      variant="body1"
                      fontWeight={'semibold'}
                      fontSize={14}
                      color={'#00cc66'}
                      my={2}>
                      {integration.tag}
                    </Typography>
                    <Typography variant="body1">{integration.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default Integrations;
