import React from 'react';
import { DashboardLayout } from '../../components/DashboardLayout';
import { Box, Container, Typography } from '@mui/material';
import Cal from '@calcom/embed-react';

const Expenses = () => {
  return (
    <DashboardLayout>
      <Container maxWidth={false}>
        <Box sx={{ py: 4 }}>
          <h1>Expenses</h1>
          <Typography variant="h4">This feature is launching soon</Typography>
          <Cal
            calOrigin="http://localhost:3000/essl/15min?duration=15"
            config={{
              name: 'John Doe',
              email: 'you@youremail.net',
              notes: 'meeting agenda',
              guests: 's@me.com',
              theme: 'dark'
            }}
            calLink="essl"
          />
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default Expenses;
