import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Collapse, IconButton, Paper, TableContainer, Typography } from '@mui/material';
import * as React from 'react';

interface SEOTestProps {
  title: string;
  status: boolean;
  notes: string;
  task: string;
}

const SEOTest: React.FC<SEOTestProps> = ({ title, status, notes }) => {
  const [openFix, setOpenFix] = React.useState<boolean>(false);

  return (
    <Box sx={{ py: 2, px: 3 }} bgcolor={status ? '#93faa3' : '#F7D1CD'}>
      <Box sx={{ display: 'flex', alignItems: 'start', mb: 4 }}>
        {status ? (
          <CheckIcon sx={{ color: 'green', mr: 2 }} />
        ) : (
          <ClearIcon sx={{ color: 'red', mr: 2 }} />
        )}
        <Box sx={{ flex: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: 'semibold', fontSize: 'lg' }}>
            {title}
          </Typography>
          <Typography variant="body2">{status ? 'Passed' : 'Failed'}</Typography>
        </Box>
        {!status && notes && (
          <IconButton
            size="small"
            sx={{
              fontSize: 'sm'
            }}
            onClick={() => setOpenFix((prev) => !prev)}>
            How to fix {openFix ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        )}
      </Box>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        {notes}
      </Typography>
      <Collapse in={openFix}>
        {notes && (
          <Paper sx={{ bg: 'gray.100', p: 2, rounded: 'default' }}>
            <Typography variant="body2">{notes}</Typography>
          </Paper>
        )}
      </Collapse>
    </Box>
  );
};

export default function SEOPage({ seoData }: { seoData: Record<string, any> }) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        maxWidth: '4xl',
        mx: 'auto',
        my: 3,
        bg: 'white',
        shadow: 'default',
        rounded: 'lg'
      }}>
      <Box
        sx={{
          maxWidth: '4xl',
          mx: 'auto',
          bg: 'white',
          shadow: 'default',
          rounded: 'lg'
        }}>
        {Object.entries(seoData).map(([task, value]) => (
          <SEOTest
            key={task}
            task={task}
            title={value.title}
            status={value.status}
            notes={value.notes}
          />
        ))}
      </Box>
    </TableContainer>
  );
}
