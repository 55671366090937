import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  MenuList,
  Popover,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import '../../assets/styles/resources.css';
import { Search as SearchIcon } from '../../icons/search';
import { Upload as UploadIcon } from '../../icons/upload';
import Select from 'react-select';

import { useRef, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../lib/queryKeys';
import { getMyCustomers } from '../../api';
import { useStore } from '../../store';
import { colourStyles } from '../../assets/styles/reactSelectStyles';
import { createOption } from '../../lib/util';
import { Option } from '../../types/types';
const ProductToolbar = (props: any) => {
  const { setOpen, value, setValue } = props;
  const { user, setProductId }: any = useStore((state: any) => state);

  const [options, setOptions] = useState<any>([]);

  const settingsRef = useRef(null);
  const [openImportPopover, setOpenImportPopover] = useState(false);

  const myCustomer: any = useQuery([queryKeys.mycustomers], () => getMyCustomers(user.id));

  useEffect(() => {
    if (myCustomer.data) {
      const options = myCustomer.data.map((customer: any) =>
        createOption(customer.fullname, customer)
      );
      setOptions(options);
      if (options) {
        setValue(options[0]);
      }
    }
  }, [myCustomer.data]);

  const handleSelectCustomer = (newValue: any) => {
    setValue(newValue as Option);
  };

  return (
    <Box>
      <Box className="resources__header">
        <Typography className="m" variant="h4">
          Products
        </Typography>
        <Box className="m">
          <Button
            ref={settingsRef}
            onClick={() => setOpenImportPopover(true)}
            startIcon={<UploadIcon fontSize="small" />}
            sx={{ mr: 1 }}>
            Import
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setProductId(null);
              setOpen(true);
            }}>
            Add Product
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '8px',
            py: 5,
            px: 2
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              px: 2
            }}>
            <Box sx={{ flex: 1, mr: { xs: 0, sm: 2 }, width: '100%' }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action" fontSize="small">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search products"
                variant="outlined"
              />
            </Box>
            <Box sx={{ flex: 1, width: '100%' }}>
              <Select
                isClearable
                isDisabled={myCustomer.isLoading}
                isLoading={myCustomer.isLoading}
                onChange={(newValue) => handleSelectCustomer(newValue)}
                options={options}
                value={value}
                styles={colourStyles}
                className="customer-select"
                classNamePrefix="select"
                placeholder="Type a Customer Name to Select"
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Popover
        anchorEl={settingsRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        onClose={() => setOpenImportPopover(false)}
        open={openImportPopover}
        PaperProps={{
          sx: { width: '300px' }
        }}>
        <MenuList>
          <MenuItem>CSV</MenuItem>
          <MenuItem>Microsoft Excel</MenuItem>
        </MenuList>
      </Popover>
    </Box>
  );
};

export default ProductToolbar;
