import React from 'react';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import usFlag from '../../assets/img/us-flag.svg';
import ngFlag from '../../assets/img/ng-flag.svg';
import { tHolidayData } from '../../types/types';

interface Props {
  holiday: tHolidayData;
}
const Holiday: React.FC<Props> = ({ holiday }) => {
  return (
    <List dense>
      <ListItem>
        <ListItemAvatar>
          <Avatar src={holiday.countryCode === 'US' ? usFlag : ngFlag} />
        </ListItemAvatar>
        <ListItemText primary={holiday.localName} secondary={holiday.date} />
      </ListItem>
    </List>
  );
};

export default Holiday;
