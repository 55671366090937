import React, { useState } from 'react';
import { Button, Collapse, List, Box, ListItem } from '@mui/material';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { NavItem } from './NavItem';
import { useStore } from '../store';

interface NavItemCollapseProps {
  item: {
    to: string;
    icon?: any;
    title: string;
    subItems: {
      to: string;
      title: string;
      icon?: any;
    }[];
  };
}
const NavItemCollapse = ({ item }: NavItemCollapseProps) => {
  const { user }: any = useStore((state: any) => state);
  const currentPath = window.location.href.split('/')[3];
  const [open, setOpen] = useState(false);

  const adminOnly = user?.roles?.includes('ADMIN');

  React.useEffect(() => {
    if (currentPath) {
      item.subItems.forEach((subItem) => {
        if (subItem.to.split('/')[1] === currentPath) {
          setOpen(true);
        }
      });
    }
  }, []);
  return (
    <>
      <ListItem
        disableGutters
        sx={{
          display: 'flex',
          mb: 0.5,
          py: 0,
          px: 2
        }}>
        <Button
          startIcon={item.icon && item.icon}
          onClick={() => setOpen(!open)}
          sx={{
            borderRadius: 1,

            justifyContent: 'flex-start',
            px: 3,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',

            '&:hover': {
              backgroundColor: '#e0bf6299'
            }
          }}>
          <Box sx={{ flexGrow: 1 }}>{item.title}</Box>
          {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </Button>
      </ListItem>
      <Collapse in={open} timeout="auto">
        <List sx={{ marginLeft: 3 }}>
          {item.subItems.map((it: any) =>
            it.admin ? (
              adminOnly && <NavItem key={it.title} icon={it.icon} to={it.to} title={it.title} />
            ) : (
              <NavItem key={it.title} icon={it.icon} to={it.to} title={it.title} />
            )
          )}
        </List>
      </Collapse>
    </>
  );
};

export default NavItemCollapse;
