/* eslint-disable @typescript-eslint/no-unused-vars */
import { StylesConfig } from 'react-select';

export const colourStyles: StylesConfig<any> = {
  control: (styles) => ({
    ...styles,
    padding: '0.5rem 0.5rem',
    fontSize: '1rem',
    backgroundColor: 'white',
    margin: '0.5rem 0',
    ':hover': {
      border: '1px solid #000'
    }
  }),

  multiValue: (styles, { data }) => {
    const color = data.color;
    return {
      ...styles,
      backgroundColor: color
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    backgroundColor: '#33333322'
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    backgroundColor: '#33333322',
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'black',
      cursor: 'pointer'
    }
  })
};
