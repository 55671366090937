import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DashboardLayout } from '../../components/DashboardLayout';
import GenericToolbar from '../../components/admin/GenericToolbar';
import TimesheetComponent from '../../components/admin/TimesheetComponent';

const Timesheet = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4,
          px: 0
        }}>
        <Container>
          <GenericToolbar
            addBtn={true}
            addFn={() => {
              navigate('/timesheet/add', { replace: true });
            }}
            title="My Timesheets"
            search={true}
          />
          <TimesheetComponent />
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Timesheet;
