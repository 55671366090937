import { Box, Container, Grid, Modal, Pagination } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { SetStateAction, useEffect, useState } from 'react';
import { getCustomerProducts } from '../../api';
import { DashboardLayout } from '../../components/DashboardLayout';
import ProductModal from '../../components/product';
import ProductCard from '../../components/product/ProductCard';
import ProductToolbar from '../../components/product/ProductToolbar';
import { queryKeys } from '../../lib/queryKeys';
import NoValue from '../../components/NoValue';
import { Option } from '../../types/types';

const Products = () => {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<Option | null>();
  const [products, setProducts] = useState([]);

  const pageSize = 9;
  const startIndex = (page - 1) * pageSize;
  const endIndex = page * pageSize;
  const currentProducts = products.slice(startIndex, endIndex);

  const handleChange = (event: any, value: SetStateAction<number>) => {
    setPage(value);
  };

  const customerProducts = useQuery(
    [queryKeys.customerProducts, { customerId: value?.id }],
    getCustomerProducts,
    {
      enabled: !!value
    }
  );

  useEffect(() => {
    if (customerProducts.data) {
      setProducts(customerProducts.data);
    }
  }, [customerProducts]);

  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4
        }}>
        <Container maxWidth={false}>
          <ProductToolbar setOpen={setOpen} value={value} setValue={setValue} />
          {!customerProducts.isLoading && (
            <Box sx={{ pt: 3 }}>
              {currentProducts.length > 0 ? (
                <Grid container spacing={3}>
                  {currentProducts.map((product: any) => (
                    <Grid item key={product.id} lg={4} md={6} xs={12}>
                      <ProductCard product={product} setOpen={setOpen} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <NoValue text={`${value?.label || 'This User'} has no product available`} />
              )}
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 3
            }}>
            <Pagination
              count={Math.ceil(products.length / pageSize)}
              page={page}
              color="primary"
              size="small"
              onChange={handleChange}
            />
          </Box>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
              overflow: 'scroll'
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className="modal-style">
              <ProductModal setOpen={setOpen} />
            </Box>
          </Modal>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Products;
