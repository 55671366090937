import React from 'react';
import Layout from '../../components/auth/Layout';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { InputField, PasswordField } from '../../components/InputFields';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { resetPassword } from '../../api';
import { notifyError, notifySuccess } from '../../lib/notification';
import { useNavigate, useSearchParams } from 'react-router-dom';

const fields = {
  header: 'Reset Password',
  subHeader: 'Enter the code sent to your email and set a new password below',
  link: 'login',
  linkText: 'Login'
};

const resetSchema = Yup.object({
  code: Yup.string().required('Code is Required'),
  password: Yup.string().required('Password is Required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { mutate, isLoading } = useMutation(resetPassword, {
    onSuccess: () => {
      notifySuccess('Password Reset Successful');
      navigate('/login');
    },
    onError: (err) => {
      console.log('error message', err);
      notifyError('Something went Wrong, Try again');
    }
  });
  const formik = useFormik({
    initialValues: {
      code: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: resetSchema,
    onSubmit: (values) => {
      const payload = {
        email: searchParams.get('email') || '',
        token: values.code,
        password: values.password
      };

      if (payload.email === '') {
        notifyError('Email is missing, try again!!!');
        navigate('/forgot-password');
        return;
      }
      mutate(payload);
    }
  });
  return (
    <Layout fields={fields}>
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={formik.handleSubmit}>
        <InputField
          type="code"
          label="Code"
          name="code"
          id="code"
          error={Boolean(formik.touched.code && formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.code}
        />

        <PasswordField
          type="password"
          label="Password"
          name="password"
          id="password"
          error={Boolean(formik.touched.password && formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.password}
        />

        <PasswordField
          type="confirmPassword"
          label="Confirm Password"
          name="confirmPassword"
          id="confirmPassword"
          error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.confirmPassword}
        />

        <Box component={Link} to={'/login'} sx={styles.forgot}>
          Back to Login Page
        </Box>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!formik.dirty || isLoading}
          sx={{
            marginTop: 2
          }}>
          Reset Password
        </Button>
      </Box>
    </Layout>
  );
};

export default ResetPassword;
const styles = {
  forgot: {
    textDecoration: 'underline',
    color: '#000',
    justifySelf: 'flex-end',
    alignSelf: 'flex-end',
    fontSize: 18
  }
};
