import React from 'react';
import { DashboardLayout } from '../../components/DashboardLayout';
import { Box, Container } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ExpiringProducts from '../../components/customer/ExpiringProducts';

const ProductExp = () => {
  const { state } = useLocation();
  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4
        }}>
        <Container maxWidth={false}>
          <Box sx={{ mt: 3 }}>
            <ExpiringProducts data={state} />
          </Box>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default ProductExp;
