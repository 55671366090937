import * as Yup from 'yup';

export const createInvoiceSchema = Yup.object().shape({
  customer: Yup.string().required('Select a Customer'),
  invoiceNumber: Yup.string().label('Invoice Number').required(),
  frequency: Yup.string().label('Frequency').required(),
  invoiceDate: Yup.string().label('Invoice Date'),
  paymentDate: Yup.string().label('Payment Due Date'),
  paymentType: Yup.string().label('Payment Type').required(),
  comment: Yup.string().label('Comment'),
  products: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      description: Yup.string(),
      price: Yup.number().typeError('Price must be a number'),
      quantity: Yup.number()
        .typeError('Quantity must be a number')
        .integer('Quantity must be an integer')
        .positive('Quantity must be a positive number')
    })
  )
});
