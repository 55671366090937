import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/img/logo.png';
import { useStore } from '../../store';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  margin-right: 20px;
  font-weight: 600;
  font-size: 16px;

  &:hover {
    color: #555555;
    text-decoration: underline;
  }
`;

const StyledAnchor = styled(Typography)`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  margin-right: 20px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #555555;
    text-decoration: underline;
  }
`;

export const seoTestTypes = [
  {
    label: 'SEO Friendliness',
    value: 'friendliness'
  },
  {
    label: 'Image Test',
    value: 'image'
  },
  {
    label: 'Deprecated HTML Tags',
    value: 'deprecated'
  },
  {
    label: 'Page Objects Analysis',
    value: 'analysis'
  },
  {
    label: 'Social Media Links',
    value: 'social'
  },
  {
    label: 'DOM Optimization',
    value: 'dom'
  },
  {
    label: 'Security Checks (SSL Checks)',
    value: 'security'
  },
  {
    label: 'SPF Records',
    value: 'spfrecords'
  }
];

const Header = () => {
  const navigate = useNavigate();
  const { user }: any = useStore((state): any => state);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={styles.flexGrow}>
      <AppBar position="static" sx={styles.appbar}>
        <Toolbar sx={styles.toolbar}>
          <IconButton component="div" sx={styles.flexGrow} onClick={() => navigate('/')}>
            <Box sx={styles.logo}>
              <img src={logo} alt="reach logo" width={170} height={60} />
            </Box>
          </IconButton>
          {user ? (
            <>
              <StyledAnchor variant="body1" onClick={handleClick}>
                SEO Tools <KeyboardArrowDownIcon sx={{ ml: 1 }} />
              </StyledAnchor>
              <StyledLink to="/dashboard">Dashboard</StyledLink>
              <StyledLink to="/products">Products</StyledLink>
              <StyledLink to="/Customers">Customers</StyledLink>
              <StyledLink to="/website-builder">Website Builder</StyledLink>
            </>
          ) : (
            <>
              <StyledAnchor variant="body1" onClick={handleClick}>
                SEO Tools <KeyboardArrowDownIcon sx={{ ml: 1 }} />
              </StyledAnchor>
              <StyledLink to="/website-builder">Website Builder</StyledLink>
              <Button
                variant="outlined"
                size="small"
                onClick={() => navigate('/register')}
                sx={styles.button1}>
                Sign Up
              </Button>

              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={() => navigate('/login')}
                sx={styles.button2}>
                Login
              </Button>
            </>
          )}
        </Toolbar>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <MenuItem value={'seo'} onClick={() => navigate('/seo')}>
            General SEO
          </MenuItem>
          {seoTestTypes.map((seo) => (
            <MenuItem
              key={seo.value}
              value={seo.value}
              onClick={() => navigate(`/seo/${seo.value}`)}>
              {seo.label}
            </MenuItem>
          ))}
        </Menu>
      </AppBar>
    </Box>
  );
};

export default Header;

const styles = {
  flexGrow: { flexGrow: 1 },
  appbar: {
    bgcolor: 'white',
    color: 'black'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1290px',
    mx: 'auto',
    width: { xs: '90%', lg: '100%' },
    px: 2
  },
  logo: { minWidth: '100%', margin: '5px auto', display: 'flex' },
  button1: { mr: 2, px: 3, display: { xs: 'none', sm: 'block' } },
  button2: { px: 4, py: '7px', display: { xs: 'none', sm: 'block' } }
};
