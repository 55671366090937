import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface InputFieldProps {
  type?: string;
  placeholder?: string;
  id?: string;
  label?: string;
  required?: boolean;
  inputProps?: any;
  error?: boolean;
  onBlur?: any;
  onChange?: any;
  value?: any;
  helperText?: any;
  multiline?: boolean;
  rows?: number | undefined;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  children?: React.ReactNode;
  margin?: boolean;
  [key: string]: any;
}

export const SelectField = (props: InputFieldProps): JSX.Element => {
  const {
    label,
    id,
    error,
    helperText,
    onChange,
    value,
    children,
    onBlur,
    type,
    variant = 'outlined',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    margin = false,
    ...rest
  } = props;
  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        fullWidth
        id={id}
        helperText={helperText}
        label={label}
        onChange={onChange}
        select
        SelectProps={{ native: true }}
        onBlur={onBlur}
        value={value}
        type={type}
        error={error}
        variant={variant}
        {...rest}>
        {children}
      </TextField>
    </Box>
  );
};

export const InputField = (props: InputFieldProps): JSX.Element => {
  const {
    type,
    placeholder,
    error,
    label,
    id,
    helperText,
    onBlur,
    onChange,
    value,
    multiline,
    rows,
    margin = false,
    ...rest
  } = props;

  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        type={type}
        error={error}
        id={id}
        rows={rows && rows}
        label={label}
        multiline={multiline || false}
        helperText={helperText}
        fullWidth
        sx={{ backgroundColor: '#fff' }}
        margin={margin ? 'none' : 'normal'}
        variant="outlined"
        placeholder={placeholder}
        {...rest}
      />
    </Box>
  );
};

export const PasswordField = (props: InputFieldProps): JSX.Element => {
  const { placeholder, error, label, id, helperText, onBlur, onChange, value } = props;

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        type={showPassword ? 'text' : 'password'}
        error={error}
        id={id}
        label={label}
        helperText={helperText}
        fullWidth
        sx={{ backgroundColor: '#fff' }}
        margin="normal"
        variant="outlined"
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};
