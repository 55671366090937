import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { customerSlice } from './customer';
import { productSlice } from './product';
import { userSlice } from './user';

export const useStore = create(
  persist(
    (...preState) => ({
      ...preState,
      ...userSlice(...preState),
      ...customerSlice(...preState),
      ...productSlice(...preState)
    }),
    {
      name: 'reachai-store',
      serialize: (state) => JSON.stringify(state),
      deserialize: (str) => JSON.parse(str)
    }
  )
);
