import { Add, ChevronLeft, ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Divider,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
// import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
// import { addTimeEntry } from '../../api';
// import { notifyError, notifySuccess } from '../../lib/notification';
import { generateRandomHexColor } from '../../lib/util';

// const timeEntrySchema = Yup.object().shape({
//   timeSheetId: Yup.number().integer().required(),
//   date: Yup.string().required(),
//   task: Yup.string().required(),
//   budgetId: Yup.number().integer().required(),
//   hour: Yup.number().integer().required()
// });

const TimesheetComponent = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editMode, setEditMode] = useState<any>({});
  const [currentWeek, setCurrentWeek] = useState(1);
  const [currentDate] = useState(new Date());
  const [rows, setRows] = useState([
    {
      project: 'Vacation',
      monday: 8,
      tuesday: 0,
      wednesday: 8,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0
    }
  ]);

  // const timeEntryMutation = useMutation(addTimeEntry, {
  //   onSuccess: () => {
  //     notifySuccess('Added a new time entry successfully');
  //     return;
  //   },
  //   onError: () => {
  //     notifyError('Failed to add time entry, try again!!!');
  //     return;
  //   }
  // });

  const addRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      {
        project: '',
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0
      }
    ]);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleEditMode = (index: number) => {
    setEditMode((prevEditMode: any) => ({
      ...prevEditMode,
      [index]: !prevEditMode[index]
    }));
  };

  const handleChange = (event: any, index: number, key: string) => {
    const { value } = event.target;
    setRows((prevRows) =>
      prevRows.map((row, i) =>
        i === index
          ? {
              ...row,
              [key]: value
            }
          : row
      )
    );
  };

  const generateDynamicHeaders = () => {
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    // const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const startDate = new Date(firstDayOfMonth);
    startDate.setDate(
      firstDayOfMonth.getDate() + (currentWeek - 1) * 7 - firstDayOfMonth.getDay() + 1
    );
    const headers = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      headers.push({
        date: date.toISOString(),
        formattedDate: date.toLocaleDateString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric'
        })
      });
    }
    return headers;
  };

  const handleWeekChange = (week: number) => {
    setCurrentWeek(week);
    setAnchorEl(null);
  };

  return (
    <Container>
      <Box sx={styles.container}>
        <Typography variant="h4" component="h1">
          Timesheet
        </Typography>
        <Box>
          <Button endIcon={<KeyboardArrowDown />} onClick={handleClick} sx={{ mr: 1 }}>
            Week {currentWeek}
          </Button>
          <Menu id="teammates" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            {[1, 2, 3, 4].map((week) => (
              <MenuItem key={week} onClick={() => handleWeekChange(week)} value={`week${week}`}>
                Week {week}
              </MenuItem>
            ))}
          </Menu>

          <Button
            variant="contained"
            size="small"
            sx={{ mr: 1 }}
            disabled={currentWeek < 2}
            onClick={() => {
              setCurrentWeek((prev) => prev - 1);
            }}>
            <ChevronLeft />
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={currentWeek === 4}
            onClick={() => {
              setCurrentWeek((prev) => prev + 1);
            }}>
            <ChevronRight />
          </Button>
        </Box>
      </Box>

      <Paper
        sx={{
          p: 2,
          borderRadius: '10px'
        }}>
        <Box>
          <Box>
            <Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Projects</TableCell>
                      {generateDynamicHeaders().map((header, index) => (
                        <TableCell key={index}>{header.formattedDate}</TableCell>
                      ))}
                      <TableCell>Total</TableCell>
                      <TableCell>Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Box sx={styles.dotContainer}>
                            <Button
                              sx={{
                                ...styles.dot,
                                bgcolor: generateRandomHexColor()
                              }}
                            />
                            {editMode[index] ? (
                              <TextField
                                value={row.project}
                                onChange={(e) => handleChange(e, index, 'project')}
                                variant="outlined"
                                size="small"
                              />
                            ) : (
                              row.project
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {editMode[index] ? (
                            <TextField
                              value={row.monday}
                              onChange={(e) => handleChange(e, index, 'monday')}
                              variant="outlined"
                              size="small"
                            />
                          ) : (
                            <span>{row.monday} &#58; 00</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {editMode[index] ? (
                            <TextField
                              value={row.tuesday}
                              onChange={(e) => handleChange(e, index, 'tuesday')}
                              variant="outlined"
                              size="small"
                            />
                          ) : (
                            <span>{row.tuesday} &#58; 00</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {editMode[index] ? (
                            <TextField
                              value={row.wednesday}
                              onChange={(e) => handleChange(e, index, 'wednesday')}
                              variant="outlined"
                              size="small"
                            />
                          ) : (
                            <span>{row.wednesday} &#58; 00</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {editMode[index] ? (
                            <TextField
                              value={row.thursday}
                              onChange={(e) => handleChange(e, index, 'thursday')}
                              variant="outlined"
                              size="small"
                            />
                          ) : (
                            <span>{row.thursday} &#58; 00</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {editMode[index] ? (
                            <TextField
                              value={row.friday}
                              onChange={(e) => handleChange(e, index, 'friday')}
                              variant="outlined"
                              size="small"
                            />
                          ) : (
                            <span>{row.friday} &#58; 00</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {editMode[index] ? (
                            <TextField
                              value={row.saturday}
                              onChange={(e) => handleChange(e, index, 'saturday')}
                              variant="outlined"
                              size="small"
                            />
                          ) : (
                            <span>{row.saturday} &#58; 00</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {editMode[index] ? (
                            <TextField
                              value={row.sunday}
                              onChange={(e) => handleChange(e, index, 'sunday')}
                              variant="outlined"
                              size="small"
                            />
                          ) : (
                            <span>{row.sunday} &#58; 00</span>
                          )}
                        </TableCell>
                        <TableCell>
                          {row.monday +
                            row.tuesday +
                            row.wednesday +
                            row.thursday +
                            row.friday +
                            row.saturday +
                            row.sunday}{' '}
                          <span>&#58; 00</span>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => toggleEditMode(index)}>
                            {editMode[index] ? 'Save' : 'Edit'}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Button variant="contained" onClick={addRow} startIcon={<Add />}>
          Add Row
        </Button>
      </Paper>
    </Container>
  );
};

export default TimesheetComponent;
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    py: 2
  },
  dot: {
    height: '10px',
    width: '10px',
    minWidth: '10px',
    mr: 1,
    p: 0,
    borderRadius: '50%'
  },
  dotContainer: { display: 'flex', alignItems: 'center' }
};
