import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DashboardLayout } from '../../components/DashboardLayout';

import {
  Box,
  Card,
  CardHeader,
  Container,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getReceipts } from '../../api';
import '../../assets/styles/resources.css';
import ErrorTable from '../../components/ErrorTable';
import LoadingTable from '../../components/LoadingTable';
import ReceiptModal from '../../components/receipt';
import Receipt from '../../components/receipt/Receipt';
import ReceiptToolbar from '../../components/receipt/ReceiptToolbar';
import { queryKeys } from '../../lib/queryKeys';
import { Option } from '../../types/types';

const Receipts = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<Option | null>();
  const { data, isLoading, error } = useQuery([queryKeys.receipts], getReceipts);

  // const { data, isLoading, error } = useQuery(
  //   [queryKeys.customerReceipts, { customerId: value?.id }],
  //   getCustomerReceipts,
  //   {
  //     enabled: !!value
  //   }
  // );

  return (
    <DashboardLayout>
      <Container maxWidth={false}>
        <Box sx={{ py: 4 }}>
          <ReceiptToolbar setOpen={setOpen} value={value} setValue={setValue} />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 2
          }}>
          <Box sx={{ mt: 3 }}>
            <Card>
              <CardHeader title="All Receipts" />
              <PerfectScrollbar>
                <Box sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Receipt Id</TableCell>
                        <TableCell>Invoice Id</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell sortDirection="desc">Date</TableCell>
                        <TableCell>Currency</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading ? (
                        <LoadingTable />
                      ) : error ? (
                        <ErrorTable />
                      ) : (
                        data.map((receipt: any) => <Receipt key={receipt.id} receipt={receipt} />)
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Box>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            overflow: 'scroll'
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box className="modal-style">
            <ReceiptModal setOpen={setOpen} selectedInvoice={null} />
          </Box>
        </Modal>
      </Container>
    </DashboardLayout>
  );
};

export default Receipts;
