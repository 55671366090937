import { Box, Button, Card, CardContent, CardHeader, Container, Divider } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';
import { addApp } from '../../api';
import { notifyError, notifySuccess } from '../../lib/notification';
import { Option, tAddApp } from '../../types/types';
import { DashboardLayout } from '../DashboardLayout';
import { InputField } from '../InputFields';
import GenericToolbar from './GenericToolbar';
import { useState } from 'react';

const appSchema = Yup.object().shape({
  appname: Yup.string().required('App name is required'),
  manager: Yup.string().required('Manager name is required'),
  website: Yup.string().required('Website name is required').url('Website must be a valid URL'),
  appCode: Yup.string()
    .required('App code is required')
    .matches(/^[0-9]+$/, 'App code must be a number'),
  logo: Yup.string().required('Logo is required')
});

const AddApps = () => {
  const location = useLocation();
  const { custId, usr } = location.state;
  const navigate = useNavigate();
  const initialValues: tAddApp = {
    appCode: 0,
    appname: '',
    logo: '',
    manager: '',
    website: '',
    status: 'DRAFT'
  };
  const [status, setStatus] = useState<Option | null>();
  const handleStatus = (status: any) => {
    formik.setValues({
      ...formik.values,
      status: status.value
    });
    setStatus(status as Option);
  };
  const formik = useFormik<tAddApp>({
    initialValues,
    validationSchema: appSchema,
    onSubmit: (values) => {
      const toSave = { ...values, custId, usr };
      mutation.mutate(toSave);
    }
  });

  const mutation = useMutation(addApp, {
    onSuccess: () => {
      formik.resetForm();
      notifySuccess('App Added Successfully');
      navigate('/apps');
    },
    onError: (error) => {
      notifyError('App creation Failed');
      console.error(error);
    }
  });
  return (
    <DashboardLayout>
      <Box sx={{ flexGrow: 1, py: 4 }}>
        <Container>
          <GenericToolbar title="Add an App Form" />
          <Card
            sx={{
              my: 3
            }}>
            <CardHeader title="Add an App" subheader="All fields are required" />
            <Divider />

            <PerfectScrollbar>
              <CardContent sx={{ gap: 2 }}>
                <InputField
                  type="text"
                  label="App Name"
                  name="appname"
                  id="appname"
                  error={Boolean(formik.touched.appname && formik.errors.appname)}
                  helperText={formik.touched.appname && formik.errors.appname}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.appname}
                />
                <InputField
                  type="text"
                  label="App Code"
                  name="appCode"
                  id="appCode"
                  error={Boolean(formik.touched.appCode && formik.errors.appCode)}
                  helperText={formik.touched.appCode && formik.errors.appCode}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.appCode}
                />
                <InputField
                  type="text"
                  label="Website URL"
                  name="website"
                  id="website"
                  error={Boolean(formik.touched.website && formik.errors.website)}
                  helperText={formik.touched.website && formik.errors.website}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.website}
                />
                <InputField
                  type="text"
                  label="Manager Name"
                  name="manager"
                  id="manager"
                  error={Boolean(formik.touched.manager && formik.errors.manager)}
                  helperText={formik.touched.manager && formik.errors.manager}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.manager}
                />
                <InputField
                  type="text"
                  label="Logo URL"
                  name="logo"
                  id="logo"
                  error={Boolean(formik.touched.logo && formik.errors.logo)}
                  helperText={formik.touched.logo && formik.errors.logo}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.logo}
                />
                <Select
                  isClearable
                  onChange={(newValue) => {
                    handleStatus(newValue);
                  }}
                  options={[
                    { value: 'DRAFT', label: 'DRAFT' },
                    { value: 'ACTIVE', label: 'ACTIVE' },
                    { value: 'PAID', label: 'PAID' }
                  ]}
                  value={status}
                  className="status-select"
                  classNamePrefix="select"
                  placeholder="Select App Status"
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    my: 2
                  }}
                  fullWidth
                  size="large"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}>
                  Submit App
                </Button>
              </CardContent>
            </PerfectScrollbar>
          </Card>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default AddApps;
