import React from 'react';
import { Card, CardContent, Divider, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../lib/queryKeys';
import { getInvoice } from '../../api';
const ReceiptDetail = (props: any) => {
  const { values } = props;
  const navigate = useNavigate();

  const singleInvoice = useQuery(
    [
      queryKeys.invoice,
      {
        id: values.inv
      }
    ],
    getInvoice,
    {
      enabled: !!values.inv
    }
  );

  const itemsTotal = singleInvoice?.data?.items?.map((item: any) => item.price * item.quantity);
  const invoiceTotal = itemsTotal?.reduce((acc: number, item: number) => acc + item, 0);

  return (
    <Card className="flex flex-center flex-column">
      <CardContent className="card-content">
        <Box className="flex flex-center flex-column">
          <Box className="flex flex-center">
            <img
              src="/assets/images/sample.png"
              alt="Company's Logo"
              style={{
                width: '100px',
                height: 'auto'
              }}
            />
          </Box>
          <Box className="py text-center">
            <Typography color="textPrimary" variant="h5">
              Payment Receipt
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={{
                fontSize: '16px',
                fontWeight: 'bold'
              }}>
              Invoice No: {values?.inv}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box className="py">
          <Box
            sx={{
              px: 1
            }}>
            <Box component="pre" className="preText">
              {values?.message}
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="py">
          <Typography color="textPrimary" variant="h6" className="text-center">
            Payment Amount: ${invoiceTotal}
          </Typography>
        </Box>
        <Divider />
        <Box className="py">
          <Typography
            color="textPrimary"
            variant="subtitle1"
            className="text-center"
            sx={{
              fontSize: '16px',
              fontWeight: 'bold'
            }}>
            Payment Status: Paid
          </Typography>
          <Box className="flex flex-center py">
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                values.inv ? navigate(`/invoices/${values?.inv}`) : navigate('/invoices');
              }}>
              View Invoice
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ReceiptDetail;
