import { Box, Typography } from '@mui/material';
import React from 'react';
import { DashboardLayout } from '../../components/DashboardLayout';

const ExpenseAdd = () => {
  return (
    <DashboardLayout>
      <Box component={'div'} sx={{ margin: 10 }}>
        <Typography variant="h1">Add Expense</Typography>
      </Box>
    </DashboardLayout>
  );
};

export default ExpenseAdd;
