import { Box } from '@mui/material';
import { DashboardLayout } from '../../components/DashboardLayout';
import Detail from '../../components/builder/Detail';
import BuilderLayout from '../../components/website/BuilderLayout';
import { useStore } from '../../store';

function WebsiteGenerator() {
  const { user }: any = useStore((state): any => state);

  if (!user) {
    return (
      <BuilderLayout title="I can generate a website for you!">
        <Box
          sx={{
            width: '100%'
          }}>
          <Detail />
        </Box>
      </BuilderLayout>
    );
  }

  return (
    <DashboardLayout>
      <Detail />
    </DashboardLayout>
  );
}

export default WebsiteGenerator;
