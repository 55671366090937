import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { seoTest } from '../../api/data';
import Footer from '../../components/seo/Footer';
import Header from '../../components/seo/Header';
import Overview from '../../components/seo/Overview';
import SEOPage from '../../components/seo/SeoTest';
import TaskList from '../../components/seo/TaskList';

const SeoResult = () => {
  return (
    <Box sx={styles.main}>
      <Header />
      <Box sx={styles.body}>
        <Container>
          <Overview seoData={seoTest} />
          <TaskList />
          {/* <p>Visualization</p>
          <p>Visualization items</p> 
          <div>
            <p>Result Details</p>
          </div> */}
          <div>
            <p>SERP</p>
            <Grid item xs={12} sm={6} md={4} key={'title'}>
              <Card>
                <CardContent>
                  <Typography
                    variant="body2"
                    color="green"
                    component="a"
                    rel="noopener noreferrer"
                    href={'url'}
                    sx={{ textDecoration: 'none' }}>
                    {'https://reachai.online'}
                  </Typography>
                  <Typography variant="h5" component="h3" color={'blue'}>
                    {'Reach AI'}
                  </Typography>
                  <Typography variant="body2" color={'gray'}>
                    {'description'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* <SeoList seoData={seoMock} /> */}
            <SEOPage seoData={seoTest} />
          </div>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default SeoResult;
const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  body: {
    flexGrow: 1,
    maxWidth: '1290px',
    mx: 'auto',
    width: { xs: '90%', lg: '100%' },
    px: 2,
    mt: 10
  }
};
