import {
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import AppAppBar from '../landing/AppAppBar';
import FAQ from '../landing/FAQ';
import Footer from '../landing/Footer';
import Map from '../Map';
import * as yup from 'yup';
import { styled } from '@mui/system';
import { notifySuccess } from '../../lib/notification';
import SocialTags from '../SocialTags';
import { useEffect } from 'react';

const validationSchema = yup.object({
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  phone: yup.string().required('Phone is required'),
  message: yup.string().required('Message is required')
});

const FormGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export default function Contact() {
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: ''
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      notifySuccess('Thank you for your message. We will get back to you soon.');
    }
  });

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          ReachAI - Business Automation. Build Leads, Get digital powered websites and marketing
          your businesses - Contact
        </title>
        <meta
          name="description"
          content="Welcome to ReachAI. Build Leads, Get digital powered websites and marketing your businesses "></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston, SEO,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, Digital powered websites, marketing my businesses, reachai, Digital powered websites, marketing my businesses, reachai, ESSL"
        />
        <SocialTags />
      </Helmet>
      <main>
        <CssBaseline />
        <AppAppBar />
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 }
          }}>
          <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
            <Typography
              variant="h1"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: 'clamp(3.5rem, 10vw, 4rem)'
              }}>
              Contact Us
            </Typography>
            <Typography
              textAlign="center"
              sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}>
              Reach AI is here to help you. If you have any questions, please reach out to us. We
              will get back to you as soon as possible.
            </Typography>
          </Stack>
          <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3} my={5}>
                <FormGrid item xs={12} md={6}>
                  <FormLabel htmlFor="firstname" required>
                    First name
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    id="firstname"
                    name="firstname"
                    type="text"
                    placeholder="John"
                    autoComplete="first name"
                    value={formik.values.firstname}
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.firstname && formik.errors.firstname)}
                    helperText={formik.touched.firstname && formik.errors.firstname}
                    sx={{
                      maxHeight: 40
                    }}
                  />
                </FormGrid>
                <FormGrid item xs={12} md={6}>
                  <FormLabel htmlFor="lastname" required>
                    Last name
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    id="lastname"
                    name="lastname"
                    type="text"
                    placeholder="Doe"
                    autoComplete="last name"
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.lastname && formik.errors.lastname)}
                    helperText={formik.touched.lastname && formik.errors.lastname}
                    sx={{
                      maxHeight: 40
                    }}
                  />
                </FormGrid>
                <FormGrid item xs={12} md={6}>
                  <FormLabel htmlFor="email" required>
                    Email
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    id="email"
                    name="email"
                    type="text"
                    placeholder="test@gmail.com"
                    autoComplete="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    sx={{
                      maxHeight: 40
                    }}
                  />
                </FormGrid>
                <FormGrid item xs={12} md={6}>
                  <FormLabel htmlFor="phone" required>
                    Phone
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="08000000000"
                    autoComplete="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.phone && formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                    sx={{
                      maxHeight: 40
                    }}
                  />
                </FormGrid>
                <FormGrid item xs={12}>
                  <FormLabel htmlFor="message" required>
                    Message
                  </FormLabel>
                  <TextField
                    variant="outlined"
                    id="message"
                    name="message"
                    multiline
                    rows={4}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.message && formik.errors.message)}
                    helperText={formik.touched.message && formik.errors.message}
                    placeholder="Enter your message"
                    autoComplete="message"
                  />
                </FormGrid>
                <FormGrid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary"
                    sx={{ alignSelf: 'center' }}>
                    Submit
                  </Button>
                </FormGrid>
              </Grid>
            </form>
          </Stack>
        </Container>
        <Map />
        <Box>
          <Divider />
          <FAQ />
          <Footer />
        </Box>
      </main>
    </div>
  );
}
