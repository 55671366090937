import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

import { PieCenterLabel, ProgressWithLabel, size } from './Overview';
import SEOBadge from './SEOBadge';

const ReusableOverview = ({ message, passed }: { message?: string; passed: boolean }) => {
  const seoScore = passed ? 100 : 0;
  const data = [
    { value: passed ? 0 : 100, label: 'Failed' },
    { value: 0, label: 'Warning' },
    { value: passed ? 100 : 0, label: 'Passed' }
  ];

  return (
    <Card
      variant="outlined"
      sx={{
        my: 2
      }}>
      <CardHeader
        sx={{
          m: 0,
          p: 2
        }}
        title="Overview"
        subheader="Your generated SEO report summary"
      />
      <CardContent
        sx={{
          m: 0,
          px: 2,
          py: 0
        }}>
        <Box mb={2}>
          {message && (
            <SEOBadge type="info">
              <Typography variant="subtitle1">{message}</Typography>
            </SEOBadge>
          )}
          <Typography variant="subtitle1">
            This test received an SEO score of {seoScore} out of 100, which is{' '}
            {Number(seoScore) > 70 ? 'above' : 'below'} the average score of 70.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Box>
            <PieChart
              colors={['#FF0000', '#FFFF00', '#00FF00']}
              series={[{ data, innerRadius: 80 }]}
              {...size}>
              <PieCenterLabel>{seoScore} %</PieCenterLabel>
            </PieChart>
          </Box>
          <Box sx={{ flex: 1, width: '100%' }}>
            <ProgressWithLabel label="Failed SEO" value={Number(data[0].value)} />
            <ProgressWithLabel label="Warning SEO" value={Number(data[1].value)} />
            <ProgressWithLabel label="Passed SEO" value={Number(data[2].value)} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ReusableOverview;
