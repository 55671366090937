import { Box, Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { sendToken, userExist } from '../../api';
import { InputField } from '../../components/InputFields';
import Layout from '../../components/auth/Layout';
import { notifyError, notifySuccess } from '../../lib/notification';

const fields = {
  header: 'Forgot Password',
  subHeader: 'Enter your email and we will send you a link to reset your password',
  link: 'login',
  linkText: 'Login'
};

const forgotSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Required')
});

const Forgot = () => {
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(sendToken, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data) => {
      notifySuccess('Code sent to Email Successfully');
      navigate(`/reset-password?email=${formik.values.email}`);
    },
    onError: (err) => {
      console.log('error message', err);
      notifyError('Something went Wrong, Try again');
    }
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: forgotSchema,
    onSubmit: async (values) => {
      const doesUserExist = await userExist(values.email);

      if (!doesUserExist) {
        formik.setFieldError('email', 'User does not exist');
        return;
      }

      mutate(values.email);
    }
  });
  return (
    <Layout fields={fields}>
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={formik.handleSubmit}>
        <InputField
          type="email"
          label="Email"
          name="email"
          id="email"
          error={Boolean(formik.touched.email && formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
        />

        <Box component={Link} to={'/login'} sx={styles.forgot}>
          Back to Login Page
        </Box>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!formik.dirty || isLoading}
          sx={{
            marginTop: 2
          }}>
          Send Email
        </Button>
      </Box>
    </Layout>
  );
};

export default Forgot;
const styles = {
  forgot: {
    textDecoration: 'underline',
    color: '#000',
    justifySelf: 'flex-end',
    alignSelf: 'flex-end',
    fontSize: 18
  }
};
