import { Box, Typography } from '@mui/material';
import ReusableOverview from './ReusableOverview';
import SEOBadge from './SEOBadge';
import ReusableSeo from './ReusableSeo';
import { useSeoInput } from '../../hooks/useSeoInput';
import { useNavigate, useParams } from 'react-router-dom';
import { regexUrl } from '../../lib/util';

const ImageTest = () => {
  return (
    <Box>
      <ReusableOverview
        message={`To improve your website's SEO, update the image formats in your report to newer options like JPEG 2000, JPEG XR, or WebP. `}
        passed={true}
      />
      <Typography
        variant="h4"
        sx={{
          my: 2
        }}>
        How to pass
      </Typography>
      <Typography variant="subtitle1">
        To pass this test, change all the images in this report to a newer format like JPEG 2000,
        JPEG XR, or WebP. But keep in mind, not all devices and browsers support these new formats
        like WebP yet.
      </Typography>
      <SEOBadge type="success">
        <Box component={'pre'}>
          {`<-- correct format -->

<img src="images.webp" alt="alt text" />
<img src="images.jpeg" alt="alt text" />
<picture>
  <source type="image/webp" srcset="image.webp">
  <img src="image.jpg" alt="">
</picture>
`}
        </Box>
      </SEOBadge>
      <SEOBadge type="error">
        <Box component={'pre'}>
          {`<-- wrong format -->

<img src="images.jpg" alt="" />
<img src="images.png" alt="" />
`}
        </Box>
      </SEOBadge>
    </Box>
  );
};

export default ImageTest;
export const ImageTestSearch = () => {
  const { url, setUrl, error, setError } = useSeoInput();
  const navigate = useNavigate();
  const params = useParams();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);

    if (url && regexUrl(url)) return navigate(`/seo-result/${params.id}`);

    setError(true);
  };
  return (
    <ReusableSeo
      title="Image SEO Test"
      keywords="Image SEO Test"
      url={url}
      setUrl={setUrl}
      error={error}
      setError={setError}
      handleSubmit={handleSubmit}
    />
  );
};
