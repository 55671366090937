import { Web } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';

import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/auth/Layout';
import { useStore } from '../../store';
import SocialTags from '../../components/SocialTags';

const fields = {
  header: 'Welcome to ReachAI',
  subHeader: 'Lets build your business together',
  link: 'register',
  linkText: 'Register'
};
const features = [
  {
    title: 'Get a new website with corporate email accounts',
    icon: <Web />,
    link: '/website-builder'
  },
  {
    title: 'Improve SEO, Build Leads, Optimize your Website',
    icon: <DescriptionIcon />,
    link: '/seo'
  }
  // {
  //   title: 'Manage your receipts',
  //   icon: <Receipt />,
  //   link: '/register'
  // },
  // {
  //   title: 'Manage your products',
  //   icon: <Inventory />,
  //   link: '/register'
  // },
  // {
  //   title: 'Manage your sales',
  //   icon: <ShoppingCartIcon />,
  //   link: '/register'
  // },
  // {
  //   title: 'Manage your customers',
  //   icon: <People />,
  //   link: '/register'
  // }
];
const Home = () => {
  const navigate = useNavigate();
  const { user }: any = useStore((state): any => state);

  React.useEffect(() => {
    user && navigate('/dashboard');
  }, []);
  return (
    <div>
      <Helmet>
        <title>
          ReachAI - Business Automation. Build Leads, Get digital powered websites and marketing
          your businesses - Home
        </title>
        <meta
          name="description"
          content="Welcome to ReachAI. Build Leads, Get digital powered websites and marketing your businesses "></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston, SEO,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, Digital powered websites, marketing my businesses, reachai, Digital powered websites, marketing my businesses, reachai, ESSL"
        />
        <SocialTags />
      </Helmet>
      <Layout fields={fields} landing={true}>
        <Box sx={styles.container}>
          <Box sx={styles.box}>
            <Grid container spacing={3} alignContent={'center'} justifyContent={'center'}>
              {features.map((feature, index) => (
                <Grid key={index} item xs={12} sm={6}>
                  <ListItem disablePadding sx={styles.item}>
                    <ListItemButton onClick={() => navigate(feature.link)}>
                      <ListItemIcon sx={styles.icon}>{feature.icon}</ListItemIcon>
                      <ListItemText primary={feature.title} />
                    </ListItemButton>
                  </ListItem>
                </Grid>
              ))}
            </Grid>
            <Box sx={styles.buttonGroup}>
              <Grid container spacing={3} alignContent={'center'} justifyContent={'center'}>
                <Grid item xs={12} sm={6}>
                  <Button sx={styles.buttonOne} onClick={() => navigate('/register')}>
                    Get Started
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    sx={styles.buttonTwo}
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/login')}>
                    Already a member? Login
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Layout>
    </div>
  );
};

export default Home;
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5
  },
  box: {
    width: {
      xs: '90%',
      md: '70%'
    }
  },
  item: {
    backgroundColor: '#000',
    color: '#fff',
    padding: 0.5,
    borderRadius: 2
  },
  icon: {
    color: '#fff'
  },
  buttonGroup: {
    flex: 1,
    justifyContent: 'space-between',
    marginTop: 5
  },
  buttonOne: {
    border: '2px solid #000',
    mr: 5,
    my: {
      xs: 2,
      md: 0
    },
    width: '100%'
  },
  buttonTwo: {
    width: '100%',
    padding: '10px'
  }
};
