import { Box, Container } from '@mui/material';
import { DashboardLayout } from '../../components/DashboardLayout';
import Webeditor from '../../components/website/Webeditor';

const WebsiteEdit = () => {
  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4
        }}>
        <Container maxWidth={false}>
          <Box>
            <Webeditor />
          </Box>
        </Container>
      </Box>
    </DashboardLayout>
  );
};
export default WebsiteEdit;
