import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import {
  Box,
  Card,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, useNavigate } from 'react-router-dom';
import { getMyCustomers } from '../../api';
import { queryKeys } from '../../lib/queryKeys';
import { useStore } from '../../store';
import ErrorTable from '../ErrorTable';
import LoadingTable from '../LoadingTable';

const CustomerResults = () => {
  const { user }: any = useStore((state: any) => state);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const { data, isLoading, error } = useQuery([queryKeys.customers], () => getMyCustomers(user.id));

  const handleLimitChange = (event: any) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event: any, newPage: any) => {
    setPage(newPage);
  };

  if (isLoading) {
    return <LoadingTable />;
  }

  if (error) {
    return <ErrorTable />;
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 920 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>View More</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data?.map((customer: any) => {
                  return <CustomerRow customer={customer} key={customer.id} />;
                })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={data && data.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

export default CustomerResults;
const CustomerRow = ({ customer }: any) => {
  const { setCustomerEditDetails }: any = useStore((state: any) => state);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false
  });

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (customer: any, path: string) => {
    const isEditPath = path.includes('edit');
    handleClose();
    if (isEditPath) setCustomerEditDetails(customer);
    navigate(`${path}${customer.id}`);
  };

  const handleViewDetails = (event: any, customerId: number) => {
    event.stopPropagation();
    if (lgUp) return;
    navigate(`/customers/details/${customerId}`);
  };
  return (
    <TableRow hover key={customer.id} onClick={(event) => handleViewDetails(event, customer.id)}>
      <TableCell>{customer.id}</TableCell>
      <TableCell>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}>
          <Typography color="textPrimary" variant="body1">
            {customer.fullname}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>{customer.email}</TableCell>
      <TableCell>{customer.phone}</TableCell>
      <TableCell>
        {customer?.address}, {customer?.city}
      </TableCell>
      <TableCell className="flex flex-space-between">
        <Tooltip title="view more">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <ArrowDropDownCircleIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 1px 1px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <MenuItem onClick={() => handleMenuItemClick(customer, '/customers/details/')}>
            View
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick(customer, '/customers/edit/')}>
            Edit
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => handleMenuItemClick(customer, '/invoice/add/')}>
            Send Invoice
          </MenuItem>
          <MenuItem>Send Statement</MenuItem>
          <MenuItem component={Link} to={'/product/add'} state={customer?.id}>
            Add Product
          </MenuItem>
          <Divider />
          <MenuItem sx={{ color: 'red' }}>Delete</MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};
