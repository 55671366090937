import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getReceipts } from '../../api';
import { queryKeys } from '../../lib/queryKeys';
import { DashboardLayout } from '../DashboardLayout';
import ErrorTable from '../ErrorTable';
import LoadingTable from '../LoadingTable';
import ReceiptDetail from './ReceiptDetail';

const ViewReceipt = () => {
  const { id } = useParams<{ id: string }>();
  const [receipt, setReceipt] = React.useState<any>({});
  const { data, isLoading, error } = useQuery([queryKeys.receipts], getReceipts);
  React.useEffect(() => {
    if (data) {
      const dataToSet = data.find((receipt: any) => receipt.id.toString() === id?.toString());
      setReceipt(dataToSet);
    }
  }, [data]);
  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <Box
            sx={{
              my: 2
            }}>
            <Typography variant="h5">Receipt Preview</Typography>
            <Typography variant="subtitle1">Below is the details of the receipt</Typography>
          </Box>
          <Divider />
          <Box sx={{ my: 2 }}>
            <Typography variant="h6">Recipients:</Typography>
            {receipt.recipients?.map((recipient: any) => (
              <Typography key={recipient} variant="subtitle1" sx={{ ml: 2, fontWeight: 'bold' }}>
                {recipient}
              </Typography>
            ))}
          </Box>
          {isLoading ? (
            <LoadingTable />
          ) : error ? (
            <ErrorTable />
          ) : (
            <ReceiptDetail values={receipt} />
          )}
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default ViewReceipt;
