import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { tApp } from '../../types/types';

interface Props {
  data: tApp[];
}

const AppsTable: React.FC<Props> = ({ data }: Props) => {
  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 920 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Website</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((appData: tApp) => {
                return (
                  <TableRow hover key={appData.id}>
                    <TableCell>{appData.id}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}>
                        <Typography color="textPrimary" variant="body1">
                          {new Date(appData.updatedOn).toLocaleDateString('en-US', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric'
                          })}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{appData.appname}</TableCell>
                    <TableCell>{appData.website}</TableCell>
                    <TableCell>{appData?.status}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default AppsTable;
