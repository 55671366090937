import { Grid } from '@mui/material';
import website from '../../assets/img/builder.svg';
import { TotalBalance } from '../../components/dashboard/BalanceCard';
import { DashboardLayout } from '../../components/DashboardLayout';
import InvoicesCard from '../../components/dashboard/InvoicesCard';
import ProgressReport from '../../components/dashboard/ProgressReport';
import { Link } from 'react-router-dom';

const Dashabord = () => {
  return (
    <DashboardLayout isDashboard={true}>
      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} md={7}>
          <ProgressReport />
        </Grid>
        <Grid item xs={12} md={5}>
          {/* only NG users <BalanceCard />
          <FundAccount /> */}

          <Link to={'/website-builder'}>
            <img src={website} alt="need_a_site" />
          </Link>

          <TotalBalance />
          <InvoicesCard />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Dashabord;
