import { Box, Skeleton } from '@mui/material';
import React from 'react';

const LoadingTable = () => {
  return (
    <Box>
      <Skeleton height={100} />
      <Skeleton height={100} animation="wave" />
      <Skeleton height={100} animation={false} />
    </Box>
  );
};

export default LoadingTable;
