import React from 'react';
import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { InputField } from '../InputFields';
const AddressProfile = ({ formik }: any) => {
  return (
    <Card>
      <CardHeader subheader="Edit your address info" title="Address Information" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputField
              type="text"
              label="Address"
              name="address"
              id="address"
              error={Boolean(formik.touched.address && formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.address}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputField
              type="text"
              label="City"
              name="city"
              id="city"
              error={Boolean(formik.touched.city && formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.city}
            />
          </Grid>
          {/* <Grid item md={6} xs={12}>
            <InputField
              type="text"
              label="State"
              name="state"
              id="state"
              error={Boolean(formik.touched.state && formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.state}
            />
          </Grid> */}

          <Grid item md={6} xs={12}>
            <InputField
              type="text"
              label="Zip Code"
              name="zip"
              id="zip"
              error={Boolean(formik.touched.zip && formik.errors.zip)}
              helperText={formik.touched.zip && formik.errors.zip}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.zip}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default AddressProfile;
