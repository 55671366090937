import React, { useEffect, useState } from 'react';
import { Typography, Paper, Select, MenuItem, SelectChangeEvent, Alert } from '@mui/material';
import EditableText from './EditableText';
import EditableList from './EditableList';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../lib/queryKeys';
import { editWebsite, getWebEdit } from '../../api';
import { useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../lib/notification';

const Webeditor = () => {
  const { pid } = useParams();

  const { data, isLoading, isError } = useQuery([queryKeys.apps, { pid }], getWebEdit, {
    refetchOnWindowFocus: false,
    retry: 2
  });

  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [har, setHarmony] = useState<any>(null);

  useEffect(() => {
    if (data && selectedLanguage) {
      const selectedContent = data[0]?.content.find((item: any) => item.label === selectedLanguage);
      selectedContent && setHarmony(selectedContent);
    }
  }, [data, selectedLanguage]);

  const mutation = useMutation(editWebsite, {
    onSuccess: () => {
      notifySuccess('Page edited Successfully');
    },
    onError: () => {
      notifyError('Budget creation Failed');
    }
  });
  const incrementVersion = (version: number) => version + 1;

  const handleChange = (keyPath: string, value: any) => {
    const keys = keyPath.split('.');
    const updatedData = { ...har.body };
    let currentObj = updatedData;
    for (let i = 0; i < keys.length - 1; i++) {
      currentObj = currentObj[keys[i]];
    }
    currentObj[keys[keys.length - 1]] = value;
    const newHarmony = { body: updatedData, label: har.label };
    const dataCopy = [...data];
    const replaceIdx = dataCopy[0].content.findIndex((x: any) => x.label === selectedLanguage);
    dataCopy[0].content[replaceIdx] = newHarmony;
    dataCopy[0].version = incrementVersion(dataCopy[0].version);
    mutation.mutate(dataCopy[0]);
  };

  const changeSelect = (event: SelectChangeEvent<string>) => {
    setSelectedLanguage(event.target.value as string);
  };
  const secondaryRender = (d: any, keyPath: string): React.ReactNode[] => {
    return Object.entries(d).map(([key, value]) => {
      if (typeof value === 'string') {
        return (
          <>
            <EditableText
              key={value}
              keyPath={`${keyPath}.${key}`}
              label={key}
              onChange={handleChange}
              value={value}
            />
          </>
        );
      } else if (Array.isArray(value)) {
        return (
          <EditableList
            key={value[0]}
            keyPath={keyPath}
            label={key}
            items={value}
            onChange={handleChange}
          />
        );
      } else {
        return secondaryRender(value, `${keyPath}.${key}`);
      }
    });
  };

  const switcher = () => {
    if (!isLoading && !isError && data) {
      const langs = data[0]?.content.map((dx: any) => dx.label);
      return (
        <Select
          value={selectedLanguage}
          onChange={changeSelect}
          displayEmpty
          inputProps={{ 'aria-label': 'Select Language' }}>
          <MenuItem value="" disabled>
            Select Content to edit.
          </MenuItem>
          {langs.map((dy: string) => (
            <MenuItem key={dy} value={dy}>
              {dy}
            </MenuItem>
          ))}
        </Select>
      );
    }
    return null;
  };

  return (
    <div>
      {isError && (
        <Alert variant="filled" severity="error">
          Backend error occured.
        </Alert>
      )}
      {switcher()}
      {!isLoading && har && (
        <div>
          <p>{har.label}</p>
          {Object.entries(har.body).map(([key, value], index) => (
            <Paper elevation={3} sx={{ padding: 3, mb: 3 }} key={`${key}-${index}`}>
              <Typography variant="h4">{key}</Typography>
              {secondaryRender(value, `${key}`)}
            </Paper>
          ))}
        </div>
      )}
    </div>
  );
};

export default Webeditor;
