import * as Yup from 'yup';

export const customerPersonalSchema = Yup.object({
  fullname: Yup.string().label('Business Name').required(),
  note: Yup.string().label('Note'),
  currency: Yup.string().label('Currency'),
  phone: Yup.string().label('Phone Number').required(),
  email: Yup.string()
    .label('Email Address')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(,[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/i,
      'separate multiple emails with comma'
    )
    .required('Email is required')
});

export const customerAddressSchema = Yup.object({
  address: Yup.string().label('Address').required(),
  city: Yup.string().label('City').required(),
  state: Yup.string().label('State').required(),
  lgazip: Yup.string().label('LGA/ZIP').required(),
  shippingAddress: Yup.string(),
  shippingCity: Yup.string(),
  shippingLgaZip: Yup.string(),
  shippingState: Yup.string()
});
export const productSchema = Yup.object().shape({
  name: Yup.string().label('Name').required(),
  description: Yup.string().label('Description').required(),
  start: Yup.string().label('Start').required(),
  expiry: Yup.string().label('Expiry').required(),
  cost: Yup.string().label('Cost').required(),
  frequency: Yup.string().label('Frequency').required()
});
