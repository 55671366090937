import { Business, DocumentScanner, People, ReceiptLong } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { expiringProducts, getReceipts, getUserInvoices } from '../../api';
import { queryKeys } from '../../lib/queryKeys';
import { useStore } from '../../store';
import { holiday } from '../../store/mockData';
import Holiday from './Holiday';
import { tHoliday } from '../../types/types';

const ProgressReport = () => {
  const { user }: any = useStore((state: any) => state);
  const { data } = useQuery([queryKeys.customers], () => expiringProducts(), {
    enabled: user?.id !== undefined
  });
  const invoices = useQuery([queryKeys.userInvoices, { userId: user?.id }], getUserInvoices, {
    enabled: !!user
  });
  const receipts = useQuery([queryKeys.receipts], getReceipts);
  const navigate = useNavigate();

  const today = new Date();
  const sevenDaysFromToday = new Date();
  sevenDaysFromToday.setDate(today.getDate() + 14);
  const holidays: tHoliday[] = holiday;
  const holz = holidays.map((h) => {
    return h.data.filter((hol) => {
      return new Date(hol.date) >= today && new Date(hol.date) <= sevenDaysFromToday;
    });
  });
  return (
    <Box
      component="div"
      sx={{
        backgroundColor: 'transparent',
        padding: '1rem',
        color: 'white'
      }}>
      <Container maxWidth="lg">
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Box component="div">
            <Typography variant="body2" component="p">
              {format(new Date(), 'MMM dd, yyyy')}
            </Typography>
          </Box>

          {/* shows users calendar of to do items for today
          <IconButton
            sx={{
              backgroundColor: 'white',
              color: 'primary.main',

              '&:hover': {
                backgroundColor: 'white'
              }
            }}>
            <AddIcon />
          </IconButton> */}
        </Box>
        {/* <Box component="div" sx={{ my: 3 }}>
          <Typography variant="h5" component="h5">
            Sustainable Development Goals (SDGs) and Innovations
          </Typography>
          <Box
            component="div"
            sx={{
              display: 'flex',
              my: 2,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Box component="div">
              <Typography variant="body2" component="p">
                Development stage Meeting
              </Typography>
              <Typography variant="body2" component="p">
                {format(new Date('11/27/2022'), 'E dd MMM yyyy')}
              </Typography>
            </Box>
            <Divider orientation="vertical" flexItem />

            <AvatarGroup total={8} spacing="small">
              <Avatar alt="Remy Sharp" src="/assets/images/avatar/1.jpg" sx={{ opacity: 0.9 }} />
              <Avatar alt="Travis Howard" src="/assets/images/avatar/2.jpg" sx={{ opacity: 0.9 }} />
              <Avatar alt="Agnes Walker" src="/assets/images/avatar/4.jpg" sx={{ opacity: 0.9 }} />
              <Avatar
                alt="Trevor Henderson"
                src="/assets/images/avatar/5.jpg"
                sx={{ opacity: 0.9 }}
              />
            </AvatarGroup>
          </Box>
        </Box> */}
        <Box component="div" sx={{ my: 3 }}>
          {/* Cards */}
          <Paper
            elevation={6}
            sx={{
              minHeight: '160px',
              opacity: 0.9,
              padding: 3,
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: 2,
              boxShadow:
                '0px 3px 1px -2px red,0px 2px 2px 0px rgba(100,0,0,0.9),0px 1px 5px 0px rgba(0,0,0,0.12)'
            }}>
            <Box component="div" width={'30%'} mr={2}>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontWeight: 800
                }}>
                Report
              </Typography>
              <Typography variant="body2" component="p" sx={{ my: 1 }}>
                Track your performance
              </Typography>
              {user?.id && (
                <Typography variant="body2" component="p" sx={{ my: 1 }}>
                  Expiring soon ({Number(data?.length) || 0})
                </Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                sx={{
                  my: 3,
                  px: 3
                }}
                onClick={() => navigate('/expiring', { state: data })}>
                View Report
              </Button>
            </Box>
            <Box component="div">
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  fontWeight: 800
                }}>
                Upcoming Holidays
              </Typography>
              <Box display={'flex'} flexWrap={'wrap'}>
                {holz.flat().map((x, idx) => (
                  <Holiday holiday={x} key={idx} />
                ))}
              </Box>
            </Box>
          </Paper>
          <Grid container spacing={3} sx={{ my: 3 }}>
            <Grid item xs={12} md={6}>
              <Paper
                sx={{
                  minHeight: '160px',
                  opacity: 0.9,
                  padding: 3,
                  borderRadius: 2,
                  boxShadow: '0px 3px 1px -2px red'
                }}>
                <Box component="div">
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontWeight: 800
                    }}>
                    Records{' '}
                    {`(${Number(invoices?.data?.length) + Number(receipts?.data?.length) || 0})`}
                  </Typography>
                  <Box component="div">
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ color: 'black' }}>
                          <DocumentScanner />
                        </ListItemIcon>
                        <ListItemText
                          primary="Invoices"
                          secondary={`${Number(invoices?.data?.length) || 0} total invoices`}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <ReceiptLong sx={{ color: 'black' }} />
                        </ListItemIcon>
                        <ListItemText
                          primary="Receipts"
                          secondary={`${Number(receipts?.data?.length) || 0} receipts`}
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper
                sx={{
                  minHeight: '160px',
                  opacity: 0.9,
                  padding: 3,
                  borderRadius: 2,
                  boxShadow: '0px 3px 1px -2px red'
                }}>
                <Box component="div">
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      fontWeight: 800
                    }}>
                    Analysis
                  </Typography>
                  <Box>
                    <List>
                      <ListItem>
                        <ListItemIcon sx={{ color: 'black' }}>
                          <Business />
                        </ListItemIcon>
                        <ListItemText primary="Products" secondary="10" />
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <People sx={{ color: 'black' }} />
                        </ListItemIcon>
                        <ListItemText primary="Customers" secondary="2" />
                      </ListItem>
                    </List>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ProgressReport;
