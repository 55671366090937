import { Box, Typography } from '@mui/material';
import ReusableOverview from './ReusableOverview';
import { useSeoInput } from '../../hooks/useSeoInput';
import ReusableSeo from './ReusableSeo';
import { useNavigate, useParams } from 'react-router-dom';
import { regexUrl } from '../../lib/util';

const SPFRecords = () => {
  return (
    <Box>
      <ReusableOverview
        message={
          'To enhance email deliverability and reduce the likelihood of emails being marked as spam, implement SPF (Sender Policy Framework) records in your DNS settings. SPF records allow email systems to verify the authenticity of mail servers authorized to send emails on behalf of your domain.'
        }
        passed={true}
      />
      <Typography
        variant="h4"
        sx={{
          my: 2
        }}>
        How to pass
      </Typography>
      <Typography variant="subtitle1">
        <ul>
          <li>
            Create SPF record in DNS settings: Add an SPF record to your DNS configuration
            specifying authorized mail servers for your domain.
          </li>
          <li>
            Include all relevant mail servers: Ensure the SPF record includes all mail servers
            authorized to send emails on behalf of your domain, including third-party services.
          </li>
          <li>
            Use proper syntax: Follow SPF syntax guidelines to accurately define sender policies and
            prevent misconfigurations.
          </li>
          <li>
            Test SPF record: Validate the SPF record using SPF testing tools to ensure proper
            functionality.
          </li>
          <li>
            Monitor and update SPF record: Regularly review and update the SPF record to reflect
            changes in authorized mail servers or email sending practices.
          </li>
        </ul>
      </Typography>
    </Box>
  );
};

export default SPFRecords;
export const SPFRecordsSearch = () => {
  const { url, setUrl, error, setError } = useSeoInput();
  const navigate = useNavigate();
  const params = useParams();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);

    if (url && regexUrl(url)) return navigate(`/seo-result/${params.id}`);

    setError(true);
  };
  return (
    <ReusableSeo
      title="SPF Records SEO Test"
      keywords="SPF Records SEO Test"
      url={url}
      setUrl={setUrl}
      error={error}
      setError={setError}
      handleSubmit={handleSubmit}
    />
  );
};
