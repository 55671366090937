// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const customerSlice = (set: any, ...rest: any) => ({
  customerDetails: null,
  setCustomerDetails: (data: any) =>
    set((state: any) => ({
      customerDetails: { ...state.customerDetails, ...data }
    })),
  setCustomerEditDetails: (data: any) =>
    set(() => ({
      customerDetails: data
    })),

  resetCustomerDetails: () =>
    set(() => ({
      customerDetails: null
    }))
});
