import React from 'react';
import { Box } from '@mui/material';

const styles = {
  error: {
    bgcolor: 'rgb(254 226 226)',
    borderLeft: '4px solid rgb(239 68 68)',
    color: 'rgb(185 28 28)',
    p: 2,
    mb: 2,
    mt: 1
  },
  success: {
    bgcolor: 'rgb(226, 254, 226)',
    borderLeft: '4px solid rgb(68, 239, 68)',
    color: 'rgb(28, 112, 28)',
    p: 2,
    mb: 2,
    mt: 1
  },
  warning: {
    bgcolor: 'rgb(255, 255, 205)',
    borderLeft: '4px solid rgb(255, 255, 0)',
    color: 'rgb(100, 100, 0)',
    p: 2,
    mb: 2,
    mt: 1
  },
  info: {
    bgcolor: 'rgb(226, 242, 254)',
    borderLeft: '4px solid rgb(54, 162, 235)',
    color: 'rgb(31, 97, 141)',
    px: 2,
    py: 1,
    mb: 1
  }
};

const SEOBadge = ({
  type,
  children
}: {
  type: 'success' | 'error' | 'warning' | 'info';
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={
        type === 'success'
          ? styles.success
          : type === 'error'
          ? styles.error
          : type === 'warning'
          ? styles.warning
          : styles.info
      }>
      {children}
    </Box>
  );
};

export default SEOBadge;
