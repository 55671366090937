import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { InputField } from '../InputFields';
import { useStore } from '../../store';
const CustomerAddress = (props: any) => {
  const { setCustomerDetails }: any = useStore();
  const { formik, handleComplete, handleBack, activeStep } = props;
  return (
    <Box component="div">
      <Box component="form">
        <Typography variant="h5" sx={{ my: 2 }} gutterBottom>
          Customer Address
        </Typography>
        <InputField
          type="text"
          label="Address"
          name="address"
          id="address"
          error={Boolean(formik.touched.address && formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.address}
        />
        <InputField
          type="text"
          label="City"
          name="city"
          id="city"
          error={Boolean(formik.touched.city && formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.city}
        />
        <InputField
          type="text"
          label="State"
          name="state"
          id="state"
          error={Boolean(formik.touched.state && formik.errors.state)}
          helperText={formik.touched.state && formik.errors.state}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.state}
        />
        <InputField
          type="text"
          label="Zip Code"
          name="lgazip"
          id="lgazip"
          error={Boolean(formik.touched.lgazip && formik.errors.lgazip)}
          helperText={formik.touched.lgazip && formik.errors.lgazip}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.lgazip}
        />
        <Typography variant="h5" sx={{ my: 2 }} gutterBottom>
          Shipping Address
        </Typography>
        <InputField
          type="text"
          label="Shipping Address"
          name="shippingAddress"
          id="shippingAddress"
          error={Boolean(formik.touched.shippingAddress && formik.errors.shippingAddress)}
          helperText={formik.touched.shippingAddress && formik.errors.shippingAddress}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.shippingAddress}
        />
        <InputField
          type="text"
          label="Shipping City"
          name="shippingCity"
          id="shippingCity"
          error={Boolean(formik.touched.shippingCity && formik.errors.shippingCity)}
          helperText={formik.touched.shippingCity && formik.errors.shippingCity}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.shippingCity}
        />
        <InputField
          type="text"
          label="Shipping State"
          name="shippingState"
          id="shippingState"
          error={Boolean(formik.touched.shippingState && formik.errors.shippingState)}
          helperText={formik.touched.shippingState && formik.errors.shippingState}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.shippingState}
        />
        <InputField
          type="text"
          label="Shipping Zip Code"
          name="shippingLgaZip"
          id="shippingLgaZip"
          error={Boolean(formik.touched.shippingLgaZip && formik.errors.shippingLgaZip)}
          helperText={formik.touched.shippingLgaZip && formik.errors.shippingLgaZip}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.shippingLgaZip}
        />
        <Box sx={{ display: 'flex' }}>
          <Button
            variant="outlined"
            color="primary"
            disabled={activeStep < 1}
            fullWidth
            onClick={(e) => {
              e.preventDefault();
              handleBack();
            }}>
            Back
          </Button>
          <Button
            variant="contained"
            fullWidth
            disabled={!formik.isValid}
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              setCustomerDetails(formik.values);
              handleComplete();
            }}
            sx={{
              ml: 5
            }}>
            Next Step
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerAddress;
// "shippingAddress": "26119 Hebron lane",
//     "shippingCity": "Richmond",
//     "shippingState": "TX",
//     "shippingLgaZip": "77406",
// "address": "14110 barone drive, cypress drive",
//     "city": "Cypress",
//     "state": "TX",
//     "lgazip": "77429",
