import { useMemo, useState } from 'react';

import { Box, Button, FormControl, FormControlLabel, Switch } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { registerCustomerUser, registerUser } from '../../api';
import { InputField, PasswordField } from '../../components/InputFields';
import Layout from '../../components/auth/Layout';
import {
  addressRegSchema,
  businessRegSchema,
  passwordRegSchema,
  personalRegSchema
} from '../../lib/auth.schema';
import { notifyError, notifySuccess } from '../../lib/notification';
import { useStore } from '../../store';
const fields = {
  header: 'Welcome to ReachAI',
  subHeader: 'Get started in 4 easy steps!',
  link: 'login',
  linkText: 'Login'
};

const getFormikStepConfig: any = (step: number) => {
  return [
    {
      initialValues: { businessName: '', businessEmail: '' },
      validationSchema: businessRegSchema
    },
    {
      initialValues: { contactName: '', contactEmail: '', phone: '' },
      validationSchema: personalRegSchema
    },
    {
      initialValues: { address: '', city: '', state: '', country: '' },
      validationSchema: addressRegSchema
    },
    {
      initialValues: { password: '', confirmPassword: '' },
      validationSchema: passwordRegSchema
    }
  ][step];
};
const Register = () => {
  const navigate = useNavigate();
  const { user, setUser }: any = useStore((state): any => state);
  const [activeStep, setActiveStep] = useState(0);
  const [customerValues, setCustomerValues] = useState({});

  const totalStep = 4;
  const stepFormikConfig = useMemo(() => getFormikStepConfig(activeStep), [activeStep]);
  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const customerRegisterMutation = useMutation(registerCustomerUser, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data) => {
      notifySuccess('Registration was Successful! Please check your email.');
      setCustomerValues({});
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...user,
          customerId: data.id
        })
      );
      setUser({
        ...user,
        customerId: data.id
      });

      formik.resetForm();
      navigate('/dashboard');
    },
    onError: (error) => {
      notifyError('Registration Failed');
      console.log(error);
    }
  });

  const registerMutation = useMutation(registerUser, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (data) => {
      localStorage.setItem('user', JSON.stringify(data));
      setUser(data);
      if (data?.id)
        customerRegisterMutation.mutate({
          ...customerValues,
          ownerId: data.id,
          email: data?.email || ''
        });
    },
    onError: (error) => {
      notifyError('Registration Failed');
      console.log(error);
    }
  });

  const formik = useFormik({
    initialValues: stepFormikConfig.initialValues,
    validationSchema: stepFormikConfig.validationSchema,
    validateOnChange: false,

    onSubmit: (values) => {
      const firstname: string | null = values?.contactName?.split(' ')[0] || null;
      const lastname: string | null = values?.contactName?.split(' ')[1] || null;

      const submitValues = {
        firstname: firstname,
        lastname: lastname,
        fullname: values.contactName,
        email: values.contactEmail,
        bizname: values.businessName,
        bizemail: values.businessEmail,
        address: values.address,
        city: values.city,
        state: values.state,
        country: values.country,
        lgazip: values.zip,
        phone: values.phone,
        password: values.password
      };
      const registerValues = {
        firstname: firstname,
        fullname: values.contactName,
        email: values.contactEmail,
        password: values.password,
        username: values.contactEmail,
        address: values.address,
        phone: values.phone,
        zip: values.zip,
        bizname: values.businessName,
        appname: 'Reach AI'
      };
      setCustomerValues(submitValues);
      registerMutation.mutate(registerValues);
    }
  });
  const autoFillStepTwo = (e: any) => {
    const { checked } = e.target;
    if (checked) {
      formik.setValues({
        ...formik.values,
        contactEmail: formik.values.businessEmail,
        contactName: formik.values.businessName
      });
    } else {
      formik.setValues({
        ...formik.values,
        contactEmail: '',
        contactName: ''
      });
    }
  };

  const contentProps = {
    formik,
    handleNext,
    handleBack,
    activeStep,
    handleStep,
    registerMutation,
    autoFillStepTwo
  };

  const stepContent = [
    { content: <BusinessComp {...contentProps} /> },
    { content: <PersonalComp {...contentProps} /> },
    { content: <AddressComp {...contentProps} /> },
    { content: <PasswordComp {...contentProps} /> }
  ];

  return (
    <Layout fields={fields} currentStep={activeStep} totalStep={totalStep}>
      <Box component="form" className="flex flex-column" onSubmit={formik.handleSubmit} mb={4}>
        {stepContent[activeStep].content}
      </Box>
      <Box component={Link} to={'/login'} sx={styles.login}>
        Already a member? Login
      </Box>
    </Layout>
  );
};

export default Register;
const styles = {
  login: {
    textDecoration: 'underline',
    color: '#000',
    justifySelf: 'flex-end',
    alignSelf: 'flex-end',
    fontSize: 18
  }
};

const BusinessComp = (props: any) => {
  const { formik, handleNext } = props;
  return (
    <>
      <InputField
        type="text"
        label="Business Name"
        name="businessName"
        id="businessName"
        error={Boolean(formik.touched.businessName && formik.errors.businessName)}
        helperText={formik.touched.businessName && formik.errors.businessName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.businessName}
      />
      <InputField
        type="email"
        label="Business Email"
        name="businessEmail"
        id="businessEmail"
        error={Boolean(formik.touched.businessEmail && formik.errors.businessEmail)}
        helperText={formik.touched.businessEmail && formik.errors.businessEmail}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.businessEmail}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          if (!formik.isValid) {
            if (formik.errors.businessEmail) return notifyError('Enter a valid email address');
            return notifyError('All fields are required');
          }
          handleNext();
        }}
        sx={{
          marginTop: 2
        }}>
        Next Step
      </Button>
    </>
  );
};

const PersonalComp = (props: any) => {
  const { formik, handleNext, handleBack, autoFillStepTwo } = props;
  return (
    <>
      <FormControl component={'fieldset'}>
        <FormControlLabel
          value="sameContact"
          control={<Switch color="primary" onClick={autoFillStepTwo} />}
          label="Personal contact is same as business contact"
          labelPlacement="end"
        />
      </FormControl>
      <InputField
        type="text"
        label="Contact Name"
        name="contactName"
        id="contactName"
        error={Boolean(formik.touched.contactName && formik.errors.contactName)}
        helperText={formik.touched.contactName && formik.errors.contactName}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.contactName || ''}
      />
      <InputField
        type="email"
        label="Contact Email"
        name="contactEmail"
        id="contactEmail"
        error={Boolean(formik.touched.contactEmail && formik.errors.contactEmail)}
        helperText={formik.touched.contactEmail && formik.errors.contactEmail}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.contactEmail || ''}
      />
      <InputField
        type="phone"
        label="Phone"
        name="phone"
        id="phone"
        error={Boolean(formik.touched.phone && formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.phone}
      />
      <Box component="div" className="flex flex-space-between flex-wrap">
        <Button
          variant="outlined"
          color="primary"
          onClick={handleBack}
          sx={{
            marginTop: 2
          }}>
          Previous Step
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!formik.isValid || !formik.dirty}
          onClick={handleNext}
          sx={{
            marginTop: 2,
            marginLeft: '20px',
            flexGrow: 1
          }}>
          Next Step
        </Button>
      </Box>
    </>
  );
};

const AddressComp = (props: any) => {
  const { formik, handleBack, handleNext } = props;
  return (
    <>
      <InputField
        type="text"
        label="Address"
        name="address"
        id="address"
        error={Boolean(formik.touched.address && formik.errors.address)}
        helperText={formik.touched.address && formik.errors.address}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.address}
      />
      <InputField
        type="text"
        label="City"
        name="city"
        id="city"
        error={Boolean(formik.touched.city && formik.errors.city)}
        helperText={formik.touched.city && formik.errors.city}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.city}
      />
      <InputField
        type="text"
        label="State"
        name="state"
        id="state"
        error={Boolean(formik.touched.state && formik.errors.state)}
        helperText={formik.touched.state && formik.errors.state}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.state}
      />
      <InputField
        type="text"
        label="Zip Code"
        name="zip"
        id="zip"
        error={Boolean(formik.touched.zip && formik.errors.zip)}
        helperText={formik.touched.zip && formik.errors.zip}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.zip}
      />
      <InputField
        type="text"
        label="Country"
        name="country"
        id="country"
        error={Boolean(formik.touched.country && formik.errors.country)}
        helperText={formik.touched.country && formik.errors.country}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.country}
      />
      <Box component="div" className="flex flex-space-between flex-wrap">
        <Button
          variant="outlined"
          color="primary"
          onClick={handleBack}
          sx={{
            marginTop: 2
          }}>
          Previous Step
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!formik.isValid}
          onClick={handleNext}
          sx={{
            marginTop: 2,
            marginLeft: '20px',
            flexGrow: 1
          }}>
          Next Step
        </Button>
      </Box>
    </>
  );
};

const PasswordComp = (props: any) => {
  const { formik, handleBack, registerMutation } = props;
  return (
    <>
      <PasswordField
        type="password"
        label="Password"
        name="password"
        id="password"
        error={Boolean(formik.touched.password && formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.password}
      />
      <PasswordField
        type="confirmPassword"
        label="Confirm Password"
        name="confirmPassword"
        id="confirmPassword"
        error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.confirmPassword}
      />
      <Box component="div" className="flex flex-space-between flex-wrap">
        <Button
          variant="outlined"
          color="primary"
          onClick={handleBack}
          sx={{
            marginTop: 2
          }}>
          Previous Step
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={registerMutation.isLoading || !formik.isValid}
          onClick={formik.handleSubmit}
          sx={{
            marginTop: 2,
            marginLeft: '20px',
            flexGrow: 1
          }}>
          Register
        </Button>
      </Box>
    </>
  );
};
