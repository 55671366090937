import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAllInvoices } from '../../api';
import { queryKeys } from '../../lib/queryKeys';
import { Add } from '@mui/icons-material';

const FlexDiv = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1)
  };
});

const NoInvoice = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(5),
    marginBottom: 2
  };
});

const InvoicesCard = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const navigate = useNavigate();

  const { data }: any = useQuery([queryKeys.invoices], getAllInvoices);

  useEffect(() => {
    function sliceInvoice() {
      const slice = data
        .sort((a: any, b: any) => {
          return new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime();
        })
        .slice(0, 4);
      setInvoiceData(slice);
    }

    if (data && data.length > 0) sliceInvoice();
  }, [data]);
  return (
    <Card sx={{ px: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
      <FlexDiv>
        <Box display={'flex'} rowGap={2}>
          <Typography variant="h6" component="h6">
            Recent Invoice
          </Typography>
          <Link to={'/invoice/add'}>
            <Add />
          </Link>
        </Box>

        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            space: '10px'
          }}>
          <Typography variant="subtitle1" component="p">
            All Invoices
          </Typography>
          <IconButton>
            <Link to={'/invoices'}>
              <KeyboardArrowRightOutlinedIcon />
            </Link>
          </IconButton>
        </Box>
      </FlexDiv>
      <Divider />
      {invoiceData.length > 0 ? (
        <>
          <Box component="section">
            <List>
              {invoiceData.map((invoice: any, i) => {
                return (
                  <ListItem
                    divider={i < invoiceData.length - 1}
                    key={invoice.id}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      py: 0,
                      cursor: 'pointer',

                      '&:hover': {
                        backgroundColor: '#e2e1df99'
                      }
                    }}>
                    <ListItemText>
                      <Typography variant="h6" component="h6">
                        {invoice.number}
                      </Typography>
                      <Typography variant="body1" component="p">
                        {new Date(invoice.dueDate).toDateString()}{' '}
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ fontWeight: 700, color: 'success.main' }}>
                          {invoice?.paid ? 'Paid' : ''}
                        </Typography>
                      </Typography>
                    </ListItemText>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      sx={{
                        color: 'success.main',
                        fontWeight: 700
                      }}>
                      {invoice?.frequency || ''}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </>
      ) : (
        <NoInvoice>
          <Typography variant="h6" my={2}>
            No Invoices Created
          </Typography>
          <Button variant="contained" onClick={() => navigate('/invoice/add')}>
            Create New Invoice
          </Button>
        </NoInvoice>
      )}
    </Card>
  );
};

export default InvoicesCard;
