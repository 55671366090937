import { FC } from 'react';

interface Props {
  keyPath: string;
  label: string;
  items: any;
  onChange: (keyPath: string, index: number, newValue: string) => void;
}
const EditableList: FC<Props> = ({ keyPath, label, items, onChange }) => (
  <div>
    <h2>{label}</h2>
    <ul>
      {items.map((item: any, index: number) => (
        <li key={index}>
          <input value={item} onChange={(e) => onChange(keyPath, index, e.target.value)} />
        </li>
      ))}
    </ul>
  </div>
);
export default EditableList;
