import React from 'react';
import { DashboardLayout } from '../components/DashboardLayout';
import { Box, Grid, Typography, Container } from '@mui/material';
import ImageProfile from '../components/profile/ImageProfile';
import AccountInfo from '../components/profile/AccountInfo';
const Profile = () => {
  return (
    <DashboardLayout>
      <Box component="main" className="py2">
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom>
            My profile
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <ImageProfile />
            </Grid>
            <Grid item xs={12} md={8}>
              <AccountInfo />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Profile;
