// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const productSlice = (set: any, ...rest: any) => ({
  productId: null,
  products: [],
  expiring: [],
  setProducts: (data: any) =>
    set((state: any) => ({
      products: { ...state.products, ...data }
    })),
  setProductId: (data: number | null) =>
    set(() => ({
      productId: data
    })),
  setExpiring: (data: any) =>
    set((state: any) => ({
      expiring: { ...state.expiring, ...data }
    }))
});
