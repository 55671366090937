export const queryKeys = {
  customers: 'customers',
  customer: 'customer',
  mycustomers: 'mycustomers',
  receipts: 'receipts',
  invoices: 'invoices',
  product: 'product',
  expiring: 'expiring',
  users: 'users',
  apps: 'apps',
  budgets: 'budgets',
  userIsStaff: 'userIsStaff',
  invoice: 'invoice',
  lastInvoice: 'lastInvoice',
  recentInvoices: 'recentInvoices',
  userInvoices: 'userInvoices',
  response: 'response',
  products: 'products',
  customerProducts: 'customerProducts',
  customerReceipts: 'customerReceipts',
  checkDomain: 'checkDomain',
  webedit: 'webedit'
};
