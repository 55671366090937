import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import React from 'react';
import { SeverityPill } from '../SeverityPills';

const InvoicePreview = ({ data, customerData }: { data: any; customerData: any }) => {
  function convertToCustomFormat(isoDate: string | number | Date) {
    const dateObject = new Date(isoDate);

    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  const customer = customerData.value;
  //   return (
  //     <Box>
  //       <h1>Wahala</h1>
  //     </Box>
  //   );
  const itemsTotal = data?.products?.map((item: any) => item.price * item.quantity);
  const invoiceTotal = itemsTotal?.reduce((acc: number, item: number) => acc + item, 0);
  return (
    <Box component="div" sx={{ bgcolor: 'background.paper', m: 5 }}>
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          bgcolor: 'primary.main',
          color: 'white',
          p: 5
        }}>
        <Box component="div">
          <Typography variant="h4">Invoice</Typography>
        </Box>
        <Typography variant="h4">{data?.invoiceNumber}</Typography>
      </Box>
      <Box
        component="div"
        sx={{ p: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box component="div" sx={{ pb: 3 }}>
          <Box
            component="div"
            sx={{
              pb: 3
            }}>
            <Typography variant="h6">Invoice Date</Typography>
            <Typography variant="body1">
              {convertToCustomFormat(data?.dueDate) || new Date().toDateString()}
            </Typography>
          </Box>
          <Box component="div">
            <Typography variant="h6">Customer</Typography>
            <Typography variant="body1">{customer?.fullname || 'Customer Name'}</Typography>
            <Typography variant="body1">{customer?.address || 'Customer Address'}</Typography>
            <Typography variant="body1">{customer?.email || 'Customer Email'}</Typography>
            <Typography variant="body1">{customer?.phone || 'Customer Phone'}</Typography>
          </Box>
        </Box>
        <Box component="div" sx={{ px: 5 }}>
          <Typography variant="h4">Invoice Total</Typography>
          <Typography variant="h4">${invoiceTotal || 0}</Typography>
        </Box>
      </Box>
      <Box component="div" sx={{ p: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>Quatity</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.products?.map((item: any, index: number) => (
              <TableRow key={index} hover>
                <TableCell>{item?.name || 'item name'}</TableCell>
                <TableCell>{item?.quantity || 'quantity'}</TableCell>
                <TableCell>${item?.price || 'price'}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h5">Invoice Total</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">${invoiceTotal || 0}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h5">Invoice Status</Typography>
              </TableCell>
              <TableCell>
                <SeverityPill color={'primary'}>preview</SeverityPill>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default InvoicePreview;
