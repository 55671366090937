import { alpha, createTheme } from '@mui/material';

export const brand = {
  50: '#F0F7FF',
  100: '#CEE5FD',
  200: '#9CCCFC',
  300: '#55A6F6',
  400: '#0A66C2',
  500: '#0959AA',
  600: '#064079',
  700: '#033363',
  800: '#02294F',
  900: '#021F3B'
};

export const gray = {
  50: '#FBFCFE',
  100: '#EAF0F5',
  200: '#D6E2EB',
  300: '#BFCCD9',
  400: '#94A6B8',
  500: '#5B6B7C',
  600: '#4C5967',
  700: '#364049',
  800: '#131B20',
  900: '#090E10'
};

const colors = {
  grey1: '#343638',
  grey2: '#53565a',
  grey3: '#75787b',
  grey4: '#a7a8aa',
  grey5: '#cecfd0',
  grey6: '#e8e8e8',
  grey7: '#f5f6f7',
  white: '#ffffff',
  white1: '#fcfcfc',
  blue1: '#283891',
  blue2: '#969FD3',
  blue3: '#182257',
  blue4: '#0C112C',
  purple1: '#512d6d',
  purple2: '#6d4c88',
  purple3: '#9179a8',
  purple4: '#b6a6c5',
  red1: '#cb2c30',
  red2: '#f25066',
  red3: '#f57e8d',
  red4: '#f8a9b3',
  brightGreen: '#5ba83d',
  mint: '#52FAA3',
  brightOrange: '#ffb200',
  brightRed: '#ff5029',
  lightBrightRed: '#FFB89E',
  orange: '#FE6A00'
};

export const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1920
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        sizeSmall: {
          padding: '6px 16px'
        },
        sizeMedium: {
          padding: '8px 20px'
        },
        sizeLarge: {
          padding: '11px 24px'
        },
        textSizeSmall: {
          padding: '7px 12px'
        },
        textSizeMedium: {
          padding: '9px 16px'
        },
        textSizeLarge: {
          padding: '12px 16px'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '32px 24px',
          '&:last-child': {
            paddingBottom: '32px'
          }
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        },
        subheaderTypographyProps: {
          variant: 'body2'
        }
      },
      styleOverrides: {
        root: {
          padding: '32px 24px'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
          margin: 0,
          padding: 0
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%'
        },
        body: {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%'
        },
        '#__next': {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          height: '100%',
          width: '100%'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'none'
        },
        root: ({ theme }) => ({
          '& .MuiInputBase-input': {
            '&::placeholder': {
              opacity: 0.7,
              color: gray[500]
            }
          },
          boxSizing: 'border-box',
          flexGrow: 1,
          height: '100%',
          borderRadius: '10px',
          border: '1px solid',
          borderColor: gray[200],
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
          transition: 'border-color 120ms ease-in',
          backgroundColor: alpha(gray[100], 0.4),
          '&:hover': {
            borderColor: brand[300]
          },
          '&.Mui-focused': {
            borderColor: brand[400],
            outline: '4px solid',
            outlineColor: brand[200]
          },
          ...(theme.palette.mode === 'dark' && {
            '& .MuiInputBase-input': {
              '&::placeholder': {
                opacity: 1,
                color: gray[500]
              }
            },
            boxSizing: 'border-box',
            flexGrow: 1,
            minHeight: 40,
            height: '100%',
            borderRadius: '10px',
            border: '1px solid',
            borderColor: gray[700],
            boxShadow: '0px 2px 2px rgb(0, 0, 0)',
            backgroundColor: alpha(gray[800], 0.4),
            transition: 'border-color 120ms ease-in',
            '&:hover': {
              borderColor: brand[300]
            },
            '&.Mui-focused': {
              borderColor: brand[400],
              outline: '4px solid',
              outlineColor: alpha(brand[500], 0.5)
            }
          })
        }),
        input: {
          paddingLeft: 10
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          typography: theme.typography.caption,
          marginBottom: 8
        })
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: 'none'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          '& label .Mui-focused': {
            color: 'white'
          },
          '& .MuiInputBase-input': {
            '&::placeholder': {
              opacity: 0.7,
              color: gray[500]
            }
          },
          '& .MuiOutlinedInput-root': {
            boxSizing: 'border-box',
            minWidth: 280,
            minHeight: 40,
            height: '100%',
            borderRadius: '10px',
            '& fieldset': {
              border: 'none',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              background: `${alpha('#FFF', 0.3)}`
            },
            '&:hover': {
              borderColor: brand[300]
            },
            '&.Mui-focused': {
              borderColor: brand[400],
              outline: '4px solid',
              outlineColor: brand[200]
            }
          },
          ...(ownerState.variant === 'standard' && {
            '&.MuiTextField-root': {
              '& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
                borderColor: brand[200]
              }
            },
            '& :before': {
              borderBottom: '1px solid',
              borderColor: gray[200]
            },
            '&:hover': {
              '& :before': {
                borderColor: brand[300]
              }
            }
          }),
          ...(theme.palette.mode === 'dark' && {
            '& .MuiInputBase-input': {
              '&::placeholder': {
                opacity: 1,
                color: gray[500]
              }
            },
            '& .MuiOutlinedInput-root': {
              boxSizing: 'border-box',
              minWidth: 280,
              minHeight: 40,
              height: '100%',
              borderRadius: '10px',
              transition: 'border-color 120ms ease-in',
              '& fieldset': {
                border: 'none',
                boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.4)',
                background: `${alpha(gray[800], 0.4)}`
              },
              '&:hover': {
                borderColor: brand[300]
              },
              '&.Mui-focused': {
                borderColor: brand[400],
                outline: '4px solid',
                outlineColor: alpha(brand[500], 0.5)
              }
            },
            ...(ownerState.variant === 'standard' && {
              '&.MuiTextField-root': {
                '& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
                  borderColor: brand[200]
                }
              },
              '& :before': {
                borderBottom: '1px solid',
                borderColor: gray[700]
              },
              '&:hover': {
                '& :before': {
                  borderColor: brand[300]
                }
              }
            })
          })
        })
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#F3F4F6',
          '.MuiTableCell-root': {
            color: '#374151'
          },
          borderBottom: 'none',
          '& .MuiTableCell-root': {
            borderBottom: 'none',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: 1,
            letterSpacing: 0.5,
            textTransform: 'uppercase'
          },
          '& .MuiTableCell-paddingCheckbox': {
            paddingTop: 4,
            paddingBottom: 4
          }
        }
      }
    }
  },
  palette: {
    action: {
      active: '#6B7280',
      focus: 'rgba(55, 65, 81, 0.12)',
      hover: 'rgba(55, 65, 81, 0.04)',
      selected: 'rgba(55, 65, 81, 0.08)',
      disabledBackground: 'rgba(55, 65, 81, 0.12)',
      disabled: 'rgba(55, 65, 81, 0.26)'
    },
    background: {
      default: '#F9FAFC',
      paper: '#FFFFFF'
    },
    divider: '#E6E8F0',
    primary: {
      main: '#0c0c0c',
      light: colors.grey4,
      dark: colors.grey1,
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: colors.blue1,
      light: colors.blue3,
      dark: colors.blue2,
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#14B8A6',
      light: '#43C6B7',
      dark: '#0E8074',
      contrastText: '#FFFFFF'
    },
    info: {
      main: '#2196F3',
      light: '#64B6F7',
      dark: '#0B79D0',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#FFB020',
      light: '#FFBF4C',
      dark: '#B27B16',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#D14343',
      light: '#DA6868',
      dark: '#922E2E',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: '#121828',
      secondary: '#65748B',
      disabled: 'rgba(55, 65, 81, 0.48)'
    }
  },
  shape: {
    borderRadius: 8
  },
  shadows: [
    'none',
    '0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
    '0px 1px 2px rgba(100, 116, 139, 0.12)',
    '0px 1px 4px rgba(100, 116, 139, 0.12)',
    '0px 1px 5px rgba(100, 116, 139, 0.12)',
    '0px 1px 6px rgba(100, 116, 139, 0.12)',
    '0px 2px 6px rgba(100, 116, 139, 0.12)',
    '0px 3px 6px rgba(100, 116, 139, 0.12)',
    '0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)',
    '0px 5px 12px rgba(100, 116, 139, 0.12)',
    '0px 5px 14px rgba(100, 116, 139, 0.12)',
    '0px 5px 15px rgba(100, 116, 139, 0.12)',
    '0px 6px 15px rgba(100, 116, 139, 0.12)',
    '0px 7px 15px rgba(100, 116, 139, 0.12)',
    '0px 8px 15px rgba(100, 116, 139, 0.12)',
    '0px 9px 15px rgba(100, 116, 139, 0.12)',
    '0px 10px 15px rgba(100, 116, 139, 0.12)',
    '0px 12px 22px -8px rgba(100, 116, 139, 0.25)',
    '0px 13px 22px -8px rgba(100, 116, 139, 0.25)',
    '0px 14px 24px -8px rgba(100, 116, 139, 0.25)',
    '0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)',
    '0px 25px 50px rgba(100, 116, 139, 0.25)',
    '0px 25px 50px rgba(100, 116, 139, 0.25)',
    '0px 25px 50px rgba(100, 116, 139, 0.25)',
    '0px 25px 50px rgba(100, 116, 139, 0.25)'
  ],
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily: '"Lato", \'open Sans\'',
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.57
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.75
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.57
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '0.5px',
      lineHeight: 2.5,
      textTransform: 'uppercase'
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.66
    },
    h1: {
      fontWeight: 700,
      fontSize: '3.5rem',
      lineHeight: 1.375
    },
    h2: {
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: 1.375
    },
    h3: {
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: 1.375
    },
    h4: {
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: 1.375
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.3rem',
      lineHeight: 1.375
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: 1.375
    }
  }
});
