import { Box, Button, Card, CardContent, Divider, Typography, styled } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addNewStaff, getCustomers, getMyStaff, getProduct } from '../../api';
import '../../assets/styles/customer.css';
import { notifyError, notifySuccess } from '../../lib/notification';
import { queryKeys } from '../../lib/queryKeys';
import { useStore } from '../../store';
import { DashboardLayout } from '../DashboardLayout';
import ErrorTable from '../ErrorTable';
import UserMgmtModal from '../users/UserMgmtModal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BoldText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '16px'
}));

const CustomerDetails = () => {
  const [userData, setUserData] = useState<any>({});
  const [isCustomer, setIsCustomer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { setCustomerEditDetails, user }: any = useStore((state: any) => state);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, error } = useQuery([queryKeys.customers], getCustomers);
  const { data: myStaff } = useQuery(
    [queryKeys.userIsStaff, { customerId: userData.id }],
    getMyStaff,
    {
      enabled: !!userData
    }
  );
  const { data: productData } = useQuery([queryKeys.userIsStaff, { customerId: id }], getProduct, {
    enabled: !!userData
  });

  const { mutate, isLoading: isMutating } = useMutation(addNewStaff, {
    onSuccess: () => {
      notifySuccess('Staff added successfully');
    },
    onError: () => {
      notifyError('Something went wrong, please try again');
    }
  });

  useEffect(() => {
    const user = data?.find((user: any) => user.id.toString() === id?.toString());
    setUserData(user);
  }, [data]);

  useEffect(() => {
    // to do -- review this check
    if (userData && myStaff) {
      myStaff.forEach((staff: any) => {
        staff.id === user.id ? setIsCustomer(true) : setIsCustomer(false);
      });
    }
  }, [myStaff]);

  const handleAddStaff = (customerId: string | number) => {
    const payload = {
      id: customerId,
      ownerId: user.id
    };
    mutate(payload);
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  if (isLoading) {
    return (
      <Box>
        <Skeleton height={50} />
        <Skeleton height={50} animation="wave" />
        <Skeleton height={50} animation={false} />
      </Box>
    );
  }

  if (error) return <ErrorTable />;

  return (
    <DashboardLayout>
      <Card>
        <CardContent>
          <Box component="div" className="customer-details">
            <Box component="div" className="customer-details__header">
              <Box
                sx={{
                  my: 2
                }}>
                <Typography variant="h5">Customer Details</Typography>
                <Typography variant="subtitle1">Below are customer details</Typography>
              </Box>
              <Box>
                {isCustomer ? (
                  <Button
                    onClick={() => setOpenModal(true)}
                    variant="outlined"
                    color="primary"
                    sx={{
                      mr: 2,
                      border: '1px solid #000'
                    }}>
                    My User
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mr: 2
                    }}
                    onClick={() => handleAddStaff(userData.id)}
                    disabled={isMutating}>
                    Add User/Staff
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setCustomerEditDetails(userData);
                    navigate(`/customers/edit/${userData?.id}`);
                  }}>
                  Edit Customer
                </Button>
              </Box>
            </Box>
            <Box component="div" className="customer-details__personal">
              <Typography variant="h5">Personal Details</Typography>
              <Divider />
              <Box component="div" className="customer-details__personal__info">
                <Box className="customer-details__grid">
                  <Box className="customer-details__grid__item">
                    <BoldText className="font-16">Full Name</BoldText>
                    <Typography variant="body1">{userData?.fullname}</Typography>
                  </Box>
                  <Box className="customer-details__grid__item">
                    <BoldText className="font-16">Email</BoldText>
                    <Typography variant="body1">{userData?.email}</Typography>
                  </Box>
                  <Box className="customer-details__grid__item">
                    <BoldText className="font-16">Phone</BoldText>
                    <Typography variant="body1">{userData?.phone}</Typography>
                  </Box>
                  <Box className="customer-details__grid__item">
                    <BoldText className="font-16">Currency</BoldText>
                    <Typography variant="body1">{userData?.currency || 'USD'}</Typography>
                  </Box>
                  <Box className="customer-details__grid__item">
                    <BoldText className="font-16">Note</BoldText>
                    <Typography variant="body1">{userData?.note}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            {productData?.length > 0 && (
              <Box component="div" className="customer-details__personal">
                <Typography variant="h5">Product Details</Typography>
                <Divider />
                {productData.map((product: any, _index: number, _array: any) => (
                  <Box
                    component="div"
                    className="customer-details__personal__info"
                    key={product.id}>
                    <Box className="customer-details__grid">
                      <Box className="customer-details__grid__item">
                        <BoldText className="font-16">Name</BoldText>
                        <Typography variant="body1">{product?.name}</Typography>
                      </Box>
                      <Box className="customer-details__grid__item">
                        <BoldText className="font-16">Frequency</BoldText>
                        <Typography variant="body1">
                          {product?.frequency || 'Not Stated'}
                        </Typography>
                      </Box>
                      <Box className="customer-details__grid__item">
                        <BoldText className="font-16">Start Date</BoldText>
                        <Typography variant="body1">
                          {product?.start && new Date(product?.start).toDateString()}
                        </Typography>
                      </Box>
                      <Box className="customer-details__grid__item">
                        <BoldText className="font-16">Expiry Date</BoldText>
                        <Typography variant="body1">
                          {product?.expiry && new Date(product?.expiry).toDateString()}
                        </Typography>
                      </Box>
                      <Box className="customer-details__grid__item">
                        <BoldText className="font-16">Cost</BoldText>
                        <Typography variant="body1">{product?.cost}</Typography>
                      </Box>
                      <Box className="customer-details__grid__item">
                        <BoldText className="font-16">Description</BoldText>
                        <Typography variant="body1">{product?.description}</Typography>
                      </Box>
                    </Box>
                    {_array.length - 1 !== _index && <Divider />}
                  </Box>
                ))}
              </Box>
            )}

            <Box component="div" className="customer-details__personal">
              <Typography variant="h5">Personal Address</Typography>
              <Divider />
              <Box component="div" className="customer-details__personal__info">
                <Box className="customer-details__grid">
                  <Box className="customer-details__grid__item">
                    <BoldText className="font-16">Address</BoldText>
                    <Typography variant="body1">{userData?.address}</Typography>
                  </Box>
                  <Box className="customer-details__grid__item">
                    <BoldText className="font-16">City</BoldText>
                    <Typography variant="body1">{userData?.city}</Typography>
                  </Box>
                  <Box className="customer-details__grid__item">
                    <BoldText className="font-16">State</BoldText>
                    <Typography variant="body1">{userData?.state}</Typography>
                  </Box>
                  <Box className="customer-details__grid__item">
                    <BoldText className="font-16 bold">Zip Code</BoldText>
                    <Typography variant="body1">{userData?.lgazip}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box component="div" className="customer-details__personal">
              <Typography variant="h5">Shipping Address</Typography>
              <Divider />
              <Box component="div" className="customer-details__personal__info">
                <Box className="customer-details__grid">
                  <Box className="customer-details__grid__item">
                    <BoldText className="font-16">Shipping Address</BoldText>
                    <Typography variant="body1">{userData?.shippingAddress}</Typography>
                  </Box>
                  <Box className="customer-details__grid__item">
                    <BoldText className="font-16">Shipping City</BoldText>
                    <Typography variant="body1">{userData?.shippingCity}</Typography>
                  </Box>
                  <Box className="customer-details__grid__item">
                    <BoldText className="font-16">Shipping State</BoldText>
                    <Typography variant="body1">{userData?.shippingState}</Typography>
                  </Box>
                  <Box className="customer-details__grid__item">
                    <BoldText className="font-16">Shipping Zip Code</BoldText>
                    <Typography variant="body1">{userData?.shippingLgaZip}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <UserMgmtModal open={openModal} handleClose={closeModal} staff={myStaff} />
    </DashboardLayout>
  );
};

export default CustomerDetails;
