import React, { useState } from 'react';
import { Box } from '@mui/material';
import { InputField, SelectField } from '../InputFields';
import { InputBox, InputContainer, InputLabel } from './ReceiptFormComponents';
import CreatableSelect from 'react-select/creatable';
import { colourStyles } from '../../assets/styles/reactSelectStyles';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../lib/queryKeys';
import { getAllInvoices, getMyCustomers } from '../../api';
import { useStore } from '../../store';
import { createOption } from '../../lib/util';
import { Option } from '../../types/types';

const ReceiptForm = ({ formik, options, value, setValue, setOptions }: any) => {
  const { user }: any = useStore((state: any) => state);
  const [isLoading, setIsLoading] = useState(false);

  const myCustomer: any = useQuery([queryKeys.mycustomers], () => getMyCustomers(user.id));
  const allInvoices: any = useQuery([queryKeys.invoices], getAllInvoices);

  React.useEffect(() => {
    if (myCustomer.data) {
      const options = myCustomer.data.map((customer: any) =>
        createOption(customer.fullname, customer.email)
      );

      setOptions(options);
    }
  }, [myCustomer.data]);

  React.useEffect(() => {
    if (user?.email) formik.setFieldValue('from', user?.email);
  }, []);
  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue, inputValue);
      setIsLoading(false);
      setOptions((prev: any) => [...prev, newOption]);
      setValue((prev: any) => [...prev, newOption]);
    }, 200);
  };
  return (
    <Box>
      <Box
        sx={{
          maxWidth: '500px',
          margin: 'auto'
        }}>
        <InputBox>
          <InputLabel component="label">From: </InputLabel>
          <InputContainer>
            <InputField
              fullWidth
              id="from"
              name="from"
              type="text"
              value={formik.values.from}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.from && Boolean(formik.errors.from)}
              helperText={formik.touched.from && formik.errors.from}
            />
          </InputContainer>
        </InputBox>
        <InputBox>
          <InputLabel component="label">To: </InputLabel>
          <InputContainer>
            <CreatableSelect
              isClearable
              isMulti
              isDisabled={isLoading}
              isLoading={isLoading}
              onChange={(newValue) => setValue(newValue as Option[])}
              onCreateOption={handleCreate}
              options={options}
              value={value}
              styles={colourStyles}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </InputContainer>
        </InputBox>
        <InputBox>
          <InputLabel component="label">Invoice: </InputLabel>
          <InputContainer>
            <SelectField
              fullWidth
              id="inv"
              name="inv"
              type="text"
              value={formik.values.inv}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.inv && Boolean(formik.errors.inv)}
              helperText={formik.touched.inv && formik.errors.inv}>
              <option defaultValue="">Select Invoice</option>
              {allInvoices.data &&
                allInvoices.data.map((invoice: any) => (
                  <option key={invoice.id} value={invoice.id}>
                    {invoice.id} {`(${invoice?.note?.substring(0, 30 - 3) + '...'})`}
                  </option>
                ))}
            </SelectField>
          </InputContainer>
        </InputBox>
        <InputBox>
          <InputLabel component="label">Currency: </InputLabel>
          <InputContainer>
            <InputField
              fullWidth
              id="currency"
              name="currency"
              type="text"
              value={formik.values.currency}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.currency && Boolean(formik.errors.currency)}
              helperText={formik.touched.currency && formik.errors.currency}
            />
          </InputContainer>
        </InputBox>
        <InputBox>
          <InputLabel component="label">Subject: </InputLabel>
          <InputContainer>
            <InputField
              fullWidth
              id="title"
              name="title"
              type="text"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </InputContainer>
        </InputBox>
        <InputBox>
          <InputLabel component="label">Message: </InputLabel>
          <InputContainer>
            <InputField
              fullWidth
              multiline
              rows={5}
              id="message"
              name="message"
              type="text"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
          </InputContainer>
        </InputBox>
      </Box>
    </Box>
  );
};

export default ReceiptForm;
