import { Box, Button, InputLabel, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import Helmet from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import seo from '../../assets/img/seo2.svg';
import Footer from '../../components/seo/Footer';
import Header from '../../components/seo/Header';
import SocialTags from '../../components/SocialTags';

const Seo = () => {
  const [url, setUrl] = useState('');
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);

    const expression =
      /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi;
    const urlRegex = new RegExp(expression);

    if (urlRegex.test(url)) {
      navigate('/seo-result');
      setUrl('');
    } else {
      setError(true);
      return;
    }
  };
  return (
    <>
      <Helmet>
        <title>
          ReachAI - Business Automation. Build Leads, Get digital powered websites and marketing
          your businesses - Home
        </title>
        <meta
          name="description"
          content="Welcome to ReachAI. Build Leads, Get digital powered websites and marketing your businesses "></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston, SEO,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, Digital powered websites, marketing my businesses, reachai, Digital powered websites, marketing my businesses, reachai, ESSL"
        />
        <SocialTags />
      </Helmet>
      <Box sx={styles.main}>
        <Header />
        <Box sx={styles.body}>
          <Box sx={styles.container}>
            <Box sx={styles.left}>
              <Typography variant="h1" mb={'20px'}>
                Easy SEO with <br /> ReachAI.
              </Typography>
              {error && (
                <Typography variant="subtitle1" color="red">
                  Please enter a valid URL
                </Typography>
              )}
              <form onSubmit={handleSubmit}>
                <Box sx={styles.formStyle}>
                  <InputLabel htmlFor="url">Website URL</InputLabel>
                  <TextField
                    type="text"
                    placeholder="Enter your website URL"
                    sx={styles.inputStyle}
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />

                  <Button fullWidth variant="contained" type="submit" sx={styles.buttonStyle}>
                    Search
                  </Button>
                </Box>
              </form>
              <Typography variant="h6" my={'20px'}>
                Boost Your Online Visibility with SEO Expertise: Examine your website&apos;s Search
                Engine Optimization and look for ways to improve it.
              </Typography>
            </Box>
            <Box sx={styles.right}>
              <img src={seo} alt="hero" width={500} height={500} />
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Seo;
const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  body: {
    flexGrow: 1,
    maxWidth: '1290px',
    mx: 'auto',
    width: { xs: '90%', lg: '100%' },
    px: 2
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    my: '50px'
  },
  button: {
    backgroundColor: '#FF6854 !important',
    color: '#fff',
    px: 3,
    borderRadius: 10,
    textTransform: 'capitalize',
    mb: '20px'
  },
  left: {
    maxWidth: '600px'
  },
  right: {
    display: { sm: 'none', md: 'block' },
    maxWidth: '500px'
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  inputStyle: {
    fontSize: '16px',
    flex: '1',
    width: '100%',
    py: 1
  },
  buttonStyle: {
    fontSize: '16px',
    border: 'none',
    mt: 3,
    cursor: 'pointer'
  }
};
