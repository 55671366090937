import { Box, Button, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';
export const NavItem = (props: any) => {
  const { to, icon, title, ...others } = props;
  //   const navigate = useNavigate();
  const active = window.location.href.split('/')[3] === to.split('/')[1];

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2
      }}
      {...others}>
      <Link to={to} style={{ width: '100%', textDecoration: 'none' }}>
        <Button
          startIcon={icon && icon}
          disableRipple
          sx={{
            borderRadius: 1,
            justifyContent: 'flex-start',
            px: 3,
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            backgroundColor: active ? '#ffdd7e99' : 'transparent',

            '&:hover': {
              backgroundColor: '#e0bf6299'
            }
          }}>
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
        </Button>
      </Link>
    </ListItem>
  );
};
