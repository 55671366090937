import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  MenuList,
  Popover,
  MenuItem
} from '@mui/material';
import { Search as SearchIcon } from '../../icons/search';
import React, { useState, useRef } from 'react';
import '../../assets/styles/resources.css';
import ToolHeader from '../dashboard/ToolHeader';

const CustomerToolbar = (props: any) => {
  const settingsRef = useRef(null);
  const [openImportPopover, setOpenImportPopover] = useState(false);

  return (
    <Box {...props}>
      <ToolHeader
        settingsRef={settingsRef}
        setOpenImportPopover={setOpenImportPopover}
        path="/customers/new"
        title="Customers"
        adminOnly={true}
      />
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon color="action" fontSize="small">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search customer"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>

      <Popover
        anchorEl={settingsRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        onClose={() => setOpenImportPopover(false)}
        open={openImportPopover}
        PaperProps={{
          sx: { width: '300px' }
        }}>
        <MenuList>
          <MenuItem>CSV</MenuItem>
          <MenuItem>Google Contacts</MenuItem>
        </MenuList>
      </Popover>
    </Box>
  );
};

export default CustomerToolbar;
