import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { InputField } from '../InputFields';

const PersonalProfile = ({ formik }: any) => {
  return (
    <Card>
      <CardHeader subheader="Edit your personal info" title="Personal Profile" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <InputField
              type="text"
              label="Full Name"
              name="fullname"
              id="fullname"
              error={Boolean(formik.touched.fullname && formik.errors.fullname)}
              helperText={formik.touched.fullname && formik.errors.fullname}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.fullname}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputField
              type="email"
              label="Email Address"
              name="email"
              id="email"
              error={Boolean(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </Grid>

          <Grid item xs={12}>
            <InputField
              type="text"
              label="Phone"
              name="phone"
              id="phone"
              error={Boolean(formik.touched.phone && formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
          </Grid>

          <Grid item xs={12}>
            <InputField
              type="text"
              label="Business Name"
              name="bizname"
              id="bizname"
              error={Boolean(formik.touched.bizname && formik.errors.bizname)}
              helperText={formik.touched.bizname && formik.errors.bizname}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.bizname}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default PersonalProfile;
