import {
  Box,
  Button,
  Card,
  CardContent,
  InputAdornment,
  Popover,
  SvgIcon,
  TextField,
  Typography
} from '@mui/material';
import React, { useRef, useState } from 'react';
import '../../assets/styles/resources.css';
import { Download as DownloadIcon } from '../../icons/download';
import { Search as SearchIcon } from '../../icons/search';
import { Upload as UploadIcon } from '../../icons/upload';

type Props = {
  title: string;
  importBtn?: boolean;
  exportBtn?: boolean;
  exportFn?: () => any;
  addBtn?: boolean;
  addFn?: () => any;
  popOver?: React.ReactNode;
  search?: boolean;
};

const GenericToolbar: React.FC<Props> = ({
  title,
  popOver,
  importBtn = false,
  exportBtn = false,
  exportFn,
  addBtn = false,
  addFn,
  search = false
}) => {
  const settingsRef = useRef(null);
  const [openImportPopover, setOpenImportPopover] = useState(false);

  return (
    <Box>
      <Box className="resources__header">
        <Typography className="m" variant="h4">
          {title}
        </Typography>
        <Box className="m">
          {importBtn && (
            <Button
              ref={settingsRef}
              onClick={() => setOpenImportPopover(true)}
              startIcon={<UploadIcon fontSize="small" />}
              sx={{ mr: 1 }}>
              Import
            </Button>
          )}

          {exportBtn && (
            <Button startIcon={<DownloadIcon fontSize="small" />} sx={{ mr: 1 }} onClick={exportFn}>
              Export
            </Button>
          )}
          {addBtn && (
            <Button color="primary" variant="contained" onClick={addFn}>
              Add {title}
            </Button>
          )}
        </Box>
      </Box>
      {search && (
        <Box sx={{ mt: 3 }}>
          <Card>
            <CardContent>
              <Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder={`Search ${title}`}
                  variant="outlined"
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}

      <Popover
        anchorEl={settingsRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        onClose={() => setOpenImportPopover(false)}
        open={openImportPopover}
        PaperProps={{
          sx: { width: '300px' }
        }}>
        {popOver}
      </Popover>
    </Box>
  );
};

export default GenericToolbar;
