import { Box, Typography } from '@mui/material';
import React from 'react';

const ErrorTable = () => {
  return (
    <Box className="flex flex-wrap flex-column flex-center">
      <Typography variant="h4" color="error">
        Error
      </Typography>
      <Typography variant="h6" color="error">
        Something went wrong
      </Typography>
    </Box>
  );
};

export default ErrorTable;
