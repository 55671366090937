import { Box, Container } from '@mui/material';
import { DashboardLayout } from '../../components/DashboardLayout';
import CustomerResults from '../../components/customer/CustomerResults';
import CustomerToolbar from '../../components/customer/CustomerToolbar';

const Customers = () => {
  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 4
        }}>
        <Container maxWidth={false}>
          <CustomerToolbar />
          <Box sx={{ mt: 3 }}>
            <CustomerResults />
          </Box>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Customers;
