import { SeverityPill } from '../SeverityPills';
import { format } from 'date-fns';
import { TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
const Receipt = ({ receipt }: any) => {
  return (
    <TableRow
      component={Link}
      to={`/receipts/${receipt.id}`}
      hover
      sx={{
        cursor: 'pointer',
        textDecoration: 'none'
      }}>
      <TableCell>{receipt.id}</TableCell>
      <TableCell>{receipt?.inv || 'Nil'}</TableCell>
      <TableCell>{receipt?.title}</TableCell>
      <TableCell>{format(new Date(receipt.updatedOn), 'MMM dd, yyyy')}</TableCell>
      <TableCell>
        <SeverityPill color="success">{receipt?.currency || 'USD'}</SeverityPill>
      </TableCell>
    </TableRow>
  );
};

export default Receipt;
