import { Box, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import DOMTest from '../../components/seo/DOMTest';
import Deprecated from '../../components/seo/Deprecated';
import Footer from '../../components/seo/Footer';
import FriendTest from '../../components/seo/FriendTest';
import Header, { seoTestTypes } from '../../components/seo/Header';
import ImageTest from '../../components/seo/ImageTest';
import PageObjects from '../../components/seo/PageObjects';
import SPFRecords from '../../components/seo/SPFRecords';
import SecurityTest from '../../components/seo/SecurityTest';
import SocialLinks from '../../components/seo/SocialLinks';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  body: {
    flexGrow: 1,
    mx: 'auto',
    width: { xs: '90%', lg: '100%' },
    px: 2,
    mt: 4
  }
};

const SeoSingleResult = () => {
  const params = useParams();

  const renderComponent = () => {
    switch (params.id) {
      case 'friendliness':
        return <FriendTest />;
      case 'image':
        return <ImageTest />;
      case 'deprecated':
        return <Deprecated />;
      case 'analysis':
        return <PageObjects />;
      case 'social':
        return <SocialLinks />;
      case 'dom':
        return <DOMTest />;
      case 'security':
        return <SecurityTest />;
      case 'spfrecords':
        return <SPFRecords />;
      default:
        return <FriendTest />;
    }
  };
  return (
    <Box sx={styles.main}>
      <Header />
      <Box sx={styles.body}>
        <Container>
          <Box>
            <Typography variant="h4">
              {seoTestTypes.find((item) => item.value === params.id)?.label || 'SEO Friendliness'}
            </Typography>
          </Box>
          {renderComponent()}
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default SeoSingleResult;
