import { Box, Typography } from '@mui/material';
import ReusableOverview from './ReusableOverview';
import SEOBadge from './SEOBadge';
import { useSeoInput } from '../../hooks/useSeoInput';
import ReusableSeo from './ReusableSeo';
import { useNavigate, useParams } from 'react-router-dom';
import { regexUrl } from '../../lib/util';

const SocialLinks = () => {
  return (
    <Box>
      <ReusableOverview
        message={
          'To enhance your website&quot;s visibility on social media platforms, ensure the inclusion of social media meta tags in the "head" section of your webpage. Social media meta tags are HTML snippets that dictate how URLs appear when shared on platforms like Facebook and Twitter. '
        }
        passed={true}
      />
      <Typography
        variant="h4"
        sx={{
          my: 2
        }}>
        How to pass
      </Typography>
      <Typography variant="subtitle1">
        To pass this test, incorporate essential meta tags for Facebook and Twitter into your
        webpage&apos;s HTML structure. For Facebook, utilize meta tags from the Open Graph protocol,
        including &apos;og:title&apos;, &apos;og:description&apos;, &apos;og:image&apos;, and
        &apos;og:url&apos;. For Twitter, use similar meta tags with the &apos;twitter&apos; prefix,
        such as &apos;twitter:title&apos;, &apos;twitter:description&apos;,
        &apos;twitter:image&apos;, and &apos;twitter:card&apos;. Ensure each tag is correctly
        implemented with appropriate content to provide essential information for social media
        sharing.
      </Typography>

      <SEOBadge type="success">
        <Box component={'pre'}>
          {`<-- correct format -->

<!-- Facebook meta tags -->
<meta property="og:title" content="Example Title">
<meta property="og:description" content="Example Description">
<meta property="og:image" content="https://example.com/og-image.png">
<meta property="og:url" content="https://example.com">

<!-- Twitter meta tags -->
<meta name="twitter:title" content="Example Title">
<meta name="twitter:description" content="Example Description">
<meta name="twitter:image" content="https://example.com/twitter-image.png">
<meta name="twitter:card" content="summary">

`}
        </Box>
      </SEOBadge>
    </Box>
  );
};

export default SocialLinks;
export const SocialLinksSearch = () => {
  const { url, setUrl, error, setError } = useSeoInput();
  const navigate = useNavigate();
  const params = useParams();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);

    if (url && regexUrl(url)) return navigate(`/seo-result/${params.id}`);

    setError(true);
  };
  return (
    <ReusableSeo
      title="Social Links SEO Test"
      keywords="Social Links SEO Test"
      url={url}
      setUrl={setUrl}
      error={error}
      setError={setError}
      handleSubmit={handleSubmit}
    />
  );
};
