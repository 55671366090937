import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography,
  Menu, // Import the Menu component
  MenuItem // Import the MenuItem component
} from '@mui/material';
import { Clock as ClockIcon } from '../../icons/clock';
import { InfoOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Web } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useStore } from '../../store';
import { useNavigate } from 'react-router-dom';

const ProductCard = ({ product, setOpen }: { product: any; setOpen: (value: boolean) => void }) => {
  const { setProductId }: any = useStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setProductId(product.id || null);
    setOpen(true);
    handleMenuClose();
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pb: 3
          }}>
          <Avatar alt="Product" src="random" variant="square" />
        </Box>
        <Typography align="center" color="textPrimary" gutterBottom variant="h5">
          {product.name}
        </Typography>
        <Typography align="center" color="textPrimary" variant="body1">
          {product.description}
        </Typography>
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'block' }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              mb: 1
            }}>
            <ClockIcon color="action" />
            <Typography color="textSecondary" display="inline" sx={{ pl: 1 }} variant="body2">
              Start - End : {new Date(product.start).toLocaleDateString()} -
              {new Date(product.expiry).toLocaleDateString()}
            </Typography>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}>
            <InfoOutlined color="action" />
            <Typography color="textSecondary" display="inline" sx={{ pl: 1 }} variant="body2">
              {product.statistics || 'Statistics'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={handleMenuOpen}>
          {/* You can use any icon for the dropdown button */}
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <MenuItem onClick={handleEdit}>
            <EditIcon
              color="primary"
              sx={{
                mr: 1
              }}
            />{' '}
            Edit Product
          </MenuItem>
          <MenuItem
            onClick={() => navigate(`/product/website/edit/${product.id}`, { replace: true })}>
            <Web
              color="primary"
              sx={{
                mr: 1
              }}
            />{' '}
            Edit Website
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <DeleteIcon
              color="error"
              sx={{
                mr: 1
              }}
            />{' '}
            Delete
          </MenuItem>
        </Menu>
      </Box>
    </Card>
  );
};

export default ProductCard;
