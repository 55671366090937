import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { tChart } from '../../types/types';
interface Props {
  data: tChart[];
}
const CashFlowChart = (props: Props) => {
  const { data } = props;
  return (
    <LineChart width={600} height={300} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="income" stroke="#8884d8" name="Income" />
      <Line type="monotone" dataKey="spending" stroke="#82ca9d" name="Spending" />
      <Line type="monotone" dataKey="net" stroke="#ff7300" name="Net" />
    </LineChart>
  );
};
export default CashFlowChart;
