import { Box, Container, Grid, Link, Typography } from '@mui/material';
import logo from '../../assets/img/logo.png';

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: 'black',
        color: 'white',
        width: '100%',
        py: 5
      }}>
      <Box
        sx={{
          maxWidth: '1290px',
          mx: 'auto',
          width: { xs: '90%', lg: '100%' },
          color: 'white',
          px: 2
        }}>
        <Container maxWidth="lg">
          <Grid
            container
            columnSpacing={{
              xs: 8,
              md: 3
            }}>
            <Grid item xs={12} sm={9}>
              <Box>
                <img src={logo} alt="reach logo" width={220} height={80} />
              </Box>
              <Box width={400} my={2}>
                <Typography variant="h6">
                  Create your website, oversee its management, and enhance your website&apos;s SEO
                  with Reach AI.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box>
                <Typography variant="h5" mb={2}>
                  Explore Our Features
                </Typography>
                <Box
                  component="div"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                  }}>
                  <Link href="/website-builder" underline="none" color="primary" sx={styles.link}>
                    Build New Website
                  </Link>
                  <Link href="/signup" underline="none" color="primary" sx={styles.link}>
                    Sign Up for ReachAI
                  </Link>
                  <Link href="/login" underline="none" color="primary" sx={styles.link}>
                    Log In to ReachAI
                  </Link>
                  <Typography>office[at]reachai.online</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Container sx={{ textAlign: 'center', mt: 2 }}>
          <Typography variant="body2">
            &copy;{' '}
            <a href="https://reachai.online/" target="_blank" rel="noreferrer">
              Reach AI
            </a>{' '}
            {new Date().getFullYear()}. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
const styles = {
  link: {
    mb: 2,
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': { textDecoration: 'underline' }
  }
};
